import React from "react";
import { NoInfoYet, NoteMetric } from "../../../types/search";
import { Text, View } from '@react-pdf/renderer';
import { DashboardQuery } from "../../../types/files";
import styles from '../dashboard-export/styles';
import moment from "moment";

const MetricsAnswer: React.FC<{ query: DashboardQuery }> = ({ query }) => {
    const answer = !!query.answer ? JSON.parse(query.answer) : null;
    const parsed: NoteMetric = !!answer ? answer?.answer : null;
    let metricsList: {metric: string, value: string, date?: string|null}[] = [];

    if (!Object.values(parsed).every(metric => metric.value === null)) {
        Object.entries(parsed)
            .forEach(([metricName, metricValues]) => {
                if (metricValues.value !== null) {
                    const date = !!metricValues.date ? moment(metricValues.date).format('MMM YYYY') : '';
                    const cleanInput = String(metricValues.value!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
                    let multiplier = 1;
                    let displayValue = '0';

                    if (cleanInput.includes('t'))
                        multiplier = Math.pow(10, 12);
                    else if (cleanInput.includes('b'))
                        multiplier = Math.pow(10, 9);
                    else if (cleanInput.includes('m'))
                        multiplier = Math.pow(10, 6);
                    else if (cleanInput.includes('k'))
                        multiplier = 1000;

                    const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

                    if (metricValues.units === '%')
                        displayValue = `${numericValue.toFixed(1)}%`;
                    else if (metricValues.units === '$')
                        displayValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    else
                        displayValue = `${numericValue}`;

                    metricsList.push({metric: metricName, value: displayValue, date: date});
                }
        });
    }

    return (<>
        {!!metricsList.length ? (
            metricsList?.map((metrics, i) => (!!metrics && (
                <View style={styles.metricsBlock} key={'text-answer-43-' + i}>
                    <Text style={styles.metricText}>{`${metrics.metric}:`}</Text>
                    <Text style={styles.sentence}>{`${metrics.value}`}</Text>
                    {!!metrics.date && (
                        <Text style={styles.sentence}>({`${metrics.date}`})</Text>
                    )}
                </View>
        )))) : (
            <Text style={styles.sentence}>{NoInfoYet.answer}</Text>
        )}
    </>);
}

export default MetricsAnswer;
