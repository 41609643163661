import { Divider, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { scrollbarStyle } from '../../../shared/dashboard';
import React from 'react';
import ArrayUtils from '../../../utils/ArrayUtils';
import moment from 'moment';

type StatMetric = {
    method: string;
    value: number;
};

const useStyles = makeStyles(() => ({
    cardsGroupBlock: {
        height: '250px',
        overflowY: 'auto',
        borderRadius: '10px',
        marginTop: '8px',
        ...scrollbarStyle,
    },
}));

const StatMetricsCategory = ['Average', 'Median', 'Min', 'Max'];

const MonthlySummary: React.FC<{
    data: Array<{date: string, value: number}>,
    unit: string,
}> = ({ data, unit }) => {
    const classes = useStyles();
    const [currentValues, setCurrentValues] = useState<number[]>([]);

    const summaryInfo: StatMetric[] = useMemo(() => {
        if (!!currentValues.length) {
            return StatMetricsCategory.map(category => ({
                method: category,
                value: (() => {
                    switch (category) {
                        case 'Average':
                            return currentValues.reduce((sum, val) => sum += val, 0) / currentValues.length;
                        case 'Median':
                            return (() => {
                                let values = [...currentValues].sort((a, b) => a - b);
                                const half = Math.floor(values.length / 2);
                                return (values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2);
                            })();
                        case 'Min':
                            return Math.min(...currentValues);
                        case 'Max':
                            return Math.max(...currentValues);
                        default:
                            return 0;
                    }
                })(),
            }));
        }

        return StatMetricsCategory.map(category => ({method: category, value: 0}));
    }, [currentValues]);

    const formatNumber = useCallback((value: number|null, unit?: string) => {
        if (value === null)
            return 'N/A';

        let cleanInput = String(value!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

        if (unit === '%')
            return `${numericValue.toFixed(1)}%`;
        else if (unit === '$')
            return `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return `${numericValue}`;
    }, []);

    useEffect(() => {
        if (!!data.length) {
            setCurrentValues([...ArrayUtils.sortByDescending(data, 'date')]
                .filter(datum => moment().diff(moment(datum.date), 'months') === 0)
                .map(datum => datum.value));
        } else {
            setCurrentValues([]);
        }
    }, [data]);

    return (<>
        <Typography fontSize="1.1rem" fontWeight="bold" color="#7bd4d4">This Month:</Typography>
        <Stack spacing="4px" className={classes.cardsGroupBlock} sx={{pl: '8px'}}>
            {summaryInfo.map((summary, i) => (<Fragment key={'monthly-summary-100-' + i}>
                <Typography fontSize="1rem" color="darkgray">{summary.method}</Typography>
                <Typography fontSize="1.2rem" fontWeight="bold" color="#666666" display="inline">{formatNumber(summary.value, unit)}</Typography>
                <Divider sx={{ margin: '4px 0'}}/>
            </Fragment>))}
        </Stack>
    </>);
}

export {StatMetricsCategory};
export default MonthlySummary;