import React, { useMemo, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import WorkspacesBulkShareEmailModal from "../../modals/investors/WorkspacesBulkShareEmailModal";
import { collectionKey } from "../../modals/dashboard-details/ShareDealModal";

const useStyles = makeStyles(() => ({
    commonButton: {
        height: 40,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
    icon: {
        width: 20,
        height: 20,
    }
}));

const WorkspacesBulkShareEmail: React.FC<{}> = () => {
    const classes = useStyles();
    const {loading, getBulkWorkspaces} = useBulkWorkspaces();
    const [modalOpen, setModalOpen] = useState<boolean>(false);

    const noBulkAction = useMemo(() => !getBulkWorkspaces(collectionKey).length, [getBulkWorkspaces]);

    return (<>
        <LoadingButton variant="contained"
            className={classes.commonButton}
            startIcon={
                <ShareIcon className={classes.icon}
                    style={{ stroke: noBulkAction? 'darkgray' : 'white' }}/>
            }
            disabled={noBulkAction || loading}
            onClick={() => setModalOpen(true)}
            sx={{ width: 120 }}>{'Share'}</LoadingButton>
        {modalOpen && (<WorkspacesBulkShareEmailModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />)}
    </>);
}

export default WorkspacesBulkShareEmail;