import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import quotedPrintable from 'quoted-printable';
import topLeveDomains from "../helpers/topLevelDomains.json";
import Pattern from "../utils/url-extractor/entry";
import { defaultStages } from "../shared/dashboard";
import { UnassignedContent } from "../types/files";

export type AttachmentType = {
  url: string;
  name: string;
  size?: number;
  contentType: string;
};

export type EmailDeal = {
  id: string;
  name: string;
  website: string;
  stage?: string;
  deck?: AttachmentType;
  externalDecks?: AttachmentType[];
  email?: string;
};

type ContentBlock = {
  name: string;
  url?: string|null;
  start: number;
  end: number;
};

const useEmailDealsExtraction = () => {
  const topLevelDomainsList = useMemo(() =>
    Object.entries(topLeveDomains as { [key: string]: string | string [] }).find(([key]) => key === 'result')?.pop() ?? []
  , []);

  const sanitizeUrl = useCallback((originUrl?: string) => originUrl?.replace(/^https?:\/\//, '')?.replace(/^www\./, '') || '', []);

  const textToBlocks = useCallback((emailBody: string) => {
    const decoded = quotedPrintable.decode(emailBody);
    const blocks = decoded.split(/\n/).flatMap((block, i) => (block.trim() === '') ? (i > 0 ? [''] : []) : [block.trim()]);

    return blocks;
  }, []);

  const blocksToText = useCallback((blocks: string[], startIndex: number, endIndex?: number, noBreak?: boolean) => {
    const texts: string[] = [];
    let blanks = 0;

    for (let i = startIndex; i < (endIndex ?? blocks.length); i++) {
      const block = blocks[i].trim();

      if (!noBreak && (blanks >= 3 || block === '--'))
        break;

      if (block !== '') {
        texts.push(block);
        blanks = 0;
      } else {
        blanks++;
      }
    }

    return texts.join('\n');
  }, []);

  const extractCompanyUrls = useCallback((block: string) => {
    const matchesUrl = block.match(/[\\[(<]?\s*https?:\/\/[^\s\])>]+\s*[\])>]?/g);
    const matchesEmail = block.match(/[\\[(<]?\s*(?:mailto:)?\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b\s*[\])>]?/gi);

    if (!!matchesUrl) {
      const links = matchesUrl.map(match => {
        const urlMatch = match.match(/(https?:\/\/[^\s\])>]+)/);

        return urlMatch ? (urlMatch[1] || urlMatch[2] || urlMatch[3]) : null;
      }).filter(url => !!url);

      if (!!links.length) {
        const extractedUrls = Pattern.TextArea.extractAllUrls(links.join(' '),
          {'ip_v4': true, 'ip_v6': false, 'localhost': false, 'intranet': true});

        return extractedUrls.filter(url =>
          !!url.value.onlyDomain && !url.value.onlyUriWithParams // && protocol === 'https'
          && topLevelDomainsList.includes(((String(url.value.onlyDomain)).split('.').pop() || '')?.trim().toLowerCase()));
      }
    } else if (!!matchesEmail) {
      const links = matchesEmail.map(match => {
        const mailMatch = match.match(/([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})/);

        return mailMatch ? mailMatch[1] : null;
      }).filter(email => !!email).map(email => email?.split('@')?.[1] ?? '');

      if (!!links.length) {
        const extractedUrls = Pattern.TextArea.extractAllUrls(links.join(' '),
          {'ip_v4': true, 'ip_v6': false, 'localhost': false, 'intranet': true});

        return extractedUrls.filter(url =>
          !!url.value.onlyDomain && !url.value.onlyUriWithParams // && protocol === 'https'
          && topLevelDomainsList.includes(((String(url.value.onlyDomain)).split('.').pop() || '')?.trim().toLowerCase()));
      }
    }

    return [];
  }, [topLevelDomainsList]);

  const extractCompanyStage = useCallback((blocks: string[], startIndex: number, endIndex?: number) => {
    const blockTexts = Array.from(blocks).slice(startIndex, endIndex);

    for (let block = 0; block < blockTexts.length; block++) {
      for (let stage = 0; stage < defaultStages.length; stage++) {
        if (blockTexts[block].toLowerCase().includes(defaultStages[stage].toLowerCase()))
          return defaultStages[stage];
      }
    }

    return '';
  }, []);

  const extractExternalDecks = useCallback((blocks: string[], startIndex: number, endIndex?: number) => {
    const blockTexts = Array.from(blocks).slice(startIndex, endIndex);
    const patterns = [
      /(?:\S*\s*)?deck\s*:\s*here\s*<\s*(https?:\/\/docsend\.com\/[^\s>]+)\s*>/gi, // "Deck: here <url>"
      /(?:\S*\s*)?deck\s*here\s*<\s*(https?:\/\/docsend\.com\/[^\s>]+)\s*>/gi,     // "Deck here <url>"
      /(?:\S*\s*)?deck\s*<\s*(https?:\/\/docsend\.com\/[^\s>]+)\s*>/gi,            // "Deck <url>"
      /(?:\S*\s*)?<\s*(https?:\/\/docsend\.com\/[^\s>]+)\s*>/gi,                   // "<url>", docsend.com
    ];
    const decks: Set<string> = new Set([]);

    for (let block = 0; block < blockTexts.length; block++) {
      patterns.forEach(pattern => {
        const matches = blockTexts[block].match(pattern)
          ?.map(match => match?.match(/\s*(https?:\/\/[^\s>]+)\s*/gi)?.[0]) || [];

        if (!!matches.length) {
          matches.forEach(matched => {
            if (!!matched)
              decks.add(JSON.stringify({ url: matched, name: matched, contentType: 'text/plain' }));
          });
        }
      });
    }

    return (!!decks.size ? Array.from(decks).map(deck => JSON.parse(deck)) : undefined);
  }, []);

  const matchCompanyName = useCallback((urls: string[], companyName: string) => {
    if (companyName.trim().toLowerCase().includes('notissia'))
      return null;

    for (let i = 0; i < urls.length; i++) {
      const blockWords = companyName.split(/[^a-zA-Z0-9]+/)
        .filter((word, i, self) => (i === self.findIndex(item => item === word)))
        .filter(word => isNaN(Number(word)) && word.length >= 3)
        .map(word => word.trim().toLowerCase());
      const urlTokens = sanitizeUrl(urls[i]).split(/[\W/]+/).slice(0, -1).map(item => item.trim().toLowerCase());
      const matchedWords: string[] = [];

      urlTokens.forEach(token => {
        blockWords.forEach(word => {
          if (token.includes(word) || word.includes(token)) {
            if (!matchedWords.join('').includes(word))
              matchedWords.push(word);
          }
        });
      });

      if (!!matchedWords.length)
        return sanitizeUrl(urls[i]);
    }

    return null;
  }, [sanitizeUrl]);

  const matchAttachment = useCallback((companyName: string, attachments?: string[]) => {
    if(!Array.isArray(attachments))
      return undefined;

    const fileNameTokens = attachments.map(attachment => attachment.split(/[\W/]+/).slice(0, -1)
      .filter((word, i, self) => (i === self.findIndex(item => item === word)))
      .filter(word => isNaN(Number(word)) && word.length >= 3)
      .map(word => word.trim().toLowerCase()));
    let deck: AttachmentType|undefined = undefined;

    if (!!fileNameTokens.length) {
      const companyWords = companyName.split(/[\W/]+/)
        .filter((word, i, self) => (i === self.findIndex(item => item === word)))
        .filter(word => isNaN(Number(word)) && word.length >= 3)
        .map(word => word.trim().toLowerCase());

      fileNameTokens.forEach((fNtokens, i) => {
        if (JSON.stringify(companyWords.sort()) === JSON.stringify(fNtokens.sort())
          || companyWords.some(wFToken => fNtokens.some(fnToken => fnToken.includes(wFToken)))
          || fNtokens.some(fNToken => companyWords.some(wFToken => wFToken.includes(fNToken))))
          deck = {url: '', name: attachments[i], contentType: 'application/pdf' } as AttachmentType;
      });
    }

    return deck;
  }, []);

  const registerDeal = useCallback((deals: EmailDeal[], blocks: string[], current: ContentBlock, attachments?: string[]) => {
    const website = current.url!;

    if (!deals.find(deal => deal.website === website)) {
      const name = current.name;
      const stage = extractCompanyStage([...blocks], current.start, current.end);
      const externalDecks = extractExternalDecks([...blocks], current.start, current.end);
      const deck = externalDecks ? externalDecks[0] : matchAttachment(name, attachments);
      const email = blocksToText([...blocks], current.start, current.end);

      deals.push({ id: uuidv4(), name, website, stage, deck, externalDecks, email });
    }
  }, [blocksToText, extractCompanyStage, extractExternalDecks, matchAttachment]);

  const extractEmailDeals = useCallback((emailContent: UnassignedContent) => {
    const { body, potentialCompanies } = emailContent;
    const blocks = textToBlocks(body?.trim() || '');
    const companyNames = potentialCompanies?.flatMap(companies =>
        companies.split('\n').map(company => company.replace(/^[\W_]+|[\W_]+$/g, '').trim()))
      ?.filter(company => !!company.length) ?? [];
    const deals: EmailDeal[] = [];

    if (!!companyNames.length) {
      companyNames.forEach((name, i, self) => {
        const start = blocks.findIndex(block => block.toLowerCase().includes(self[i].toLowerCase()));
        let end = (i < self.length - 1) ? blocks.slice(start + 1).findIndex(block => block.toLowerCase().includes(self[i + 1].toLowerCase())) : blocks.length;

        if ((i < self.length - 1) && (end !== -1))
          end = start + end + 1;

        let current: ContentBlock = { url: null, name, start, end };

        for (let i = start; (i >= 0) && (i < end); i++) {
          const companyUrls = extractCompanyUrls(blocks[i])?.map(url => url.value.onlyDomain as unknown as string) || [];
          const matchedUrl = matchCompanyName(companyUrls, name);

          if (!!matchedUrl) {
            current.url = matchedUrl;
            registerDeal(deals, blocks, current, emailContent.attachments);
            break;
          }
        }
      });
    }

    return deals;
  }, [extractCompanyUrls, matchCompanyName, registerDeal, textToBlocks]);

  return { sanitizeUrl, extractEmailDeals };
};

export default useEmailDealsExtraction;
