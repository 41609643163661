import React, { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dashboard, DashboardDeckCreation, DashboardQuery, Workspace } from '../types/files';
import { deleteCommentsFunc, deleteDashboardFunc, getDashboards, getFilesFunc, getGroupDashboardQueriesFunc, getPublicDashboardQueriesFunc } from '../lib/helper';
import { AuthContext } from './AuthContext';
import { API, graphqlOperation } from 'aws-amplify';
import { onDashboard, onDashboardDeleted, onDocSendIngestionCreation } from '../graphql/subscriptions';
import ArrayUtils from '../utils/ArrayUtils';
import { deleteFile } from '../graphql/mutations';
import { s3Delete } from '../helpers/s3';
import useMutation from '../hooks/useMutation';
import { splitter } from '../helpers/textSplitter';

export type OverviewQueries = {
  [key: string]: number | string | string[];
};

export type OverviewQueriesList = {
  [key: string]: OverviewQueries;
};

export type StatusGroupList = {
  [key: string]: Dashboard[];
};

type DashboardsContextProps = {
  dashboardsLoaded: boolean;
  setDashboardsLoaded: Dispatch<SetStateAction<boolean>>;
  workspace: Workspace|null;
  setWorkspace: Dispatch<SetStateAction<Workspace|null>>;
  dashboards: Dashboard[];
  setDashboards: Dispatch<SetStateAction<Dashboard[]>>;
  externalDashboards: Dashboard[];
  setExternalDashboards: Dispatch<SetStateAction<Dashboard[]>>;
  dashboardsQueries: DashboardQuery[],
  setDashboardsQueries: Dispatch<SetStateAction<DashboardQuery[]>>,
  mappedOverviewQueries: Map<string, any>;
  isPublicView: boolean;
  setPublicView: Dispatch<SetStateAction<boolean>>;
  deleteDashboard: (dashboardId?: string) => Promise<any>;
};

export const DashboardsContext = createContext<DashboardsContextProps>({
  dashboardsLoaded: false,
  setDashboardsLoaded: () => {},
  workspace: null,
  setWorkspace: () => {},
  dashboards: [],
  setDashboards: () => {},
  externalDashboards: [],
  setExternalDashboards: () => {},
  dashboardsQueries: [],
  setDashboardsQueries: () => {},
  mappedOverviewQueries: new Map(),
  isPublicView: false,
  setPublicView: () => {},
  deleteDashboard: () => Promise.resolve(),
});

export const OverviewQueryTitles = ['Digest', 'Team', 'Key People', 'Note Metrics', 'Investment Thesis', 'Location', 'Website', 'Crunchbase Page', 'LinkedIn'];
export const DisplayQueryTitles = ['Description', 'Website', 'LinkedIn', 'Location', 'Team', 'Metrics'];
export const CompanyInfoFields = ['Description', 'Stage', 'Sector', 'Team', 'Deck', 'Deal overview'];

const DashboardsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userGroup } = useContext(AuthContext);
  const [deleteFileGql] = useMutation({ statement: deleteFile, name: "deleteFile" });

  const [workspace, setWorkspace] = useState<Workspace|null>(null);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [externalDashboards, setExternalDashboards] = useState<Dashboard[]>([]);
  const [dashboardsQueries, setDashboardsQueries] = useState<DashboardQuery[]>([]);
  const [dashboardDecksInProgress, setDashboardDecksInProgress] = useState<DashboardDeckCreation[]>([]);
  const [dashboardsLoaded, setDashboardsLoaded] = useState<boolean>(false);
  const [isPublicView, setPublicView] = useState<boolean>(false);

  const dashboardSubscriptionRef = useRef<any>(null);
  const dashboardDeletedSubscriptionRef = useRef<any>(null);
  const dashboardDeckCreationFinishedSubscriptionRef = useRef<any>(null);
  const dashboardDeckCreationSubscriptionRef = useRef<any>(null);

  const updateSubscribedDashboards = useCallback((localDashboards: Dashboard[], dashboard?: Dashboard|null, remove?: boolean) => {
    if (!dashboard?.id)
      return;

    let newDashboards = [];

    if (remove) {
      newDashboards = localDashboards.filter(d => d.id !== dashboard?.id);
    } else if (!!localDashboards.find(d => d.id === dashboard?.id)) {
      newDashboards = localDashboards.map(d => d.id === dashboard?.id ? ({...dashboard}) : d);
    } else {
      newDashboards = [{...dashboard}, ...localDashboards];
    }

    setDashboards(ArrayUtils.sortByDescending(newDashboards, 'createdAt'));
  }, []);

  const subscribeDashboards = useCallback(async (localDashboards: Dashboard[]) => {
    if (dashboardSubscriptionRef.current)
      dashboardSubscriptionRef.current.unsubscribe();

    if (userGroup) {
      dashboardSubscriptionRef.current = await (API.graphql(
        graphqlOperation(onDashboard, { group: userGroup })) as any)
          .subscribe({ next: ({ value }: any) =>
            updateSubscribedDashboards(localDashboards, value?.data?.onDashboard)});
    }
  }, [userGroup, updateSubscribedDashboards]);

  const subscribeDashboardDeleted = useCallback(async (localDashboards: Dashboard[]) => {
    if (dashboardDeletedSubscriptionRef.current)
      dashboardDeletedSubscriptionRef.current.unsubscribe();

    if (userGroup) {
      dashboardDeletedSubscriptionRef.current = await (API.graphql(
        graphqlOperation(onDashboardDeleted, { group: userGroup })) as any)
          .subscribe({ next: ({ value }: any) =>
            updateSubscribedDashboards(localDashboards, value?.data?.onDashboardDeleted, true)});
    }
  }, [userGroup, updateSubscribedDashboards]);

  const subscribeDashboardDeckCreateFinished = useCallback(async (localDashboardDecks: DashboardDeckCreation[]) => {
      if (dashboardDeckCreationFinishedSubscriptionRef.current)
        dashboardDeckCreationFinishedSubscriptionRef.current.unsubscribe();

      if (userGroup) {
        dashboardDeckCreationFinishedSubscriptionRef.current = await (API.graphql(
          graphqlOperation(onDashboard, { group: userGroup })) as any)
            .subscribe({ next: async ({ value }: any) => {
              if (!value?.data?.onDashboard?.id)
                return;

              if (!!localDashboardDecks?.length)
                setDashboardDecksInProgress(localDashboardDecks.filter(deck => deck.newDashboardTitle !== value.data.onDashboard.title));
            }});
      }
  }, [userGroup]);

  const subscribeDashboardDeckCreated = useCallback(async (localDashboardDecks: DashboardDeckCreation[]) => {
      if (dashboardDeckCreationSubscriptionRef.current)
        dashboardDeckCreationSubscriptionRef.current.unsubscribe();

      if (userGroup) {
        dashboardDeckCreationSubscriptionRef.current = await (API.graphql(
          graphqlOperation(onDocSendIngestionCreation, { group: userGroup })) as any)
            .subscribe({ next: async ({ value }: any) => {
              if (!value?.data?.onDocSendIngestionCreation?.newDashboardTitle)
                return;

              setDashboardDecksInProgress([{ ...value.data.onDocSendIngestionCreation }, ...localDashboardDecks]);
            }});
      }
    }, [userGroup]);

    const deleteDashboard = useCallback((dashboardId?: string) => {
      return new Promise((resolve, reject) => {
        const lookUpDashboard = dashboards.find(dashboard => dashboard.id === dashboardId);

        if (!lookUpDashboard) {
          resolve(false);
          return;
        }

        const promises = [];

        lookUpDashboard.screenshots?.forEach((screenshot) => promises.push(s3Delete({ path: screenshot!.id })));
        lookUpDashboard.selections?.forEach((selection) => promises.push(deleteCommentsFunc(selection!.id)));
        lookUpDashboard.screenshots?.forEach((selection) => promises.push(deleteCommentsFunc(selection!.id)));
        getFilesFunc(userGroup, lookUpDashboard.id).then((dashboardFiles) => {
          dashboardFiles?.forEach((file) => {
            promises.push(s3Delete({ path: file.key }));
            promises.push(deleteFileGql({ id: file.id, group: userGroup }));
          });
        });
        promises.push(deleteDashboardFunc(lookUpDashboard.id));

        Promise.all(promises).then(() => {
          setDashboards(prev => prev.filter(d => d.id !== lookUpDashboard.id));
          resolve(true);
        }, (error) => {
          reject(error);
        });
      });
      // eslint-disable-next-line
    }, [dashboards, userGroup]);

  const mappedOverviewQueries = useMemo(() =>
    new Map(dashboardsQueries.map(query => {
      const answer = JSON.parse(query?.answer || '{}')?.answer || '';

      if (query.title === 'Digest')
        return [`${query.dashboardId}:${query.title}`, splitter(answer)?.map((split) => split.sent)];

      return [`${query.dashboardId}:${query.title}`, answer];
    }))
  , [dashboardsQueries]);

  useEffect(() => {
    if (userGroup && !dashboardSubscriptionRef.current) {
      setDashboardsLoaded(false);
      getDashboards(userGroup).then((dashboardsData) => {
        setDashboards(ArrayUtils.sortByDescending(dashboardsData, 'createdAt'));
        setDashboardsLoaded(true);
      });
      getGroupDashboardQueriesFunc(userGroup).then((dashboardQueriesData) => {
        setDashboardsQueries(prev => [...dashboardQueriesData, ...prev].filter(query =>
          OverviewQueryTitles.includes(query.title)
        ));
      });
    }
  }, [userGroup]);

  useEffect(() => {
    if (!!externalDashboards.length) {
      const promises: Promise<DashboardQuery>[] = [];

      externalDashboards.forEach(dashboard => {
        promises.push(getPublicDashboardQueriesFunc(dashboard.id));
      })

      Promise.all(promises).then((dashboardQueriesData) => {
        setDashboardsQueries(prev => [...prev, ...dashboardQueriesData.flat()].filter((query: DashboardQuery) =>
          OverviewQueryTitles.includes(query.title)
        ));
      })
    }
  }, [externalDashboards, userGroup]);

  useEffect(() => {
    subscribeDashboards(dashboards);
    subscribeDashboardDeleted(dashboards);

    return () => {
      dashboardSubscriptionRef?.current?.unsubscribe();
      dashboardDeletedSubscriptionRef?.current?.unsubscribe();
    }
  }, [dashboards, subscribeDashboards, subscribeDashboardDeleted]);

  useEffect(() => {
    subscribeDashboardDeckCreated(dashboardDecksInProgress);
    subscribeDashboardDeckCreateFinished(dashboardDecksInProgress);

    return () => {
      dashboardDeckCreationSubscriptionRef?.current?.unsubscribe();
      dashboardDeckCreationFinishedSubscriptionRef?.current?.unsubscribe();
    }
  }, [dashboardDecksInProgress, subscribeDashboardDeckCreated, subscribeDashboardDeckCreateFinished]);

  const contextValue = useMemo(() => ({
    dashboardsLoaded,
    setDashboardsLoaded,
    workspace,
    setWorkspace,
    dashboards,
    setDashboards,
    externalDashboards,
    setExternalDashboards,
    dashboardsQueries,
    setDashboardsQueries,
    mappedOverviewQueries,
    isPublicView,
    setPublicView,
    deleteDashboard,
  }), [
    dashboardsLoaded,
    setDashboardsLoaded,
    workspace,
    setWorkspace,
    dashboards,
    setDashboards,
    externalDashboards,
    setExternalDashboards,
    dashboardsQueries,
    setDashboardsQueries,
    mappedOverviewQueries,
    isPublicView,
    setPublicView,
    deleteDashboard,
  ]);

  return (
    <DashboardsContext.Provider value={contextValue}>
      {children}
    </DashboardsContext.Provider>
  );
};

export default DashboardsProvider;
