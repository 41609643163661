import React from 'react';
import { Box, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {ReactComponent as CrunchBaseIcon} from "../../../assets/icons/crunchbase.svg";
import {ReactComponent as LinkedInIcon} from "../../../assets/icons/linkedin.svg";
import {ReactComponent as WorldGridIcon} from "../../../assets/icons/world.svg";
import { AnsweredQuestion } from '../../../contexts/DashboardQueriesContext';
import useContentCopy from '../../../hooks/useContentCopy';
import CollapsableKeyPeople from '../../atoms/CollapsableKeyPeople';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
    link: {
        color: '#666666',
        fontSize: '0.95rem',
        fontFamily: 'Inter !important',
        fontWeight: 'bold',
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none !important',
        }
    }
}));

const KeyPeopleAnswer: React.FC<{ keyPeople?: AnsweredQuestion|null  }> = ({ keyPeople }) => {
    const classes = useStyles();
    const { getCopiableId } = useContentCopy();

    const keyPeopleAnswer = keyPeople?.answer as AnsweredQuestion[] || [];
    const extendCopyId = getCopiableId('extend');

    const getIcon = (question: string | null) => {
        if (question) {
            if (question.toLowerCase().includes('crunchbase')) {
                return <CrunchBaseIcon className={classes.icon} />;
            } else if (['linkedin', 'company', 'people'].reduce((found: boolean, keyword: string) => found || question.toLowerCase().includes(keyword), false)) {
                return <LinkedInIcon className={classes.icon} />;
            } else {
                return <WorldGridIcon className={classes.icon} stroke="#7bd4d4" />;
            }
        }
    }

    return (<>
        <Stack id={extendCopyId} direction="column" spacing={2} alignItems="flex-start" justifyContent="center">
            {(Array.isArray(keyPeople?.answer) && !!keyPeopleAnswer.length) && (keyPeopleAnswer?.map((element: any, i) => (
                <Stack className={classes.masonryElement}
                    direction="column" spacing={1} alignItems="flex-start" justifyContent="center"
                    key={'key-people-answer-66-' + i}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        {getIcon('linkedin')}
                        <Typography
                            component="a"
                            className={classes.link}
                            href={element.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        > {element.title.split('|')?.[0]?.split('-')?.[0]} </Typography>
                    </Stack>
                    {!!element.content && (
                        <Box sx={{ ml: "32px !important", mr: "8px !important" }}>
                            <CollapsableKeyPeople rawHTML={element.content} />
                        </Box>
                    )}
                </Stack>
            )))}
        </Stack>
    </>);
}

export default KeyPeopleAnswer;
