import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { Worker } from "@react-pdf-viewer/core";
import { Amplify, Auth } from "aws-amplify";
import awsConfig from "../aws-exports";
import AuthProvider from "../contexts/AuthContext";
import FileStructureProvider from "../contexts/FileStructureContext";
import UsersProvider from "../contexts/UsersContext";
import SearchNavigationProvider from "../contexts/SearchNavigationContext";
import SearchProvider from "../contexts/SearchContext";
import theme from "../theme";
import App from "../components/App";
import PublicApp from "../components/PublicApp";
import { BreakpointProvider } from "react-socks";
import DashboardsProvider from "../contexts/DashboardsContext";
import DashboardQueriesProvider from "../contexts/DashboardQueriesContext";
import SubscriptionProvider from "../contexts/SubscriptionContext";
import GroupSettingsProvider from "../contexts/GroupSettingsContext";
import DashboardProvider from "../contexts/DashboardContext";
import BulkActionsProvider from "../contexts/BulkActionsContext";
import {isMobile} from 'react-device-detect';
import MobileApp from "../components/MobileApp";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

Amplify.configure({
  ...awsConfig,
  Storage: {
    bucket: awsConfig.aws_main_bucket,
    region: awsConfig.aws_project_region,
  },
});

const RootContainer = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then((sess) => {
        setLoggedIn(true);
        setLoading(false);
      })
      .catch(() => {
        setLoggedIn(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    assessLoggedInState();
  }, []);

  if (isMobile) {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <MobileApp />
        </Router>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {!loading && (
          <>
            {loggedIn ? (
              <AuthProvider>
                <FileStructureProvider>
                  <UsersProvider>
                    <SearchNavigationProvider>
                      <SearchProvider>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                          <SnackbarProvider maxSnack={4}>
                            <Router>
                              <BreakpointProvider>
                                <GroupSettingsProvider>
                                  <DashboardsProvider>
                                    <DashboardProvider>
                                      <DashboardQueriesProvider>
                                        <BulkActionsProvider>
                                          <SubscriptionProvider>
                                            <App />
                                          </SubscriptionProvider>
                                        </BulkActionsProvider>
                                      </DashboardQueriesProvider>
                                    </DashboardProvider>
                                  </DashboardsProvider>
                                </GroupSettingsProvider>
                              </BreakpointProvider>
                            </Router>
                          </SnackbarProvider>
                        </Worker>
                      </SearchProvider>
                    </SearchNavigationProvider>
                  </UsersProvider>
                </FileStructureProvider>
              </AuthProvider>
            ) : (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <SnackbarProvider maxSnack={4}>
                  <Router>
                    <BreakpointProvider>
                      <DashboardsProvider>
                        <DashboardProvider>
                          <DashboardQueriesProvider>
                            <PublicApp />
                          </DashboardQueriesProvider>
                        </DashboardProvider>
                      </DashboardsProvider>
                    </BreakpointProvider>
                  </Router>
                </SnackbarProvider>
              </Worker>
            )}
          </>
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default RootContainer;
