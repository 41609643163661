import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const useStyles = makeStyles((theme) => ({
    container: {
        width: 300,
        height: 200,
        border: `2px solid ${theme.colors.primary['200']}`,
        borderRadius: 16,
        padding: 16,
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.colors.neutral['700'],
    },
    mainIcon: {
        width: 60,
        height: 60,
        color: theme.colors.cyan['200'],
    },
    icon: {
        width: 24,
        height: 24,
    },
    button: {
        minWidth: 210,
        borderRadius: 24,
        fontFamily: 'Lato',
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
}));

const AddInvestor: React.FC<{ onModalOpen?: (open: boolean) => void}> = ({ onModalOpen }) => {
    const classes = useStyles();

    return (<>
        <Stack className={classes.container} spacing={2} alignItems="center" justifyContent="center">
            <AddCircleRoundedIcon className={classes.mainIcon} />
            <Typography className={classes.text}>
                {'Add an investor here'}
            </Typography>
            <Button variant="contained"
                className={classes.button}
                startIcon={<AddIcon className={classes.icon} />}
                onClick={() => onModalOpen?.(true)}>
                {'Add an investor'}
            </Button>
        </Stack>
    </>);
}

export default AddInvestor;