import React from 'react';
import { Button, Divider, Fade, Paper, Popper, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import theme from '../../../theme';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {DashboardsContext} from '../../../contexts/DashboardsContext';
import { deleteWorkspaceFunc } from '../../../lib/helper';
import useBulkWorkspaces from '../../../hooks/useBulkWorkspaces';
import ConfirmDialog from '../../modals/ConfirmDialog';
import ClearIcon from '@mui/icons-material/Clear';
import WorkspacesBulkShareEmailModal from '../../modals/investors/WorkspacesBulkShareEmailModal';
import { collectionKey } from '../../pages/InvestorsPage';
import { collectionKey as workspacesKey } from '../../modals/dashboard-details/ShareDealModal';

const useStyles = makeStyles((theme) => ({
    popup: {
        left: 'calc(100% - 300px) !important',
        zIndex: 1200,
    },
    modal: {
        width: 270,
        height: 'fit-content',
        padding: '12px 8px 0px 8px',
        border: '1px solid lightgray',
    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
        marginLeft: 8,
    },
    button: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        color: 'black',
        textTransform: 'none',
        "& > .MuiButton-startIcon": {
            "& > .MuiSvgIcon-root": {
                width: 24,
                height: 24,
                color: theme.palette.primary.light,
            }
        },
    },
}));

const InvestorsMultiAction = React.forwardRef<any>((_, ref) => {
    const classes = useStyles();
    const { setDashboards } = useContext(DashboardsContext);
    const { getBulkWorkspaces, clearBulk} = useBulkWorkspaces();

    const [loading, setLoading] = useState<string>('');
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const anchorRef = useRef<any>(ref);

    const yPosition = anchorRef?.current?.current?.offsetHeight + anchorRef?.current?.current?.offsetTop;

    const bulkCount = useMemo(() => getBulkWorkspaces(collectionKey).length, [getBulkWorkspaces]);

    const handleDelete = useCallback(() => {
        const promises: Promise<boolean>[] = [];
        const bulkWorkspaces = getBulkWorkspaces(collectionKey);

        setLoading('delete');
        bulkWorkspaces.forEach((workspace) => promises.push(deleteWorkspaceFunc({ id: workspace.id })));
        Promise.all(promises).then(() => {
            const bulkDashboardIds = bulkWorkspaces.map(workspace => workspace.id);

            setDashboards(prev => prev.filter(workspace => !bulkDashboardIds.includes(workspace.id)))
            clearBulk(workspacesKey);
            clearBulk(collectionKey);
        }).finally(() => {
            setLoading('');
            setConfirmDeleteOpen(false);
        });
    // eslint-disable-next-line
    }, [getBulkWorkspaces]);

    useEffect(() => {
        setLoading('');
        clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    return (<>
        <Popper open={!!bulkCount} className={classes.popup} sx={{ top: `${yPosition}px !important` }} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                    <Paper className={classes.modal} elevation={3} square={false}>
                        <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%" mb={1}>
                            <Typography className={classes.title}>{`${bulkCount} investor selected`}</Typography>
                            <Divider sx={{ width: '100%' }}/>
                            <Stack direction="column" alignItems="flex-start" justifyContent="center">
                                <Button className={classes.button}
                                    startIcon={<ShareIcon width={24} height={24} stroke={theme.palette.primary.light} />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setShareModalOpen(true);
                                    }}> {'Share workspaces via email'} </Button>
                                <Button variant="text"
                                    className={classes.button}
                                    startIcon={<DeleteOutlineIcon
                                        sx={{ color: `${theme.colors.error['500']} !important` }} />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setConfirmDeleteOpen(true);
                                    }}
                                    sx={{ color: `${theme.colors.error['500']} !important` }}
                                > {'Delete'} </Button>
                                <Divider sx={{ width: '100%', margin: '4px 0 !important' }}/>
                                <Button className={classes.button}
                                    startIcon={<ClearIcon />}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        clearBulk(collectionKey);
                                    }}
                                    sx={{ color: `${theme.palette.primary.light} !important` }}
                                > {'Clear selection'} </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Fade>
            )}
        </Popper>
        {shareModalOpen && (
            <WorkspacesBulkShareEmailModal
                isOpen={shareModalOpen}
                collectionKey={collectionKey}
                onClose={() => setShareModalOpen(false)}/>
        )}
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete the selected investors?"
            open={confirmDeleteOpen}
            loading={loading === 'delete'}
            confirmCallback={handleDelete}
            cancelCallback={() => setConfirmDeleteOpen(false)}
        />
    </>);
});

export default InvestorsMultiAction;
