import { Dispatch, useCallback, useContext, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Badge, Box, Divider, IconButton, Popover, Stack, Tab, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';
import {ReactComponent as CallIcon} from '../../../assets/icons/call.svg';
import {ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import {ReactComponent as FolderIcon} from '../../../assets/icons/folder.svg';
import { scrollbarStyle } from '../../../shared/dashboard';
import theme from '../../../theme';
import { Dashboard, DocSendIngestion, EmailBody, UnassignedContent, UnassignedContentType } from '../../../types/files';
import UnassignedDocSendContent from '../../molecules/unassigned-contents/UnassignedDocSendContent';
import UnassignedEmailContent from '../../molecules/unassigned-contents/UnassignedEmailContent';
import ConfirmDialog from '../ConfirmDialog';
import { AuthContext } from '../../../contexts/AuthContext';
import classNames from 'classnames';
import { EmailDeal } from '../../../hooks/useEmailDealsExtraction';
import ActivityFeed from '../../molecules/unassigned-contents/ActivityFeed';
import { DashboardsContext } from '../../../contexts/DashboardsContext';
import { UnassignedContentData } from '../../atoms/UnassignedContentsBadge';

enum ActionTab {
    ActionNeeded = 'Action needed',
    ActivityFeed = 'Activity Feed',
}

const useStyles = makeStyles((theme) => ({
    modal: {
        width: '550px',
        height: '80vh',
        left: '-32px',
        '& > .MuiPaper-root': {
            width: '100%',
            height: '100%',
            padding: 16,
            marginTop: 8,
            borderRadius: 16,
            overflow: 'hidden',
        }
    },
    contentIcon: {
        width: 32,
        height: 32,
    },
    tabs: {
      "& .MuiTabs-scroller": {
        "& .MuiTabs-flexContainer": {
          display: 'flex',
          width: '100%',
          height: 'auto',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          "& .MuiTab-iconWrapper": {
            marginRight: '2px',
          }
        },
      },
      "& .MuiTabs-indicator": {
        height: 3,
        background: theme.colors.primary['700'],
      }
    },
    tab: {
      padding: 'unset !important',
      minWidth: '242px !important',
      minHeight: '40px !important',
    },
    tabText: {
      color: `${theme.colors.neutral['500']}`,
      fontFamily: 'Inter !important',
      fontSize: '0.95rem !important',
      fontWeight: 'bold !important',
      textTransform: 'none !important' as any,
    },
    selectedTabText: {
      color: `${theme.colors.neutral['700']}`,
    },
    tabPanel: {
      width: '100% !important',
      height: 'fit-content !important',
      padding: 'unset !important',
    },
    badge: {
        '& .MuiBadge-badge': {
            top: 0,
            right: -16,
            width: 20,
            height: 20,
            padding: '6px 3px',
            borderRadius: '50%',
            background: theme.colors.orange['400'],
            color: 'white',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            zIndex: 2000,
        },
    },
    notificationBlock: {
        width: '100%',
        height: 150,
        transition: 'height .3s ease',
    },
    notificationIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        fill: theme.palette.primary.main,
    },
    notificationText: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    title: {
        display: '-webkit-box',
        width: 'fit-content',
        fontSize: '1rem',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: '#7bd4d4',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        maxWidth: '32ch',
    },
    link: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

const UnassignedContentsModal: React.FC<{
    unassignedContents: UnassignedContentData[],
    sortedActivities: { dashboard: Dashboard, emailBody?: EmailBody, createdAt: string, type: 'attached' | 'created' }[],
    anchorEl: HTMLElement | null,
    onAnchorEl: Dispatch<React.SetStateAction<HTMLElement | null>>,
    onSaveEmailDeals?: (content: UnassignedContent, dashboard?: Dashboard, deals?: EmailDeal[]) => Promise<any>,
    onDocsendIngestion: (unassignedContentId: string, docsend: DocSendIngestion, password: string) => Promise<any>,
    onRemoveContent?: (unassignedContentId: string) => Promise<any>,
}> = ({ unassignedContents, sortedActivities, anchorEl,
        onAnchorEl, onSaveEmailDeals, onDocsendIngestion, onRemoveContent }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);
    const { dashboards } = useContext(DashboardsContext);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<ActionTab>(ActionTab.ActionNeeded);
    const [deleteDashboardLoading, setDeleteDashboardLoading] = useState<boolean>(false);
    const [currentContent, setCurrentContent] = useState<UnassignedContent|null>(null);
    const menuOpen = Boolean(anchorEl);

    const getLabel = useCallback((tab: ActionTab, count?: number) => (
      <Typography className={classNames(classes.tabText, selectedTab === tab && classes.selectedTabText)}>
        {`${tab.charAt(0).toUpperCase()}${tab.slice(1).replace('-', ' ')}`}
        {!!count && (<>
            <Badge className={classes.badge} badgeContent={count} />
            <Typography display="inline" pr={3} />
        </>)}
      </Typography>
    // eslint-disable-next-line
    ), [selectedTab]);

    const getIcon = useCallback((type: UnassignedContentType) => {
        switch (type) {
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
                return <EmailIcon className={classes.contentIcon} sx={{ fill: '#7bd4d4 !important'}} />;
            case UnassignedContentType.CALL:
                return <CallIcon className={classes.contentIcon} stroke="#7bd4d4" />;
            case UnassignedContentType.DOCSEND:
                return  <DashboardIcon className={classes.contentIcon} fill={theme.palette.primary.main} />;
            case UnassignedContentType.OTHER:
            default:
                return <FolderIcon className={classes.contentIcon} fill="#7bd4d4" />;
        }
        // eslint-disable-next-line
    }, []);

    const getContent = useCallback((content?: UnassignedContent) => {
        switch (content?.type) {
            case UnassignedContentType.EMAIL:
            case UnassignedContentType.FULL_EMAIL:
                return 'Email';
            case UnassignedContentType.CALL:
                return 'Call';
            case UnassignedContentType.DOCSEND:
                return 'Docsend';
            case UnassignedContentType.OTHER:
            default:
                return 'Other content';
        }
        // eslint-disable-next-line
    }, []);

    const extractDocsendContent = (content?: UnassignedContent) =>
       (content ? JSON.parse(content?.body || '{}') : {}) as DocSendIngestion;

    const closeDeleteDialog = useCallback(() => {
        setCurrentContent(null);
        setDeleteDashboardLoading(false);
        setConfirmDeleteOpen(false);
    }, []);

    const handleRemove = useCallback(async () => {
        if (!currentContent || !onRemoveContent)
            return;

        setDeleteDashboardLoading(true);
        await onRemoveContent?.(currentContent.id!);
        setCurrentContent(null);
        closeDeleteDialog();
    }, [currentContent, onRemoveContent, closeDeleteDialog]);

    return (<>
        <Popover open={menuOpen} className={classes.modal}
            anchorEl={anchorEl}
            onClose={() => onAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}>
            <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%" height="100%">
                <TabContext value={selectedTab}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" py={1}>
                        <EmailIcon sx={{ width: 32, height: 32, color: theme.colors.cyan['500'] }} />
                        <Typography fontFamily="Inter" fontSize="1.1rem" fontWeight="bold" color="black">
                            {'Your Notissia inbox'}
                        </Typography>
                    </Stack>
                    {(!!unassignedContents.length || !!dashboards.length) ? (<>
                        <TabList className={classes.tabs} onChange={(_, value) => setSelectedTab(value)}>
                            {Object.values(ActionTab).map((tab, i) => (
                                <Tab className={classes.tab}
                                    label={getLabel(tab, !i ? unassignedContents.length : undefined)}
                                    value={tab}
                                    key={'unassigned-contents-modal-238-' + i}/>
                                ))}
                        </TabList>
                        <Divider sx={{ width: '100%' }}/>
                        <Box width="100%" height="100%" sx={{ overflow: 'auto', ...scrollbarStyle }}>
                            <TabPanel className={classes.tabPanel} value={ActionTab.ActionNeeded}>
                                {unassignedContents.length ? (
                                    unassignedContents.map((contentData) => getContent(contentData.content) === 'Docsend' ? (
                                        <UnassignedDocSendContent
                                            docsend={extractDocsendContent(contentData.content)}
                                            createdAt={contentData.createdAt}
                                            onDelete={() => {
                                                setCurrentContent(contentData.content);
                                                setConfirmDeleteOpen(true);
                                            }}
                                            onIngestion={(docsend, password) => onDocsendIngestion(contentData.content.id!, docsend, password)}
                                            key={`unassigned-contents-modal-243-${contentData.content.id}`}
                                        />
                                    ) : (
                                        <UnassignedEmailContent
                                            contentData={contentData}
                                            createdAt={contentData.createdAt}
                                            icon={getIcon(contentData.content.type as UnassignedContentType)}
                                            onSave={onSaveEmailDeals}
                                            onDelete={(content) => {
                                                setCurrentContent(content);
                                                setConfirmDeleteOpen(true);
                                            }}
                                            key={'unassigned-contents-modal-255-' + contentData.content.id}
                                        />
                                    ))
                                ): (
                                    <Stack alignItems="center" justifyContent="center" mt={30}>
                                        <Typography fontFamily="Inter" fontSize="1rem" color="#666666">
                                            {'No actions needed.'}
                                        </Typography>
                                    </Stack>
                                )}
                            </TabPanel>
                            <TabPanel className={classes.tabPanel} value={ActionTab.ActivityFeed}>
                                {sortedActivities.map((activity, i) => (
                                    <ActivityFeed activity={activity}
                                        key={`unassigned-contents-modal-285-${i}-` + activity.dashboard.id} />
                                ))}
                            </TabPanel>
                        </Box>
                    </>) : (<>
                        <Divider sx={{ width: '100%' }}/>
                        <Stack direction="column" spacing={1} alignItems="center" justifyContent="center" width="100%" height="100%" pb={5}>
                            <EmailIcon sx={{ width: 60, height: 60, color: theme.colors.cyan['500'] }} />
                            <Typography fontFamily="Inter" fontSize="1.3rem" fontWeight="bold" color="black" py={1}>
                                {'No emails yet.'}
                            </Typography>
                            <Typography fontFamily="Inter" fontSize="1rem" color="black">
                                {'Forward any deal flow emails to: '}
                            </Typography>
                            <Typography component="span" className={classes.link}
                                onClick={() => window.open(`mailto: ${userGroup}@notissia.awsapps.com`, '_self', 'noopener, noreferrer')}
                                display="inline">
                                {`${userGroup}@notissia.awsapps.com`}
                                <CopyToClipboard text={`${userGroup}@notissia.awsapps.com`}
                                    options={{ format: "text/plain" }}
                                    onCopy={() => {
                                        enqueueSnackbar(`Successfully copied to clipboard.`,
                                            { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                                    }}>
                                    <IconButton size="small" sx={{ color: theme.colors.cyan['500'] }}>
                                        <ContentCopyIcon sx={{ width: 24, height: 24, }} />
                                    </IconButton>
                                </CopyToClipboard>
                            </Typography>
                        </Stack>
                    </>)}
                </TabContext>
            </Stack>
        </Popover>
        <ConfirmDialog
            title="Delete confirmation"
            content="Are you sure you want to delete this data?"
            open={confirmDeleteOpen}
            loading={deleteDashboardLoading}
            confirmCallback={handleRemove}
            cancelCallback={closeDeleteDialog}
        />
    </>);
}

export default UnassignedContentsModal;
