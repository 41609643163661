import React, { useCallback, useEffect } from 'react';
import { Chip, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { CompanyInfoFields } from '../../../contexts/DashboardsContext';
import storage from '../../../utils/storage';

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    chip: {
        width: 'fit-content',
        height: '32px !important',
        padding: '4px !important',
        border: `2px solid ${theme.colors.neutral['400']} !important`,
        fontFamily: 'Inter !important',
        fontSize: '0.8rem !important',
        textTransform: 'none',
        backgroundColor: 'unset !important',
        color: `${theme.colors.neutral['600']} !important`,
        "&:hover": {
            backgroundColor: "rgba(4, 130, 144, 0.04) !important",
        },
    },
    activeChip: {
        border: `unset !important`,
        fontWeight: 'bold !important',
        backgroundColor: '#E0F1F1 !important',
        color: '#048290 !important',
        "&:hover": {
            backgroundColor: "rgba(4, 130, 144, 0.04) !important",
        },
    },
    expandButton: {
        textTransform: 'none !important' as any,
        fontFamily: 'Inter !important',
        fontSize: '0.65rem !important',
        fontWeight: 700,
        color: `${theme.colors.neutral['500']} !important`,
        "& .MuiSvgIcon-root": {
            fontSize: 24,
        },
        "&:hover": {
            background: 'unset !important',
        },
    },
}));

const QueryFieldSelection: React.FC<{
    queryTitles: string[],
    onQueryTitles: React.Dispatch<React.SetStateAction<string[]>>,
}> = ({queryTitles, onQueryTitles }) => {
    const classes = useStyles();

    const handleChipSelect = useCallback((field: string) => {
        if (field !== 'Description') {
            onQueryTitles(prev => {
                if (prev.includes(field))
                    return prev.filter(title => title !== field);

                return [...prev, field];
            });
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const storedTitles = storage.getItem('config.queryFieldSelection');

        if (!!storedTitles)
            onQueryTitles(storedTitles.split('|'));
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        storage.setItem('config.queryFieldSelection', queryTitles.join('|'));
    }, [queryTitles]);

    return (<>
        <Stack direction="row" gap={1} flexWrap="wrap" my={1}>
            {CompanyInfoFields.map((field, i) => (
                <Chip className={classnames(classes.chip, queryTitles.includes(field) && classes.activeChip)}
                    label={field}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleChipSelect(field);
                    }}
                    sx={{ cursor: (field === 'Description') ? 'auto': 'pointer' }}
                    key={'query-field-selection-84-' + i}/>
            ))}
        </Stack>
    </>);
}

export default QueryFieldSelection;
