import { CompanyFile } from "./files";

export interface SearchResult {
  id?: string;
  fileKey: string;
  page: number;
  sentence: string;
  keyword: string;
  sortMetric: number,
}

export enum SearchEntity {
  All = "All content",
  Documents = "Documents",
  Dashboards = "Dashboards"
}

export enum SearchType {
  Keyword = "Keyword",
  Title = "Title",
}

export enum SearchDocumentType {
  Pdf = "PDF",
  Excel = "Excel (Beta)",
  Word = "Word",
  Legal = "Legal",
  Financial = "Financial",
  Presentation = "Presentation",
  PowerPoint = "PowerPoint",
  Other = "Other",
}

export interface Lemmatized {
  original: string;
  lemmatized: string;
}

export enum CommentSearchType {
  User = "User",
  Content = "Content"
}

export enum SearchKeywordOperation {
  And = "And",
  Or = "Or"
}

export enum GroupByEnum {
  Document = "Document",
  Content = "Content"
}

export interface AdvancedSearchObject {
  id: string;
  value: string | null;
  folder: null | CompanyFile;
  fileValue: string | null;
  file: null | CompanyFile;
}

export type NoteMetricKeys =
  'Fundraising' |
  'Revenue' |
  'MRR' |
  'ARR' |
  'Gross Revenue' |
  'Net Revenue' |
  'TCV' |
  'ACV' |
  'EBITDA' |
  'Gross Margins' |
  'Product Margin' |
  'GMV' |
  'LTV/CAC' |
  'Valuation' |
  'Margin' |
  'Retention';

export type MarketMetricTitles =
  'Funding' |
  'Other Traction Metrics' |
  'Revenue' |
  'Gross Revenue' |
  'Net Revenue' |
  'MRR' |
  'ARR' |
  'Total Contract Value' |
  'Annual Contract Value' |
  'EBITDA' |
  'Gross Margins' |
  'Product Margins' |
  'Gross Merchandise Value' |
  'LTV/CAC' |
  'Valuation' |
  'Usage' |
  'Retention' |
  'Growth';


export type MetricData = { date: string | null; units: string | null; value: number | null; };

export type NoteMetric = { [key in NoteMetricKeys]: MetricData; };

export interface AnswerQuestionResponse {
  answer: string | NoteMetric | InvestmentThesisScore[] | {
    id?: string;
    title?: string;
    link?: string;
    date?: string;
    category?: ArticleCategory;
    content?: string;
    summary?: string;
    investors?: string[];
    score?: number;
  }[];
  history?: NoteMetric[];
  sources?: Array<{ page: number, filename: string }>;
  type: AnswerQuestionResponseType;
}

export type AnswerQuestionResponseComplete = AnswerQuestionResponseText |
  AnswerQuestionResponseUrl |
  AnswerQuestionResponseUrlList |
  AnswerQuestionResponseHtmlList |
  AnswerQuestionResponseFundingList |
  AnswerQuestionResponseMetrics |
  AnswerQuestionResponseEmailSummaryList |
  AnswerQuestionResponseScreenshotSummaryList;

export type AnswerQuestionResponseText = {
  answer: string;
  sources?: Array<{ page: number, filename: string }>;
  type: AnswerQuestionResponseType.TEXT;
}

export type AnswerQuestionResponseUrl = {
  answer?: string;
  type: AnswerQuestionResponseType.URL;
}

export type AnswerQuestionResponseUrlList = {
  answer: {
    title: string;
    content?: string;
    category?: ArticleCategory;
    investors: string[];
    link: string;
    date?: string;
    score?: number;
  }[];
  type: AnswerQuestionResponseType.URL_LIST;
}

export type AnswerQuestionResponseHtmlList = {
  answer: {
    category?: string;
    content?: string;
    date?: string;
  }[];
  type: AnswerQuestionResponseType.HTML_LIST;
}

export type AnswerQuestionResponseFundingList = {
  answer?: any[];
  type: AnswerQuestionResponseType.FUNDING_LIST;
}

export type AnswerQuestionResponseMetrics = {
  answer: NoteMetric;
  type: AnswerQuestionResponseType.METRICS;
}

export type AnswerQuestionResponseEmailSummaryList = {
  answer: EmailSummary[];
  type: AnswerQuestionResponseType.SUMMARY_LIST;
}

export type AnswerQuestionResponseScreenshotSummaryList = {
  answer: ScreenshotSummary[];
  type: AnswerQuestionResponseType.SUMMARY_LIST;
}

export type AnswerQuestionResponseInvestmentThesisScoreList = {
  answer: InvestmentThesisScore[];
  type: AnswerQuestionResponseType.THESIS_SCORE_LIST;
}

export enum InvestmentThesisScoreDetailNames {
  GEOGRAPHY = 'Geography',
  SECTOR = 'Sector',
  STAGE = 'Stage',
  ADDITIONAL_DETAILS = 'Additional Details',
};

export type InvestmentThesisScore = {
  workspaceId?: string;
  score: number;
  details: InvestmentThesisScoreDetail[];
}

export type InvestmentThesisScoreDetail = {
  name: InvestmentThesisScoreDetailNames;
  score: number;
  items?: string[] | undefined;
}

export type AnswerQuestionResponseWithStatus = AnswerQuestionResponse & {
  shouldRetry?: boolean;
};

export interface ScreenshotSummary {
  id: string;
  summary: string;
}

export interface EmailSummary {
  id: string;
  summary: string;
}

export enum AnswerQuestionResponseType {
  TEXT = "text",
  URL = "url",
  URL_LIST = "url_list",
  HTML_LIST = "html_list",
  FUNDING_LIST = "funding_list",
  METRICS = "metrics",
  SUMMARY_LIST = "summary_list",
  THESIS_SCORE_LIST = "thesis_score_list",
}

export const NoInfoYet: AnswerQuestionResponseText = {
  answer: 'No information yet.',
  type: AnswerQuestionResponseType.TEXT,
};

export interface FileChunk {
  id: string,
  fileId: string;
  searchGroup: string;
  text: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum ArticleCategory {
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  FUNDRAISING = 'FUNDRAISING',
  OTHER = 'OTHER',
}

export enum ArticleCategoryDisplay {
  ANNOUNCEMENT = 'Announcement',
  SOCIAL_MEDIA = 'Social Media',
  FUNDRAISING = 'Financing',
  OTHER = 'Blog & Mentions'
}

//export interface SearchResultsPerFile : SearchResult[]
