import { FC, useCallback, useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    icon: {
        padding: 'unset',
        color: theme.colors.neutral['600'],
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
}));

const UnassignedContentsMenu: FC<{ onDelete?: () => void }> = ({ onDelete }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleDelete = useCallback(() => {
        onDelete?.();
        setAnchorEl(null);
    }, [onDelete]);

    return (<>
        <Box onClick={(e) => e.stopPropagation()}>
            <IconButton className={classes.icon}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disableRipple> <MoreVertIcon />  </IconButton>
            <Menu open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={handleDelete} sx={{ color: theme.colors.error['500']}}>
                    {'Delete'}
                </MenuItem>
            </Menu>
        </Box>
    </>);
}

export default UnassignedContentsMenu;
