import { useCallback, useContext, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box, Divider, Popover, Stack, Switch, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ErrorIcon from '@mui/icons-material/Error';
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';
import { ChromeExtensionLink } from "../../../shared/dashboard";
import { ReactComponent as ChromeIcon } from "../../../assets/icons/chrome-colored.svg";

const useStyles = makeStyles((theme) => ({
    modal: {
        width: 400,
        zIndex: 1300,
        '& > .MuiPaper-root': {
            width: '100%',
            height: 'fit-content',
            padding: '16px 12px',
            marginTop: 8,
            borderRadius: 16,
        }
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        textTransform: 'none',
        textDecoration: 'none',
        "&:hover": {
            background: 'transparent',
        },
    },
    title: {
        fontWeight: 'bold',
        fontFamily: 'Inter',
        color: '#000',
    },
    action: {
        width: '100%',
        fontWeight: 'bold',
        color: '#7bd4d4',
        "&:hover": {
            background: 'rgba(4, 130, 144, 0.04)'
        },
        cursor: 'pointer',
    },
    homeIcon: {
        width: 24,
        height: 24,
        fill: '#048290',
    },
    titleIcon: {
        width: 24,
        height: 24,
        fill: '#048290',
    },
    stopIcon: {
        width: 16,
        height: 16,
        color: theme.colors.error['500'],
    },
    switchButton: {
        width: 80,
        height: 36,
        padding: 0,
        transform: 'scale(0.7)',
        '& .MuiSwitch-switchBase': {
            padding: '6px',
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(40px)',
                '& + .MuiSwitch-track': {
                    border: '2px solid #048290',
                    backgroundColor: '#fff',
                    opacity: 1,
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 24,
            height: 24,
            color: '#048290',
        },
        '& .MuiSwitch-track': {
            borderRadius: '20px',
            border: '2px solid #048290',
            backgroundColor: '#fff',
            opacity: 1,
            '&:before, &:after': {
                position: 'absolute',
                color: '#7bd4d4',
                fontFamily: 'Inter',
                fontSize: '0.8rem',
                fontWeight: 600,
            },
            '&:before': {
                content: '"ON"',
                padding: '7px',
            },
            '&:after': {
                content: '"OFF"',
                padding: '7px 0 0 43px',
            },
        },
    },
    icon: {
        width: 32,
        height: 32,
    },
    subText: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 400,
        color: theme.colors.neutral['500'],
    },
    link: {
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

const AutomationSettings: React.FC<{ flags?: any }> = ({ flags }) => {
    const classes = useStyles();
    const { externalSync, autoIngestFromEmail, autoSendDigestEmail,
        setExternalSync, setAutoIngestFromEmail, setAutoSendDigestEmail } = useContext(GroupSettingsContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleAutoIngestFromEmail = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoIngestFromEmail(event.target.checked);
    }, [setAutoIngestFromEmail]);

    const handleExternalSync = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setExternalSync(event.target.checked);
    }, [setExternalSync]);

    const handleAutoSendDigestEmail = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setAutoSendDigestEmail(event.target.checked);
    }, [setAutoSendDigestEmail]);

    return (<>
        <Button variant="text"
            className={classes.label}
            onClick={event => setAnchorEl(event.currentTarget)}
            endIcon={<SettingsIcon className={classes.homeIcon} />} >
           {'Automation settings'}
        </Button>
        <Popover open={Boolean(anchorEl)}
            className={classes.modal}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
            onClose={() => setAnchorEl(null)}>
            <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%">
                <Typography className={classes.title} display="inline">
                    {'Automation Settings'}
                </Typography>
                <Divider sx={{ width: '100%' }} />
                <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                            <Typography fontFamily="Inter" fontSize="0.9rem">
                                {'Deal flow ingestions from emails'}
                            </Typography>
                            <Tooltip placement="top-start" title={<Typography component="span" fontSize="0.8rem">
                                    {`Deal flow ingestions from emails `}
                                </Typography>}
                                componentsProps={{tooltip:{sx:{
                                    display: 'flex', alignItems: 'center', borderRadius: 'unset', transform: 'translate(0px) !important',
                                    backgroundColor: 'rgba(97, 97, 97)', width: '220px', height: 'auto',  padding: 2,
                                }}}}>
                                <ErrorIcon sx={{ height: '20px', width: '20px', transform: 'rotate(180deg)', fill: 'lightgray' }} />
                            </Tooltip>
                        </Stack>
                        <Switch className={classes.switchButton} checked={autoIngestFromEmail} onChange={handleAutoIngestFromEmail} disableRipple />
                    </Stack>
                    {flags.zapierPlan && (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                                <Typography fontFamily="Inter" fontSize="0.9rem">
                                    {'Connected third-party tools sync'}
                                </Typography>
                                <Tooltip placement="top-start" title={<Typography component="span" fontSize="0.8rem">
                                        {`You can sync this data to any connected app through `}
                                        <Typography
                                            component="a"
                                            href="https://zapier.com/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            display="inline"
                                            sx={{
                                                color: '#7bd4d4',
                                                fontSize: '0.8rem',
                                                textDecoration: 'none',
                                                "&:hover": { textDecoration: 'none !important', }
                                            }}
                                        >Zapier</Typography>.
                                        <Box m="12px" />
                                        {` Learn more about getting set up with Zapier `}
                                        <Typography
                                            component="a"
                                            href="/integrations"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            display="inline"
                                            sx={{
                                                color: '#7bd4d4',
                                                fontSize: '0.8rem',
                                                textDecoration: 'none',
                                                "&:hover": { textDecoration: 'none !important', }
                                            }}
                                        >here</Typography>.
                                    </Typography>}
                                    componentsProps={{tooltip:{sx:{
                                        display: 'flex', alignItems: 'center', borderRadius: 'unset', transform: 'translate(0px) !important',
                                        backgroundColor: 'rgba(97, 97, 97)', width: '210px', height: '110px',  padding: 2,
                                    }}}}>
                                    <ErrorIcon sx={{ height: '20px', width: '20px', transform: 'rotate(180deg)', fill: 'lightgray' }} />
                                </Tooltip>
                            </Stack>
                            <Switch className={classes.switchButton} checked={externalSync} onChange={handleExternalSync} disableRipple />
                        </Stack>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Typography fontFamily="Inter" fontSize="0.9rem">
                            {'Send weekly digests via email'}
                        </Typography>
                        <Tooltip placement="top-start" title={<Typography component="span" fontSize="0.8rem">
                                {`Send weekly digests via email `}
                            </Typography>}
                            componentsProps={{tooltip:{sx:{
                                display: 'flex', alignItems: 'center', borderRadius: 'unset', transform: 'translate(0px) !important',
                                backgroundColor: 'rgba(97, 97, 97)', width: '210px', height: 'auto',  padding: 2,
                            }}}}>
                            <ErrorIcon sx={{ height: '20px', width: '20px', transform: 'rotate(180deg)', fill: 'lightgray' }} />
                        </Tooltip>
                    </Stack>
                    <Switch className={classes.switchButton} checked={autoSendDigestEmail} onChange={handleAutoSendDigestEmail} disableRipple />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="100%">
                    <ChromeIcon className={classes.icon} />
                    <Typography className={classes.subText}>
                        {'Install our'}
                        <Typography component="span" className={classes.link}
                            onClick={() => window.open(ChromeExtensionLink, '_blank', 'noopener, noreferrer')} display="inline">
                            &nbsp;{'Chrome Extension'}
                        </Typography>
                        <br/><span>{'to auto-ingest deals from emails.'}</span>
                    </Typography>
                </Stack>
            </Stack>
        </Popover>
    </>);
}

export default withLDConsumer()(AutomationSettings);
