import { useCallback, useContext, useRef, useState } from "react";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import WorkspacesSearchOrCreate from "../../molecules/workspaces-search-create/WorkspacesSearchOrCreate";
import InvestorsTable from "../../organisms/investors/InvestorsTable";
import NoWorkspacesFoundView from "../../molecules/workspaces-empty/NoWorkspacesFoundView";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import { DashboardContext } from "../../../contexts/DashboardContext";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import { Dashboard } from "../../../types/files";
import ArrayUtils from "../../../utils/ArrayUtils";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import WorkspacesBulkShareEmail from "../../atoms/workspaces/WorkspacesBulkShareEmail";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { collectionKey as partialKey } from "../../pages/InvestorsPage";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            maxWidth: 'unset',
            maxHeight: 'unset',
            width: '75vw',
            height: '80vh',
            padding: 16,
            borderRadius: 24,
            overflow: 'hidden',
        },
    },
    dialogTitle: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 32,
        padding: 'unset',
        "& > span": {
            width: '100%',
            maxWidth: 250,
            color: 'black',
            fontWeight: 'bold',
            fontFamily: 'Inter',
            fontSize: '1.5rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        }
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    saveAllButton: {
        minWidth: 100,
        width: 'auto',
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 20,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
        "& > span": {
            margin: 'unset',
        }
    },
    saveIcon: {
        width: 24,
        height: 24,
        color: theme.palette.primary.main,
    },
}));

export const collectionKey = `${partialKey}:deals`;

const ShareDealModal: React.FC<{ isOpen: boolean, onClose: () => void, }> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const { dashboard, setDashboard } = useContext(DashboardContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const {getBulkWorkspaces, clearBulk, assignToDashboards} = useBulkWorkspaces();

    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const handleUpdate = useCallback(() => {
        setLoading(true);
        assignToDashboards(collectionKey, [dashboard!.id])
            .then((dashboardsData: Dashboard[]) => {
                // force update dashboards list
                const updatedDashboards = [...dashboards].map(current => {
                    const updates = dashboardsData.filter(updated => updated.id === current.id);

                    if (!!updates.length) {
                        const dashboardUpdate = ArrayUtils.sortByDescending(updates, 'createdAt')[0];

                        if (dashboard!.id === current.id)
                            setDashboard(dashboardUpdate);

                        return dashboardUpdate;
                    }

                    return current;
                });

                setDashboards(ArrayUtils.sortByDescending(updatedDashboards, 'createdAt'));
            }).finally(() => {
                enqueueSnackbar(`${dashboard!.title} has been successfully added to selected workspaces`, {
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "right",
                    },
                    autoHideDuration: 4000,
                });
                setLoading(false);
            });
    // eslint-disable-next-line
    }, [assignToDashboards, dashboard, dashboards]);

    const handleClose = useCallback(() => {
        timerRef.current = setTimeout(() => {
            clearBulk(collectionKey);
            onClose();
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [clearBulk]);

    if (!dashboard)
        return (<></>);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={handleClose}>
            <DialogTitle className={classes.dialogTitle}>
                <span>{`Share ${dashboard.title}`}</span>
                {!!workspaces.length && (
                    <Box width="100%" maxWidth="400px" mt={1}>
                        <WorkspacesSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                    </Box>
                )}
                <Box width="190px" />
            </DialogTitle>
            {!!workspaces.length && (<Divider sx={{ margin: '8px 0'}} />)}
            <DialogContent className={classes.dialogContent}>
                {!!workspaces.length ? (
                    <InvestorsTable
                        dashboard={dashboard}
                        collectionKey={collectionKey}
                        search={search}
                        forSharing />
                ) : (<NoWorkspacesFoundView noResult />)}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="flex-start">
                        {!!workspaces.length && (
                            <LoadingButton variant="outlined"
                                className={classes.saveAllButton}
                                startIcon={!loading && (<BookmarkBorderIcon className={classes.saveIcon} />)}
                                disabled={!getBulkWorkspaces(collectionKey).length}
                                loading={loading}
                                onClick={handleUpdate}>
                                {'Save selected'}
                            </LoadingButton>
                        )}
                    </Stack>
                    <Stack direction="row"spacing={2} alignItems="center" justifyContent="flex-end">
                        <Button className={classes.cancelButton}
                            onClick={handleClose}> {'Cancel'} </Button>
                        {!!workspaces.length && (<WorkspacesBulkShareEmail />)}
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}
export default ShareDealModal;