import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailIcon from '@mui/icons-material/MailOutlineRounded';
import { AuthContext } from "../../../contexts/AuthContext";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useSnackbar } from "notistack";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 300,
        height: 200,
        border: `2px solid ${theme.colors.primary['200']}`,
        borderRadius: 16,
        padding: 16,
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.colors.neutral['700'],
    },
    subtext: {
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        textAlign: 'center',
        color: theme.colors.neutral['500'],
    },
    link: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.cyan['400'],
    },
    mainIcon: {
        width: 70,
        height: 70,
        color: theme.colors.cyan['200'],
    },
    icon: {
        width: 24,
        height: 24,
    },
    button: {
        minWidth: 210,
        borderRadius: 24,
        fontFamily: 'Lato',
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
}));

const ForwardCompanies: React.FC<{}> = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup} = useContext(AuthContext);

    return (<>
        <Stack className={classes.container} spacing={2} alignItems="center" justifyContent="center">
            <EmailIcon className={classes.mainIcon} />
            <Stack spacing="4px" alignItems="center" justifyContent="center">
                <Typography className={classes.text}>
                    {'Forward companies to: '}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-around">
                    <Tooltip title={`${userGroup}@notissia.awsapps.com`} placement="top">
                        <Typography className={classes.link}>
                            {`${userGroup}@notissia.awsapps.com`}
                        </Typography>
                    </Tooltip>
                    <CopyToClipboard text={`${userGroup}@notissia.awsapps.com`}
                        options={{ format: "text/plain" }}
                        onCopy={() => {
                            enqueueSnackbar(`Successfully copied to clipboard.`,
                                { anchorOrigin: { vertical: "bottom", horizontal: "right" }, });
                        }}>
                        <IconButton size="small" sx={{ color: theme.colors.cyan['500'] }}>
                            <ContentCopyIcon sx={{ width: '20px', height: '20px', }} />
                        </IconButton>
                    </CopyToClipboard>
                </Stack>
                <Typography className={classes.subtext}>
                    {'(company website must be in the email)'}
                </Typography>
            </Stack>
        </Stack>
    </>);
}

export default ForwardCompanies;