import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Workspace } from "../../../types/files";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";

const useStyles = makeStyles(() => ({
    copyButton: {
        minWidth: 'unset !important',
        "& > .MuiButton-startIcon": {
            margin: 'unset !important',
        },
        '&:hover': {
            background: 'inherit',
            color: '#666666',
        },
    },
    copyIcon: {
        width: '20px !important',
        height: '20px !important',
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
}));

const WorkspaceSelect: React.FC<{
    collection: string,
    workspace?: Workspace,
    hidden?: boolean,
}> = ({ collection, workspace, hidden }) => {
    const classes = useStyles();
    const { getBulkWorkspaces, addToBulk, removeFromBulk } = useBulkWorkspaces();
    const [checked, setChecked] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const cleared = useMemo(() => !getBulkWorkspaces(collection).length, [getBulkWorkspaces, collection]);

    const selected = useMemo(() =>
        !!getBulkWorkspaces(collection).find(ws => ws.id === workspace?.id)
    , [getBulkWorkspaces, collection, workspace?.id]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            if (loaded && !!workspace) {
                if (checked && !selected)
                    addToBulk(collection, workspace);
                else if (!checked && selected)
                    removeFromBulk(collection, workspace);
            }

            clearTimeout(timerRef.current);
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [checked]);

    useEffect(() => {
        if (!loaded) {
            setChecked(selected);
            setLoaded(true);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cleared || !selected)
            setChecked(false);
    // eslint-disable-next-line
    }, [cleared]);

    if (hidden || !workspace)
        return (<></>);

    return (<>
        <Checkbox edge="end"
            className={classes.checkBox}
            checked={checked}
            onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                setChecked(prev => !prev);
            }}
            disableRipple
        />
    </>);
}

export default memo(WorkspaceSelect);