/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onFileAltered = /* GraphQL */ `subscription OnFileAltered($group: String) {
  onFileAltered(group: $group) {
    id
    documentCategory
    key
    displayPath
    status
    name
    group
    project
    dashboardId
    marketMetrics {
      page
      pageKey
      title
    }
    isDeck
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnFileAlteredSubscriptionVariables,
  APITypes.OnFileAlteredSubscription
>;
export const onFileDeleted = /* GraphQL */ `subscription OnFileDeleted($group: String) {
  onFileDeleted(group: $group) {
    id
    documentCategory
    key
    displayPath
    status
    name
    group
    project
    dashboardId
    marketMetrics {
      page
      pageKey
      title
    }
    isDeck
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnFileDeletedSubscriptionVariables,
  APITypes.OnFileDeletedSubscription
>;
export const onEmail = /* GraphQL */ `subscription OnEmail($group: String) {
  onEmail(group: $group) {
    id
    email
    project
    group
    isUploading
    createdAt
  }
}
` as GeneratedSubscription<
  APITypes.OnEmailSubscriptionVariables,
  APITypes.OnEmailSubscription
>;
export const onComment = /* GraphQL */ `subscription OnComment($group: String, $documentId: String) {
  onComment(group: $group, documentId: $documentId) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCommentSubscriptionVariables,
  APITypes.OnCommentSubscription
>;
export const onCommentDeleted = /* GraphQL */ `subscription OnCommentDeleted($group: String, $documentId: String) {
  onCommentDeleted(group: $group, documentId: $documentId) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCommentDeletedSubscriptionVariables,
  APITypes.OnCommentDeletedSubscription
>;
export const onCommentsAll = /* GraphQL */ `subscription OnCommentsAll($group: String) {
  onCommentsAll(group: $group) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCommentsAllSubscriptionVariables,
  APITypes.OnCommentsAllSubscription
>;
export const onCommentsAllDeleted = /* GraphQL */ `subscription OnCommentsAllDeleted($group: String) {
  onCommentsAllDeleted(group: $group) {
    id
    parentId
    email
    title
    text
    project
    group
    documentId
    dashboardId
    commentedItem {
      height
      left
      pageIndex
      top
      width
    }
    usersMentioned
    originType
    originUrl
    description
    createdAt
    updatedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnCommentsAllDeletedSubscriptionVariables,
  APITypes.OnCommentsAllDeletedSubscription
>;
export const onDashboard = /* GraphQL */ `subscription OnDashboard($group: String) {
  onDashboard(group: $group) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      from
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
    source
    userId
  }
}
` as GeneratedSubscription<
  APITypes.OnDashboardSubscriptionVariables,
  APITypes.OnDashboardSubscription
>;
export const onDashboardDeleted = /* GraphQL */ `subscription OnDashboardDeleted($group: String) {
  onDashboardDeleted(group: $group) {
    id
    email
    title
    project
    group
    documents {
      id
      title
      searchTerms
      searchTitle
      pages
      order
      key
      summary
      description
      documentScreenshot
      document
      comment {
        title
        text
      }
    }
    selections {
      id
      title
      document
      page
      order
      key
      summary
      selectedText
      comment {
        title
        text
      }
    }
    screenshots {
      id
      title
      key
      summary
      order
      comment {
        title
        text
      }
      page
      url
    }
    notes {
      id
      title
      data
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    emailBodies {
      id
      subject
      content
      from
      type
      createdAt
    }
    isPublic
    summary
    createdAt
    updatedAt
    lastUpdatedBy
    status
    investmentStage
    tags
    shouldRefresh
    externalLinks
    refreshData {
      lastUpdatedBy
      shouldRefresh
      modifiedData
    }
    shouldSyncExternally
    workspaces
    workspaceMemberships {
      workspaceId
      updatedAt
    }
    source
    userId
  }
}
` as GeneratedSubscription<
  APITypes.OnDashboardDeletedSubscriptionVariables,
  APITypes.OnDashboardDeletedSubscription
>;
export const onDocSendIngestionCreation = /* GraphQL */ `subscription OnDocSendIngestionCreation($group: String!) {
  onDocSendIngestionCreation(group: $group) {
    url
    email
    group
    newDashboardTitle
  }
}
` as GeneratedSubscription<
  APITypes.OnDocSendIngestionCreationSubscriptionVariables,
  APITypes.OnDocSendIngestionCreationSubscription
>;
export const onIntegrationUpdate = /* GraphQL */ `subscription OnIntegrationUpdate($group: String, $integration: String) {
  onIntegrationUpdate(group: $group, integration: $integration) {
    group
    integration
    externalConfig
    status
    lastSyncAt
    lastSuccessfulSyncAt
    createdAt
    updatedAt
    syncInProgress
    syncWaiting
  }
}
` as GeneratedSubscription<
  APITypes.OnIntegrationUpdateSubscriptionVariables,
  APITypes.OnIntegrationUpdateSubscription
>;
export const onDashboardQuery = /* GraphQL */ `subscription OnDashboardQuery($group: String, $dashboardId: String) {
  onDashboardQuery(group: $group, dashboardId: $dashboardId) {
    dashboardId
    title
    group
    query
    answer
    order
    columnOverride
    manualOverride
    isRefreshing
    source
    history
    status
    tags
    updatedAt
    deletedAt
  }
}
` as GeneratedSubscription<
  APITypes.OnDashboardQuerySubscriptionVariables,
  APITypes.OnDashboardQuerySubscription
>;
export const onUnassignedContent = /* GraphQL */ `subscription OnUnassignedContent($group: String) {
  onUnassignedContent(group: $group) {
    id
    group
    type
    key
    subject
    body
    attachments
    potentialCompanies
    from
    createdAt
  }
}
` as GeneratedSubscription<
  APITypes.OnUnassignedContentSubscriptionVariables,
  APITypes.OnUnassignedContentSubscription
>;
