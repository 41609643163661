import { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import { ReactComponent as CsvIcon } from "../../../assets/icons/csv.svg";
import BulkAddWorkspacesModal from "../../modals/home/BulkAddWorkspacesModal";

const useStyles = makeStyles((theme) => ({
    container: {
        width: 300,
        height: 200,
        border: `2px solid ${theme.colors.primary['200']}`,
        borderRadius: 16,
        padding: 16,
    },
    text: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'center',
        color: theme.colors.neutral['700'],
    },
    link: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.primary['700'],
        cursor: 'pointer',
    },
    mainIcon: {
        width: 60,
        height: 60,
    },
    icon: {
        width: 24,
        height: 24,
    },
    button: {
        minWidth: 150,
        borderRadius: 24,
        fontFamily: 'Lato',
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
}));

const InBulkCSV: React.FC<{}> = () => {
    const classes = useStyles();
    const [bulkAddModalOpen, setBulkAddModalOpen] = useState<boolean>(false);

    return (<>
        <Stack className={classes.container} spacing={2} alignItems="center" justifyContent="center">
            <CsvIcon className={classes.mainIcon} />
            <Typography className={classes.text}>
                {'Add investors in bulk via CSV'}
            </Typography>
            <Button variant="contained"
                className={classes.button}
                startIcon={<IosShareRoundedIcon className={classes.icon} />}
                onClick={() => setBulkAddModalOpen(true)}>
                {'Upload CSV'}
            </Button>
        </Stack>
        <BulkAddWorkspacesModal
            isOpen={bulkAddModalOpen}
            onClose={() => setBulkAddModalOpen(false)}
        />
    </>);
}

export default InBulkCSV;