import React, { ReactNode, useEffect, useState } from 'react';
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { scrollbarStyle } from '../../../shared/dashboard';
import SkeletonLoader from '../../atoms/SkeletonLoader';
import { DashboardQueryAnswer } from '../../../contexts/DashboardQueriesContext';
import AnswerHeader from '../dashboard-query-answer/AnswerHeader';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        paddingTop: 16,
        paddingBottom: 16,
        marginBottom: 16,
        borderRadius: 16,
        border: `2px solid ${theme.colors.primary['100']}`,
        background: theme.colors.primary['50'],
        transition: 'all 0.5s ease',
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 8,
        marginRight: 8,
        paddingLeft: 8,
        paddingRight: 8,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
}));

const MaxWaitLoadingTime = 15;

const EmptyQueryContainer: React.FC<{
    queryAnswer: DashboardQueryAnswer,
    children: ReactNode,
}> = ({ queryAnswer, children }) => {
    const classes = useStyles();
    const [showAnswer, setShowAnswer] = useState<boolean>(false);

    const { answeredQuestion } = queryAnswer;

    useEffect(() => {
        if ('answer' in answeredQuestion) {
            setShowAnswer((!!answeredQuestion?.answer || !!answeredQuestion?.type)
                || (!!answeredQuestion?.time && moment().diff(answeredQuestion.time, 'seconds') > MaxWaitLoadingTime));
        } else {
            setShowAnswer(true);
        }
    }, [answeredQuestion]);

    return (<>
        <Box className={classes.masonryElement}>
            <AnswerHeader answeredQuestion={answeredQuestion} hideAiGenerated hideCopy />
            <Box className={classes.answerContainer}>
                <Box className={classes.answerBlock}>
                    {showAnswer ? ( children ) : ( <SkeletonLoader variant="regular" rowCount={4} cropLast /> )}
                </Box>
            </Box>
        </Box>
    </>);
}

export default EmptyQueryContainer;
