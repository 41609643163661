import { Box, Button, Collapse, Divider, Stack, Typography, } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from "react";
import theme from "../../../theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from "moment";
import { EmailBody } from "../../../types/files";
import EmailSummaryAnswer from "../dashboard-query-answer/ContentSummaryAnswer";

const useStyles = makeStyles((theme) => ({
    card: {
        width: 450,
        minWidth: 450,
        height: 'fit-content',
        padding: '32px 24px 8px 24px',
        borderRadius: 32,
        backgroundColor: '#fff',
        border: `1px solid ${theme.colors.neutral['100']}`,
        boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        transition: '0.05s ease-in-out',
        "&:hover": {
            boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
        },
    },
    title: {
        display: '-webkit-box',
        width: 'fit-content',
        fontSize: '1.1rem',
        fontFamily: 'Poppins',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        maxWidth: '24ch',
    },
    bodyContentBlock: {
        width: '100%',
        height: 'fit-content',
        minHeight: '105px',
        maxHeight: '210px',
        overflowY: 'auto',
        marginTop: '16px',
        padding: '8px',
    },
    bodyText: {
        color: 'black',
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        whiteSpace: 'pre-wrap',
    },
    ownership: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    owner: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 600,
        color: theme.palette.primary.light,
    },
}));

const DashboardAssignedContentCard: React.FC<{
    content: EmailBody & {summary : string},
}> = ({ content }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    return (<>
        <Box className={classes.card}>
            {!!content?.from && (
                <Typography className={classes.ownership}>
                    {'From: '}
                    <Typography component="span" className={classes.owner} display="inline" pl={1}>
                        {content?.from}
                    </Typography>
                </Typography>
            )}
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h6-bold" className={classes.title}>
                    {content?.subject || '<Untitled>'}
                </Typography>
                <Typography variant="body1" fontSize="0.8rem" color={theme.colors.neutral['500']} fontFamily="Inter">
                    {`Added ${moment(content?.createdAt).fromNow()}`}
                </Typography>
            </Stack>
            <Divider sx={{ margin: '16px 0'}}>
                <Typography variant="body1" fontSize="0.8rem" color={theme.colors.neutral['500']} fontFamily="Inter">
                    {moment(content?.createdAt).format('MMMM D, YYYY')}
                </Typography>
            </Divider>
            <EmailSummaryAnswer content={content} />
            <Collapse in={isExpanded} collapsedSize={0}>
                <Stack className={classes.bodyContentBlock}>
                    <Typography className={classes.bodyText}>
                        {content?.content || ''}
                    </Typography>
                </Stack>
            </Collapse>
            <Stack direction="column" alignItems="center" justifyContent="center">
                <Button variant="text" sx={{ "&:hover":{ background: 'transparent' } }}
                    onClick={(e)=>{
                        e.preventDefault();
                        setIsExpanded((prev) => !prev);
                    }}>
                    <Typography variant="body1"
                        fontSize="0.8rem"
                        fontWeight="bold"
                        color={theme.colors.primary['500']}
                        fontFamily="Inter"
                        textTransform="capitalize"
                    >
                        {isExpanded ? 'Hide content' : 'Show content'}
                    </Typography>
                    <ExpandMoreIcon
                        sx={{
                            fill: theme.colors.primary['500'],
                            alignItems: 'center !important',
                            justifyContent: 'right',
                            transform: `rotate(${isExpanded ? 180 : 0}deg)`,
                        }}/>
                </Button>
            </Stack>
        </Box>
    </>);
};

export default DashboardAssignedContentCard;
