import { Button, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useContext, useMemo } from "react";
import WorkspacesSearchOrCreate from "../workspaces-search-create/WorkspacesSearchOrCreate";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import NoWorkspacesFoundView from "../workspaces-empty/NoWorkspacesFoundView";
import InvestorsTable from "../../organisms/investors/InvestorsTable";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import BulkShareDeals, { BulkShareCuratedTabs } from "../../atoms/home-bulk-share-deals";

const useStyles = makeStyles((theme) => ({
    mainContent: {
        width: '45vw',
        height: 'max-content',
        maxHeight: '60vh',
        padding: 'unset',
        overflow: 'hidden',
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '1.3rem !important',
        fontWeight: '700 !important',
        color: theme.palette.primary.main,
    },
    subcaption: {
        fontFamily: 'Inter',
        fontSize: '1rem !important',
        fontWeight: 400,
        color: theme.colors.neutral['600'],
    },
    commonButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    nextButton: {
        width: 'auto',
        minWidth: 100,
        borderRadius: 32,
        textTransform: 'none',
        fontSize: '0.95rem',
        fontWeight: 'bold',
    },
}));

export const collectionKey = 'bulk-share-investors';

const ShareInvestors: React.FC<{
    onSelect?: React.Dispatch<React.SetStateAction<BulkShareCuratedTabs>>,
}> = ({ onSelect }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const {getBulkWorkspaces} = useBulkWorkspaces();
    const [search, setSearch] = useState<string>('');

    const hasSelection = useMemo(() => !!getBulkWorkspaces(collectionKey).length, [getBulkWorkspaces]);

    return (<>
        <BulkShareDeals.Panel value={BulkShareCuratedTabs.Investors}
            content={(
                <Stack direction="column" className={classes.mainContent} alignItems="flex-start" justifyContent="stretch">
                    <Stack spacing={1} alignItems="flex-start">
                        <Typography className={classes.mainTitle}>
                            {'Select which investors you would like to share companies with:'}
                        </Typography>
                        <Typography className={classes.subcaption}>
                            {'You will be able to curate which company to share with which investor after.'}
                        </Typography>
                    </Stack>
                    <Stack alignItems="center" width="100%" px={3} pt={2}>
                        <WorkspacesSearchOrCreate search mediumFit onSearch={(value) => setSearch(value)} />
                    </Stack>
                    {!!workspaces.length ? (
                        <InvestorsTable collectionKey={collectionKey} search={search} forSharing />
                    ) : (<NoWorkspacesFoundView noResult />)}
                </Stack>
            )}
            actions={(
                <Stack direction="row" justifyContent="flex-end">
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Button
                            className={classes.commonButton}
                            onClick={() => onSelect?.(BulkShareCuratedTabs.Close)}>
                            {'Cancel'}
                        </Button>
                        <Button variant="contained"
                            className={classes.nextButton}
                            disabled={!hasSelection}
                            onClick={() => onSelect?.(BulkShareCuratedTabs.EachStepper)}>
                            {'Next'}
                            {hasSelection && (
                                <Typography component="span" fontWeight={300}>
                                    {': select companies'}
                                </Typography>
                            )}
                        </Button>
                    </Stack>
                </Stack>
            )} />
    </>);
}

export default ShareInvestors;