import { FC, useCallback, useState } from "react";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { Dashboard } from "../../../types/files";
import DashboardTagModal from "../../modals/dashboards-overview/DashboardTagModal";
import DashboardAddToInvestorModal from "../../modals/investors/DashboardAddToInvestorModal";
import useDashboards from "../../../hooks/useDashboards";
import theme from "../../../theme";
import CreateEditInvestorModal from "../../modals/investors/CreateEditInvestorModal";
import ConfirmDialog from "../../modals/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
    icon: {
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
    liveIcon: {
        padding: 'unset',
        color: theme.colors.neutral['600'],
        "& > svg": {
            width: 24,
            height: 24,
        },
    },
}));

const DashboardMenu: FC<{ dashboard: Dashboard, forLive?: boolean }> = ({ dashboard, forLive }) => {
    const classes = useStyles();
    const { deleteDashboard } = useDashboards();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
    const [deleteDashboardLoading, setDeleteDashboardLoading] = useState<boolean>(false);
    const [addTagsOpen, setAddTagsOpen] = useState<boolean>(false);
    const [addToWorkspaceOpen, setAddtoWorkspaceOpen] = useState<boolean>(false);
    const [createWorkspaceOpen, setCreateWorkspaceOpen] = useState<boolean>(false);

    const openDeleteDialog = useCallback(() => {
        setConfirmDeleteOpen(true);
        setAnchorEl(null);
    }, []);

    const closeDeleteDialog = useCallback(() => {
        setDeleteDashboardLoading(false);
        setConfirmDeleteOpen(false);
    }, []);

    const handleDelete = useCallback(() => {
        setDeleteDashboardLoading(true);
        deleteDashboard(dashboard.id).then(closeDeleteDialog);
    // eslint-disable-next-line
    }, [dashboard]);

    return (<>
        <Box onClick={(e) => e.stopPropagation()}>
            <IconButton className={forLive ? classes.liveIcon : classes.icon}
                onClick={(e) => setAnchorEl(e.currentTarget)} disableRipple>
                <MoreVertIcon />
            </IconButton>
            <Menu open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setAnchorEl(null)}>
                {!forLive && (<Box>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setAddTagsOpen(true);}}>
                        {'Add tags'}
                    </MenuItem>
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setAddtoWorkspaceOpen(true);}}>
                        {'Add to investor'}
                    </MenuItem>
                </Box>)}
                <MenuItem onClick={openDeleteDialog}
                    sx={{ color: theme.colors.error['500']}}>
                    {'Delete'}
                </MenuItem>
            </Menu>
            <ConfirmDialog
                title="Delete confirmation"
                content="Are you sure you want to delete this company?"
                open={confirmDeleteOpen}
                loading={deleteDashboardLoading}
                confirmCallback={handleDelete}
                cancelCallback={closeDeleteDialog}
            />
            <DashboardTagModal
                open={addTagsOpen}
                dashboard={dashboard}
                onClose={() => setAddTagsOpen(false)}
                forSelection
            />
            {addToWorkspaceOpen && (
                <DashboardAddToInvestorModal
                    isOpen={addToWorkspaceOpen}
                    dashboard={dashboard}
                    onClose={(createWorkspace?: boolean) => {
                        setAddtoWorkspaceOpen(false);
                        setCreateWorkspaceOpen(createWorkspace || false);
                    }}
            />)}
            <CreateEditInvestorModal
                isOpen={createWorkspaceOpen}
                onClose={() => {
                    setCreateWorkspaceOpen(false);
                    setAddtoWorkspaceOpen(true);
                }}
            />
        </Box>
    </>);
}

export default DashboardMenu;