import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Fab, } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UnassignedContentsModal from "../modals/dashboard-unassigned-contents/UnassignedContentsModal";
import { Dashboard, DocSendIngestion, EmailBody, UnassignedContent, UnassignedContentType } from "../../types/files";
import {AuthContext} from "../../contexts/AuthContext";
import { addEmailBodyToDashboardFunc, assignContentToDashboardFunc, createDashboardFunc, createDocSendIngestionFunc, deleteUnassignedContentFunc, getDashboards, getUnassignedContentItemsFunc } from "../../lib/helper";
import { API, graphqlOperation } from "aws-amplify";
import { onDocSendIngestionCreation, onUnassignedContent } from "../../graphql/subscriptions";
import { DashboardsContext } from "../../contexts/DashboardsContext";
import { getUserEmail } from "../../helpers/authUser";
import { User } from "../../types/auth";
import MailIcon from '@mui/icons-material/MailOutline';
import classNames from "classnames";
import ArrayUtils from "../../utils/ArrayUtils";
import { GroupSettingsContext } from "../../contexts/GroupSettingsContext";
import useEmailDealsExtraction, { EmailDeal } from "../../hooks/useEmailDealsExtraction";
import useFileUploader from "../../hooks/useFileUploader";
import useStaticDashboard from "../../hooks/useStaticDashboard";
import {v4 as uuidv4} from "uuid";
import { useDebouncedCallback } from "use-debounce";

export type UnassignedContentData = {
    content: UnassignedContent,
    deals: EmailDeal[],
    exists: boolean[],
    ingestables: boolean[],
    assignables: Dashboard[],
    createdAt: string,
};

const useStyles = makeStyles((theme) => ({
    badge: {
        '& .MuiBadge-badge': {
            position: 'absolute',
            top: 8,
            right: 8,
            width: 24,
            height: 24,
            padding: '12px 6px',
            borderRadius: '50%',
            background: theme.colors.orange['400'],
            color: 'white',
            fontSize: '0.85rem',
            fontWeight: 'bold',
            zIndex: 2000,
        },
    },
    incomingContentsButton: {
        height: 60,
        width: 60,
        boxShadow: 'none',
        textTransform: 'none',
        background: theme.colors.primary['500'],
        "&:hover": {
            background: theme.colors.primary['400'],
        }
    },
    incomingContentsOpen: {
        boxShadow: theme.shadows[5],
        background: theme.colors.primary['600'],
    },
    icon: {
        height: 32,
        width: 32,
        fill: 'white',
    },
}));

const UnassignedContentsBadge: React.FC<{}> = () => {
    const classes = useStyles();
    const {user, userGroup} = useContext(AuthContext);
    const { externalSync, autoIngestFromEmail } = useContext(GroupSettingsContext);
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const { extractEmailDeals } = useEmailDealsExtraction();
    const { saveWebQueryAnswer } = useStaticDashboard();
    const { docsendIngestion } = useFileUploader({});
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [incomingContents, setIncomingContents] = useState<UnassignedContent[]>([]);
    const [outgoingContents, setOutgoingContents] = useState<UnassignedContent[]>([]);
    const [contentsData, setContentsData] = useState<UnassignedContentData[]>([]);
    const [ingestableDeals, setIngestableDeals] = useState<{content: UnassignedContent, deals: EmailDeal[]}[]>([]);
    const [isIngesting, setIngesting] = useState<boolean>(false);
    const unassignedContentSubscriptionRef = useRef<any>(null);
    const onDocSendIngestionCreationRef = useRef<any>(null);
    const menuOpen = Boolean(anchorEl);

    const filteredContents = useMemo(() => incomingContents.filter(content => {
        if (content?.type === UnassignedContentType.DOCSEND) {
            const parsed = JSON.parse(content?.body || '{}') as DocSendIngestion;
            const lookUpDashboard = dashboards.find(dashboard => parsed?.existingDashboardId ?
                dashboard.id === parsed?.existingDashboardId : dashboard.title === parsed?.newDashboardTitle);

            return !!lookUpDashboard;
        }

        return true;
    }), [dashboards, incomingContents]);

    const activities = useMemo(() => {
        const filteredDashboards = dashboards.filter(dashboard => !!dashboard.emailBodies?.length);
        const activityItems: { dashboard: Dashboard, emailBody?: EmailBody, createdAt: string, type: 'attached' | 'created' }[] = [];

        filteredDashboards.forEach(dashboard => {
            activityItems.push({ dashboard, createdAt: String(dashboard.createdAt), type: 'created', });
            dashboard?.emailBodies?.forEach(emailBody => activityItems.push({
                dashboard, emailBody, createdAt: String(emailBody.createdAt), type: 'attached',
            }));
        });

        return activityItems.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    }, [dashboards]);

    const updateSubscribedContents = useCallback(async (
        incomingContents: UnassignedContent[],
        outgoingContents: UnassignedContent[],
        updatedContent?: UnassignedContent|null
    ) => {
        if (!updatedContent?.id)
            return;

        const lookUpIncomingContent = incomingContents.find(content => content.id === updatedContent.id);
        const lookUpOutgoingContent = outgoingContents.find(content => content.id === updatedContent.id);

        if (!lookUpOutgoingContent && !lookUpIncomingContent)
            setIncomingContents(prev => [updatedContent, ...prev]);
    }, []);

    const removedSubscribedContents = useCallback(async (updatedContent?: UnassignedContent|null) => {
        if (!updatedContent?.id)
            return;

        setIncomingContents(prev => [...prev].filter(content => content.id !== updatedContent!.id));
    }, []);

    const subscribeUnassignedContents = useCallback(async (
        incomingContents: UnassignedContent[],
        outgoingContents: UnassignedContent[]
    ) => {
        if (unassignedContentSubscriptionRef.current)
            unassignedContentSubscriptionRef.current.unsubscribe();

        if (userGroup) {
            unassignedContentSubscriptionRef.current = await (API.graphql(
                graphqlOperation(onUnassignedContent, { group: userGroup })) as any)
                .subscribe({ next: ({ value }: any) => updateSubscribedContents(
                    incomingContents, outgoingContents, value?.data?.onUnassignedContent
                )});
        }
    }, [userGroup, updateSubscribedContents]);

    const subscribeDocsendIngestionCreation = useCallback(async () => {
        if (onDocSendIngestionCreationRef.current)
            onDocSendIngestionCreationRef.current.unsubscribe();

        if (userGroup) {
            onDocSendIngestionCreationRef.current = await (API.graphql(
                graphqlOperation(onDocSendIngestionCreation, { group: userGroup })) as any)
                .subscribe({ next: ({ value }: any) => removedSubscribedContents(
                    value?.data?.onDocSendIngestionCreation
                )});
        }
    }, [userGroup, removedSubscribedContents]);

    const moveContent = useCallback((contentId: string) => {
        const lookUpContent = incomingContents.find(content => content.id === contentId);

        if (!!lookUpContent) {
            setOutgoingContents(prev => [lookUpContent, ...prev]);
            setIncomingContents(prev => [...prev]
                .filter(content => content.id !== lookUpContent.id));
        }
    }, [incomingContents]);

    const handleAssignContent = useCallback(async (dashboardId: string, unassignedContentId: string) => {
        moveContent(unassignedContentId);
        await assignContentToDashboardFunc({unassignedContentId, dashboardId});
    }, [moveContent]);

    const handleDocsendIngestion = useCallback(async (unassignedContentId: string, docsend: DocSendIngestion, password: string) => {
        moveContent(unassignedContentId);
        return await createDocSendIngestionFunc({ ...docsend,
            email: getUserEmail(user as User),
            group: userGroup,
            password,
        });
    }, [moveContent, user, userGroup]);

    const handleRemoveContent = useCallback(async (unassignedContentId: string) => {
        moveContent(unassignedContentId);
        await deleteUnassignedContentFunc({id: unassignedContentId, group: userGroup});
    }, [moveContent, userGroup]);

    const getContentsData = useCallback(() => {
        const contentsAcc: UnassignedContentData[] = [];

        for (const content of filteredContents) {
            const createdAt = String(content.createdAt || '');

            if (content.type === UnassignedContentType.FULL_EMAIL) {
                const deals = extractEmailDeals(content);
                const exists = deals.map(deal => !!dashboards.find(dashboard => dashboard.title.trim() === deal.name.trim()));
                const ingestables = deals.map(deal => !!deal.name && !!deal.website);
                const assignables = dashboards.filter(dashboard => !!(content?.subject?.toLowerCase()?.includes(dashboard.title.toLowerCase())));

                contentsAcc.push({ createdAt, content, deals, exists, ingestables, assignables: (!ingestables.length ? assignables : []), });
            } else if (content.type === UnassignedContentType.CALL) {
                const assignables = dashboards.filter(dashboard => !!(content?.subject?.toLowerCase()?.includes(dashboard.title.toLowerCase())));

                contentsAcc.push({ createdAt, content, deals: [], exists: [], ingestables: [], assignables, });
            } else {
                contentsAcc.push({ createdAt, content, deals: [], exists: [], ingestables: [], assignables: [], });
            }
        }

        return contentsAcc;
    // eslint-disable-next-line
    }, [dashboards, filteredContents]);

    const ingestEmailDeal = useCallback(async (deal: EmailDeal) => {
        const externalDeck = deal.externalDecks?.find(external => deal.deck?.name === external.name)?.name;
        const newDashboard = {
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
            email: getUserEmail(user!),
            group: userGroup!,
            project: userGroup!,
            id: uuidv4(),
            title: deal.name,
            documents: [],
            selections: [],
            screenshots: [],
            notes: [],
            lastUpdatedBy: '',
            shouldRefresh: false,
            externalLinks: externalDeck ? [externalDeck] : null,
            shouldSyncExternally: externalSync,
            refreshData: null,
            summary: '',
            status: '',
            investmentStage: deal.stage,
        } as Dashboard;

        if (!dashboards.find(dashboard => dashboard.title.trim() === deal.name.trim())) {
            return await new Promise<string|null>((resolve) => {
                createDashboardFunc(newDashboard).then(async () => {
                    Promise.all([
                        saveWebQueryAnswer(newDashboard, deal.website),
                        addEmailBodyToDashboardFunc({
                            id: newDashboard.id,
                            emailBody: {
                            id: uuidv4(),
                            subject: deal.name,
                            content: deal.email,
                            createdAt: (new Date()).toISOString(),
                            type: UnassignedContentType.EMAIL,
                            }
                        }),
                        docsendIngestion(newDashboard, externalDeck),
                    ]).then(() =>
                        resolve(`${newDashboard?.title} successfully created.`)
                    ).catch(() => resolve(null));
                }).catch(() => resolve(null));
            });
        }

        return null;
    }, [dashboards, externalSync, user, userGroup, docsendIngestion, saveWebQueryAnswer]);

    const handleSaveEmailDeals = useCallback(async (content: UnassignedContent, dashboard?: Dashboard, deals?: EmailDeal[]) => {
        if (!dashboard && !!deals?.length) {
            const promises: Promise<any>[] = [];

            deals.forEach(deal => promises.push(ingestEmailDeal(deal)));
            await Promise.all(promises);
            await handleRemoveContent(content.id!);
        } else {
            await handleAssignContent(dashboard?.id!, content.id!);
        }
    }, [handleAssignContent, handleRemoveContent, ingestEmailDeal]);

    const refreshDashboards = useCallback(async () => {
        getDashboards(userGroup).then((dashboardsData) =>
            setDashboards(ArrayUtils.sortByDescending(dashboardsData, 'createdAt')));
    // eslint-disable-next-line
    }, [userGroup]);

    const debouncedAutoIngestion = useDebouncedCallback(() => {
        setIngesting(true);

        const timeOut = setTimeout(async () => {
            const promises: Promise<any>[] = [];

            clearTimeout(timeOut);
            ingestableDeals.forEach(({content, deals}) => handleSaveEmailDeals(content, undefined, deals));
            Promise.all(promises).then(() => {
                refreshDashboards();
                setIngesting(false);
            });
        }, 3000);
    }, 3000, { maxWait: 5000 });

    useEffect(() => {
        if (userGroup) {
            getUnassignedContentItemsFunc({group: userGroup})
                .then((filteredContents: UnassignedContent[]|undefined) =>
                    setIncomingContents(filteredContents ?? [])
                );
        }
    }, [userGroup]);

    useEffect(() => {
        subscribeUnassignedContents(incomingContents, outgoingContents);
        subscribeDocsendIngestionCreation();

        return () => {
            unassignedContentSubscriptionRef.current?.unsubscribe();
            onDocSendIngestionCreationRef.current?.unsubscribe();
        }
        // eslint-disable-next-line
    }, [incomingContents, subscribeUnassignedContents]);

    useEffect(() => {
        if (!!filteredContents.length) {
            const contents = getContentsData();
            const ingestableDeals = contents.filter(({ingestables}) => ingestables.every(ingestable => ingestable))
                .flatMap(({content, deals, exists}) => ({content, deals: deals.filter((_, i) => !exists[i])}))
                .filter(ingestables => !!ingestables.deals.length);
            const assignableContents = contents.filter(({assignables}) => !!assignables.length)
                .flatMap(({content, assignables}) => ({content, dashboard: assignables[0]}));

            setContentsData(contents);
            setIngestableDeals(ingestableDeals);

            if (!!ingestableDeals.length && autoIngestFromEmail && !isIngesting)
                debouncedAutoIngestion();

            if (!!assignableContents.length)
                assignableContents.forEach(({dashboard, content}) => handleAssignContent(dashboard.id, content.id!));
        }
    // eslint-disable-next-line
    }, [getContentsData]);

    return (<>
        <Badge className={classes.badge} badgeContent={contentsData.length}>
            <Fab className={classNames(classes.incomingContentsButton,
                menuOpen && classes.incomingContentsOpen)}
                onClick={event => setAnchorEl(event.currentTarget)}>
                <MailIcon className={classes.icon} />
            </Fab>
        </Badge>
        {!!anchorEl && (
            <UnassignedContentsModal
                unassignedContents={contentsData}
                sortedActivities={activities}
                anchorEl={anchorEl}
                onAnchorEl={setAnchorEl}
                onSaveEmailDeals={handleSaveEmailDeals}
                onDocsendIngestion={handleDocsendIngestion}
                onRemoveContent={handleRemoveContent} />
        )}
    </>);
};

export default UnassignedContentsBadge;
