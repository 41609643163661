import { Stack, Typography } from "@mui/material";
import MetricsQuery from "../../molecules/dashboard-queries/MetricsQuery";
import AddSection from "../../atoms/AddSection";
import theme from "../../../theme";

export const Column = 'Latest Metrics';

const Section1 = 'Round Details';
const Section2 = 'Latest Metrics';

const KeyMetricsColumnQueries: React.FC<{}> = () => {
    return (<>
        <Stack spacing={2} alignItems="flex-start" width="20%">
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={1}>
                <Stack direction="column" alignItems="flex-start">
                    <Typography variant="b1-semibold" fontSize="0.9rem" color={theme.colors.neutral['400']}>
                        {Section1}
                    </Typography>
                    <Typography fontSize="0.8rem" color={theme.colors.neutral['400']}>
                        {'Double check metrics as they may be wrong.'}
                    </Typography>
                </Stack>
            </Stack>
            <MetricsQuery roundDetails />
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" pt={1}>
                <Stack direction="column" alignItems="flex-start">
                    <Typography variant="b1-semibold" fontSize="0.9rem" color={theme.colors.neutral['400']}>
                        {Section2}
                    </Typography>
                    <Typography fontSize="0.8rem" color={theme.colors.neutral['400']}>
                        {'Double check metrics as they may be wrong.'}
                    </Typography>
                </Stack>
                <AddSection column={Column} />
            </Stack>
            <MetricsQuery />
        </Stack>
    </>);
}

export default KeyMetricsColumnQueries;