import { Stack } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { DragDropContext } from "react-beautiful-dnd";
import configuredAsyncComponentLoader, { FallbackLoading } from "../../templates/loader";
import useDashboards from "../../../hooks/useDashboards";

const DashboardsGridView = configuredAsyncComponentLoader(() => import("./DashboardsGridView"));
const DashboardsListView = configuredAsyncComponentLoader(() => import("./DashboardsListView"));
const DashboardsSortView = configuredAsyncComponentLoader(() => import("./DashboardsSortView"));

export const collectionKey = 'dashboards';

const DashboardsView: React.FC<{
    layout: string,
    search: string,
    isPublic?: boolean,
 }> = ({ layout, search, isPublic }) => {
    const { dashboardsLoaded, dragAndDropDashboard } = useDashboards();

    return (<>
        {!dashboardsLoaded ? (
            <FallbackLoading height={`calc(100vh - 250px)`} />
        ) : (
            <Stack direction="row" alignItems="center" justifyContent="stretch" width="100%" height="100%">
                <DragDropContext onDragEnd={dragAndDropDashboard}>
                    <TabContext value={layout}>
                        <TabPanel value="sort" sx={{ width: '100%', height: '100%', p: 'unset '}}>
                            <DashboardsSortView search={search} isPublic={isPublic} />
                        </TabPanel>
                        <TabPanel value="grid" sx={{ width: '100%', height: '100%', p: 'unset '}}>
                            <DashboardsGridView search={search} isPublic={isPublic} />
                        </TabPanel>
                        <TabPanel value="list" sx={{ width: '100%', height: '100%', p: 'unset '}}>
                            <DashboardsListView search={search} isPublic={isPublic} />
                        </TabPanel>
                    </TabContext>
                </DragDropContext>
            </Stack>
        )}
    </>);
}

export default DashboardsView;