import React, { memo, useCallback, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AttachmentType, EmailDeal } from "../../../hooks/useEmailDealsExtraction";
import EmailDealSelect from "../../atoms/EmailDealSelect";
import UnassignedEmailDeal from "./UnassignedEmailDeal";
import { LoadingButton } from "@mui/lab";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    padding: 12,
    transition: '200ms ease-in-out',
  },
  chip: {
    height: 30,
    width: 120,
    fontFamily: 'Inter',
    fontSize: '0.8rem',
    fontWeight: 500,
    backgroundColor: theme.colors.orange['50'],
    color: theme.colors.orange['500'],
  },
  icon: {
    width: 24,
    height: 24,
    fill: `${theme.colors.cyan['600']} !important`,
  },
  ingestedIcon: {
    width: 24,
    height: 24,
    color: `${theme.palette.primary.light} !important`,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    maxWidth: '12ch',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    wordBreak: 'break-all',
    fontSize: '1rem',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    color: `${theme.palette.common.black} !important`,
    textOverflow: 'ellipsis',
    textTransform: 'none !important' as any,
    textAlign: 'left',
  },
  subtitle: {
    marginLeft: 32,
    marginBottom: 4,
    fontFamily: 'Inter !important',
    fontSize: '0.9rem !important',
    fontWeight: '500 !important',
    color: `${theme.palette.primary.light} !important`,
  },
  label: {
    fontFamily: 'Inter !important',
    fontSize: '0.9rem !important',
    fontWeight: 'bold !important',
    color: `${theme.colors.neutral['600']} !important`,
  },
  inputField: {
    minWidth: 'fit-content',
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 36,
      padding: '0 8px !important',
      borderRadius: 8,
      background: 'transparent',
      "& > input": {
        padding: 'unset !important',
        fontFamily: 'Inter !important',
        fontSize: '0.9rem',
        fontWeight: 600,
        color: theme.colors.neutral['600'],
        "&::placeholder": {
          fontFamily: 'Lato !important',
          fontWeight: 'normal !important',
        },
      },
      "& fieldset": {
        borderColor: 'darkgray !important',
      },
      "&:hover fieldset": {
        borderColor: 'black !important',
      },
      "&.Mui-focused fieldset": {
        borderWidth: '1px !important',
        borderColor: 'darkgreen !important',
      },
    },
  },
  emailField: {
    minWidth: 'fit-content',
    width: "100%",
    "& .MuiInputBase-root": {
      height: 'auto',
      padding: '8px !important',
      borderRadius: 8,
      background: 'transparent',
      "& > textarea": {
        padding: 'unset !important',
        fontFamily: 'Inter !important',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
        "&::placeholder": {
          fontFamily: 'Lato !important',
          fontWeight: 'normal !important',
        },
      },
      "& fieldset": {
        borderColor: 'darkgray !important',
      },
      "&:hover fieldset": {
        borderColor: 'black !important',
      },
      "&.Mui-focused fieldset": {
        borderWidth: '1px !important',
        borderColor: 'darkgreen !important',
      },
    },
  },
  expandIcon: {
    fill: '#666666 !important',
    alignItems: 'center !important',
    justifyContent: 'right',
    cursor: 'pointer',
  },
  cancelButton: {
    width: 100,
    height: 32,
    borderRadius: 24,
    borderColor: theme.colors.neutral['100'],
    background: theme.colors.neutral['100'],
    color: theme.colors.neutral['500'],
    fontFamily: 'Inter',
    fontSize: '0.9rem',
    fontWeight: 500,
    textTransform: 'none',
    transition: 'ease-in-out 300ms',
  },
  saveButton: {
    width: 'auto',
    height: 32,
    borderRadius: 24,
    backgroundColor: theme.colors.primary['600'],
    color: 'white',
    fontFamily: 'Inter',
    fontSize: '0.9rem',
    fontWeight: 500,
    textTransform: 'none',
    transition: 'ease-in-out 300ms',
    '&:hover': {
      backgroundColor: theme.colors.primary['500'],
    }
  },
}));

const UnassignedEmailDeals: React.FC<{
  emailDeals: EmailDeal[],
  exists: boolean[],
  onSave?: (emailDeals: EmailDeal[]) => Promise<any>,
  onDiscard?: () => void,
}> = ({ emailDeals, exists, onSave, onDiscard }) => {
  const classes = useStyles();
  const [selectedDeals, setSelectedDeals] = useState<EmailDeal[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [localEmailDeals, setLocalEmailDeals] = useState<EmailDeal[]|null>(null);

  const getAttachments = (deal: EmailDeal) => {
    let attachments: AttachmentType[] = [];

    if (Array.isArray(deal.externalDecks))
      attachments.push(...deal.externalDecks);
    else if (!!deal.deck)
      attachments.push(deal.deck);

    return attachments;
  };

  const handleSave = useCallback(async () => {
    setLoading(true);
    onSave?.(selectedDeals).then(() => setLoading(false));
  }, [onSave, selectedDeals]);

  const handleUpdate = useCallback((deal: EmailDeal) => {
    setLocalEmailDeals(prev => prev?.map(d => d.id === deal.id ? deal : d) ?? []);
    setSelectedDeals(prev => prev?.map(d => d.id === deal.id ? deal : d) ?? []);
  }, []);

  useEffect(() => {
    setLocalEmailDeals([...emailDeals].filter((_, i) => !exists[i]));
  }, [emailDeals, exists]);

  return (<>
    <Stack direction="column" alignItems="stretch" width="100%" height="100%" marginTop="-4px !important">
      {!!localEmailDeals?.length && (<>
        <Typography fontFamily="Inter" fontSize="0.85rem" color={theme.colors.neutral['600']} pl={4}>
          {`${localEmailDeals?.length} compan${localEmailDeals?.length > 1 ? 'ies' : 'y'} found in this email:`}
        </Typography>
        {localEmailDeals.map(deal => (
          <Stack direction="row" alignItems="flex-start" justifyContent="stretch" width="100%" pl={4}
            key={'unassigned-email-deals-178-' + deal.id}>
            <Box mt="14px">
              <EmailDealSelect
                emailDeal={deal}
                selectedDeals={selectedDeals}
                setSelectedDeals={setSelectedDeals} />
            </Box>
            <UnassignedEmailDeal
              emailDeal={deal}
              attachments={getAttachments(deal)}
              onChange={handleUpdate}
            />
          </Stack>
        ))}
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" pt={1} pb={1}>
          <Button variant="outlined" size="small"
            className={classes.cancelButton}
            onClick={onDiscard}
          > {'Discard'} </Button>
          <LoadingButton variant="contained" size="small"
            className={classes.saveButton}
            disabled={!selectedDeals.length}
            loading={loading}
            onClick={handleSave}
          > {`Add ${!!selectedDeals.length ? selectedDeals.length : ''} compan${selectedDeals.length > 1 ? 'ies': 'y'}`} </LoadingButton>
        </Stack>
      </>)}
    </Stack>
  </>);
}

export default memo(UnassignedEmailDeals);
