import React, { useContext } from "react";
import { Box, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { scrollbarStyle } from '../../../shared/dashboard';
import DashboardQueries from "../dashboard-queries/DashboardQueries";
// import DashboardTimeline from "../../organisms/dashboard-timeline/DashboardTimeline";
import DashboardItems from "../../organisms/dashboard-details/DashboardItems";
import { DashboardContext } from "../../../contexts/DashboardContext";
import DashboardMetricsTimeline from "../dashboard-timeline/DashboardMetricsTimeline";
import DashboardDeck from "../../molecules/dashboard-deck/DashboardDeck";
import DashboardMarketSize from "../dashboard-market-size/DashboardMarketSize";

const useStyles = makeStyles((theme) => ({
    panel: {
        transition: 'width .3s ease',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        width: '100%',
        ...scrollbarStyle
    },
}));

const DashboardOverview: React.FC<{}> = () => {
    const classes = useStyles();
    const { dashboard, isPublicView } = useContext(DashboardContext);

    if (!dashboard)
        return (<></>);

    return (<>
        <Box className={classes.panel}>
            <Box padding="12px 0" />
            <DashboardQueries />
            <Divider sx={{ mb: "16px !important"}}/>
            <DashboardMetricsTimeline />
            {/* <DashboardTimeline /> */}
            <Divider sx={{ mb: "16px !important"}}/>
            <DashboardMarketSize />
            <Divider sx={{ mb: "16px !important"}}/>
            {!isPublicView && (<DashboardItems />)}
            <Box display="none">
                <DashboardDeck />
            </Box>
        </Box>
    </>);
}

export default DashboardOverview;