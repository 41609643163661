import { useContext, useEffect, useMemo, useState } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import InvestorsTable from "../../organisms/investors/InvestorsTable";
import NoWorkspacesFoundView from "../../molecules/workspaces-empty/NoWorkspacesFoundView";
import {GroupSettingsContext} from "../../../contexts/GroupSettingsContext";
import useBulkWorkspaces from "../../../hooks/useBulkWorkspaces";
import WorkspacesBulkShareEmail from "../../atoms/workspaces/WorkspacesBulkShareEmail";
import { collectionKey } from "../dashboard-details/ShareDealModal";
import CreateEditInvestorModal from "../investors/CreateEditInvestorModal";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 1200,
            maxHeight: 600,
            width: 'min-content',
            height: 'fit-content',
            padding: 16,
            borderRadius: 24,
            overflow: 'hidden',
        },
    },
    dialogTitle: {
        width: '100%',
        padding: 'unset',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        color: 'black',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    selected: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'black',
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    cancelButton: {
        width: 100,
        height: 40,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
    },
    saveIcon: {
        width: 24,
        height: 24,
        color: theme.palette.primary.main,
    },
}));

const ShareDealsModal: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {
    const classes = useStyles();
    const { workspaces } = useContext(GroupSettingsContext);
    const { getBulkWorkspaces, clearBulk } = useBulkWorkspaces();
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState<boolean>(false);

    const bulkCount = useMemo(() => getBulkWorkspaces(collectionKey).length, [getBulkWorkspaces]);

    useEffect(() => {
        clearBulk(collectionKey);

        return () => clearBulk(collectionKey);
    // eslint-disable-next-line
    }, []);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <span>{'Share deals'}</span>
                    <Button variant="outlined"
                        className={classes.createButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setCreateWorkspaceModalOpen(true);
                        }}
                    >{'+  New investor'} </Button>
                </Stack>
            </DialogTitle>
            {!!workspaces.length && (<Divider sx={{ margin: '8px 0'}} />)}
            <DialogContent className={classes.dialogContent}>
                {!!workspaces.length ? (
                    <InvestorsTable collectionKey={collectionKey} forSharing />
                ) : (<NoWorkspacesFoundView noResult />)}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row"spacing={2} alignItems="center">
                    {!!bulkCount && (
                        <Typography className={classes.selected}>
                            {`${bulkCount} investor${bulkCount > 1 ? 's' : ''} selected`}
                        </Typography>
                    )}
                    <Button className={classes.cancelButton}
                        onClick={onClose}> {'Cancel'} </Button>
                    <WorkspacesBulkShareEmail />
                </Stack>
            </DialogActions>
        </Dialog>
        {createWorkspaceModalOpen && (
            <CreateEditInvestorModal
                isOpen={createWorkspaceModalOpen}
                onClose={() => {
                    setCreateWorkspaceModalOpen(false);
                }}
            />
        )}
    </>);
}

export default ShareDealsModal;