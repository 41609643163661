import { SaveDashboardItemType } from "./common";

export interface FolderTab {
  name: string;
  value: number;
}

interface FileBase {
  id: string;
  name: string;
  key: string;
}

export interface S3File extends FileBase {
  displayPath: string;
  status?: string;
  createdAt?: Date;
}

export interface CompanyFile extends FileBase {
  [x: string]: any;
  documentCategory: string;
  isDirectory: boolean;
  parentId: string;
  path: string;
  rootFolderName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  dashboardId?: string;
  marketMetrics?: MarketMetric[];
  isDeck?: boolean | null,
}

export interface MarketMetric {
  page: number;
  pageKey: string;
  title: string;
}

export interface SearchResultsInPage {
  indexInPage: number;
  sentence: string;
  text: string;
}

export interface SearchResultsPerPage {
  page: number;
  sortMetric: number;
  searchResults: SearchResultsInPage[];
}

export interface CreateSearchResponse {
  value: {
    data: {
      [subscriptionName: string]: {
        key: string;
        searchResults: SearchResultsPerPage[];
      };
    };
  };
}

export interface ActivityData {
  createdAt: Date;
  description: string;
  documentId: number | string;
  id: string;
  pageNumber: number;
  title: string | number;
  updatedAt?: Date;
  userId: string | number;
}

export enum RefreshDataEnum {
  Title = "TITLE",
  Notes = "NOTES",
  Items = "ITEMS",
  Comments = "COMMENTS",
  Summary = "SUMMARY",
  Public = "PUBLIC",
}

export enum DashboardNoteType {
  External = 1,
  Internal = 2
}

export interface DashboardDocument {
  id: string;
  title: string;
  key: string;
  description: string;
  documentScreenshot: string | number;
  document: string;
  searchTerms: string[];
  searchTitle: string[];
  order?: number | null | undefined;
  pages: number[];
  summary: string;
}

export interface DashboardSelection {
  id: string;
  document: string;
  page: number;
  key: string;
  order?: number | null | undefined;
  selectedText: string;
  title: string;
  summary: string;
}

export interface DashboardScreenshot {
  id: string;
  order?: number | null | undefined;
  key: string;
  title: string;
  summary?: string;
  page?: number;
  url?: string;
}

export interface DashboardNote {
  id: string;
  title: string;
  data: string;
  type: DashboardNoteType;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface DashboardRefreshData {
  lastUpdatedBy?: string | null;
  shouldRefresh?: boolean | null;
  modifiedData?: string | null;
}

export interface EmailBody {
  id?: string | null;
  subject?: string | null;
  content?: string | null;
  createdAt?: string | null;
  type?: string | null;
  from?: string | null;
};

export type WorkspaceMembership = {
  workspaceId: string,
  updatedAt: string,
};

export interface Dashboard {
  id: string;
  documents: DashboardDocument[] | null;
  selections: DashboardSelection[] | null;
  screenshots: DashboardScreenshot[] | null;
  notes: DashboardNote[] | null;
  email: string;
  emailBodies?: EmailBody[];
  group: string;
  project: string;
  title: string;
  isPublic?: boolean | undefined;
  createdAt: string;
  updatedAt: string;
  lastUpdatedBy: string | null;
  tags?: string[];
  shouldRefresh?: boolean | null;
  externalLinks?: string[] | null;
  shouldSyncExternally?: boolean | null;
  refreshData: DashboardRefreshData | null;
  summary: string | null;
  status: string;
  investmentStage?: string | null;
  workspaces?: string[];
  workspaceMemberships?: WorkspaceMembership[] | null;
  userId?: string | null;
  source?: string | null;
}

export interface DashboardDeckCreation {
  url: string;
  email: string;
  group: string;
  newDashboardTitle: string;
}

export interface User {
  LastName: string;
  firstName: string;
  email: string;
}

export interface MentionsData {
  createdAt: Date;
  description: string;
  documentId: number | string;
  id: string;
  title: string | number;
  updatedAt?: Date;
  user: User;
}

export interface DashboardUid {
  id: string;
  dashboardItemId: string;
  dashboardItemType?: SaveDashboardItemType;
  documentKey?: string;
}

export interface SearchCategorization {
  id: string;
  email: string;
  group: string;
  project: string;
  title: string;
  searchTerms: string[][];
  searchTermsPhrase: string[];
  sortOrder: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface UploadAction {
  id: string;
  createdAt: Date;
  group: string;
  project: string;
  updatedAt: Date;
  uploadName: string;
}

export interface DocSendIngestion {
  url?: string;
  email: string;
  group: string;
  newDashboardTitle?: string;
  existingDashboardId?: string;
  password?: string;
  docsendIngestionId?: string;
  tags?: string[];
}

export enum DashboardQuerySource {
  OPENAI = "OPENAI",
  OPENAI_CREATIVE = "OPENAI_CREATIVE",
  CRUNCHBASE = "CRUNCHBASE",
  GOOGLE = "GOOGLE",
  LINKEDIN = "LINKEDIN",
  MANUAL = "MANUAL",
  CUSTOM = "CUSTOM",
  PERPLEXITY = "PERPLEXITY",
}

export interface DashboardQuery {
  dashboardId: string;
  title: string;
  group: string;
  query: string;
  answer: string | null;
  history?: string;
  updatedAt: Date;
  columnOverride?: number;
  order: number;
  manualOverride?: boolean;
  source: DashboardQuerySource;
  status: string;
  tags?: string[];
  isRefreshing: boolean;
  deletedAt?: Date;
}

export interface DashboardQueriesType {
  dashboard: Dashboard;
  queries: DashboardQuery[];
}

export interface ExtendedDashboardQuery extends DashboardQuery {
  isSelected: boolean;
  isDisabled: boolean;
  default: boolean;
  source: DashboardQuerySource;
}

export interface SimilarCompany {
  displayName: string;
  website: string;
  description: string | null;
  market: string;
  investors: Array< string | null > | null;
  product: string;
  linkedinUrl: string | null;
  location: string | null;
  headcount: string | null;
  founded: string | null;
  funding: string | null;
  updatedAt: Date;
}

export interface CrunchbaseCompany {
  permalink: string;
  name: string;
  description?: string;
}

export interface CrunchbaseEntity {
  name?: string;
  website?: string;
  linkedin?: string;
  shortDescription?: string;
  location?: string;
}

export interface ActivityFeedEvent {
  id: string;
  dashboardId: string;
  group: string;
  eventType: ActivityFeedEventType;
  events: string;
  eventAt: Date;
  createdAt: Date;
}

export enum ActivityFeedEventType {
  DASHBOARD_CREATED = "DASHBOARD_CREATED",
  NEW_NEWS_ARTICLES = "NEW_NEWS_ARTICLES",
}

export enum FeedEventType {
  DASHBOARD_CREATED = "DASHBOARD_CREATED",
  WORKSPACE_CREATED = "WORKSPACE_CREATED",
}

export interface LiveEvent {
  eventType: ActivityFeedEventType | FeedEventType;
  dashboardEvent?: ActivityFeedEvent & { dashboard?: Dashboard };
  workspaceEvent?: { workspace?: Workspace };
  eventAt: Date;
}

export enum UnassignedContentType {
  CALL = "CALL",
  DOCSEND = "DOCSEND",
  EMAIL = "EMAIL",
  FULL_EMAIL = "FULL_EMAIL",
  OTHER = "OTHER",
}

export interface UnassignedContent {
  id?: string;
  group?: string;
  type?: string;
  key?: string;
  subject?: string;
  body?: string;
  attachments?: string[];
  potentialCompanies?: string[]|null;
  createdAt?: string;
  from?: string|null;
}

export type FocusAreaInvestmentThesis = {
  tags?: Array<string> | null,
};

export type StageInvestmentThesis = {
  stages?: Array<string> | null,
};

export type GeographyInvestmentThesis = {
  regions?: Array<string> | null,
};

export type FreeText = {
  freeText?: string | null,
};

export type InvestmentThesis = {
  focusArea?: FocusAreaInvestmentThesis | null,
  stage?: StageInvestmentThesis | null,
  geography?: GeographyInvestmentThesis | null,
  additionalDetails?: FreeText | null,
};

export interface Workspace {
  id: string;
  name: string;
  email: string;
  website: string;
  isPublic: boolean;
  investmentThesis: InvestmentThesis;
  createdAt?: Date;
  updatedAt?: Date;
};

export interface RefreshWorkspace {
  dashboardId: string;
  workspaceId: string;
  group: string;
  query: string;
};
