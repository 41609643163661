import { useCallback, useContext } from "react";
import { NoInfoYet, NoteMetric } from "../types/search";
import { Dashboard } from "../types/files";
import {DashboardsContext,  DisplayQueryTitles } from "../contexts/DashboardsContext";
import moment from "moment";
import useBulkDashboards from "./useBulkDashboards";

const edjsParser = require("editorjs-parser").default;
const parser = new edjsParser();

export type ContentCopyType = {
  type: string;
  data: {
    text?: string; // type: paragraph | heading
    content?: Array<Array<string>>; // type: table // row[column[]]
    withHeadings?: boolean; // type: table
    items?: Array<string>; // type: list
    style?: string; // ordered; unordered
    code?: string; // type: code
    level?: number; // type: header
    html?: string; // type: raw
  };
};

const BlockLevelElements = ['P','H1', 'H2', 'H3', 'H4', 'H5', 'H6','OL', 'UL','DIV','FORM','HR','TABLE'];
const NoMarginFormat = `margin: 0 !important;`;
const DefaultFormat = `font-family: Arial; font-size: 12px;`;
const TextFormat = `color: #000; font-weight: normal;`;
const LinkFormat = `text-decoration: underline;`;

export const COPIABLE_PREFIX = 'copiable-';

const useContentCopy = () => {
  const { mappedOverviewQueries } = useContext(DashboardsContext);
  const { getBulkDashboards } = useBulkDashboards();

  const getCopiableId = useCallback((key: string) => (
    String(COPIABLE_PREFIX + key)
      .trim().toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
  ), []);

  const formatHeaderData = useCallback((value: string) => (
    { type: 'header', data: {level: 4,  text: `<u>${value}</u>` } }
  ), []);

  const formatEmptyData = useCallback(() => (
    { type: 'paragraph', data: { text: '&nbsp;' } }
  ), []);

  const formatFooterData = useCallback(() => (
    { type: 'paragraph', data: { text: `Generated from <a href="https://www.notissia.com/" target="_blank" rel="noopener noreferrer">Notissia</a>.` } }
  ), []);

  const formatFieldData = useCallback((displayTitle: string, value: string) => (
    { type: 'paragraph', data: { text: `${(!!displayTitle ? "<b>" + displayTitle + ":</b>&nbsp;" : "") + "<span>" + value + "</span>"}` } }
  ), []);

  const formatRawData = useCallback((rawHTML: string) => (
    { type: 'raw', data: { html: rawHTML } }
  ), []);

  // TODO: combine all copy formatting for queries
  const formatQueryContents = useCallback(async (dashboard: Dashboard, queryTitles: string[]): Promise<ContentCopyType[]> =>
    await Promise.resolve(DisplayQueryTitles
      .filter(displayTitle => queryTitles.includes(displayTitle))
      .reduce((content: ContentCopyType[], displayTitle: string) => {
        const queryTitle = (displayTitle === 'Metrics') ? 'Note Metrics': (displayTitle === 'Description') ? 'Digest' : displayTitle;
        const answer = mappedOverviewQueries.get(`${dashboard.id}:${queryTitle}`);

        if (!!answer) {
          switch (displayTitle) {
            case 'Location': (() => {
              if (answer.trim().toLowerCase().includes('no information'))
                content.push(formatFieldData(displayTitle, answer));
              else
                content.push(formatFieldData(displayTitle, `<a href="https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(answer)}" target="_blank" rel="noopener noreferrer">${answer}</a>`));
            })(); break;
            case 'LinkedIn':
            case 'Website': (() => {
              content.push(formatFieldData(displayTitle, `<a href="${answer}" target="_blank" rel="noopener noreferrer">${answer?.replace(/^https?:\/\//, '')}</a>`));
            })(); break;
            case 'Metrics': (() => {
              const metrics = answer as NoteMetric;
              let metricsAnswer = '';
              let metricsList: string[] = [];

              if (Object.values(metrics).every(metric => metric?.value === null)) {
                metricsAnswer = 'No metrics data available.';
              } else {
                Object.entries(metrics).forEach(([metricName, metricValues]) => {
                  if (metricValues?.value !== null) {
                    const date = !!metricValues?.date ? moment(metricValues?.date).format('MMM YYYY') : '';
                    const cleanInput = String(metricValues?.value!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
                    let multiplier = 1;
                    let displayValue = '0';

                    if (cleanInput.includes('t'))
                      multiplier = Math.pow(10, 12);
                    else if (cleanInput.includes('b'))
                      multiplier = Math.pow(10, 9);
                    else if (cleanInput.includes('m'))
                      multiplier = Math.pow(10, 6);
                    else if (cleanInput.includes('k'))
                      multiplier = 1000;

                    const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

                    if (metricValues?.units === '%')
                      displayValue = `${numericValue.toFixed(1)}%`;
                    else if (metricValues?.units === '$')
                      displayValue = `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    else
                      displayValue = `${numericValue}`;

                    metricsList.push(`<i>${metricName}:</i>&nbsp;<span>${displayValue}${!!date ? ` (${date})` : ''}</span>`);
                  }
                });
              }

              content.push(formatFieldData(displayTitle, metricsAnswer));
              metricsList.forEach((metric) => content.push({type: 'paragraph', data: { text: metric }}));
            })(); break;
            case 'Team': (() => {
              const keyPeople = mappedOverviewQueries.get(`${dashboard.id}:Key People`);
              let peopleList: Array<string> = [];

              if (Array.isArray(keyPeople)) {
                keyPeople.forEach((element: any) =>
                  peopleList.push(`<a href="${element?.link}" target="_blank" rel="noopener noreferrer"><b>${element?.title?.split(' |')?.[0]}</b></a>`)
                );
              }

              content.push(formatFieldData(displayTitle, answer));
              peopleList.forEach((people) => content.push({ type: 'paragraph', data: {text: `<i>LinkedIn:</i>&nbsp;<span>${people}</span>`} }));
            })(); break;
            case 'Description': (() => {
              content.push(formatFieldData(displayTitle, answer?.[0] || NoInfoYet.answer));
            })(); break;
            default: (() => {
              content.push(formatFieldData(displayTitle, answer || NoInfoYet.answer));
            })(); break;
          }

          content.push(formatEmptyData());
        } else {
          content.push(formatFieldData(displayTitle, "<i>Loading...</i>"));
        }

        return content;
      }, []) as ContentCopyType[])
  , [formatEmptyData, formatFieldData, mappedOverviewQueries]);

  const copyFormattedContents = useCallback((contents: ContentCopyType[]) => {
    if (!!contents.length) {
      const parsed = new DOMParser().parseFromString(parser.parse({blocks: [...contents]}), 'text/html');

      parsed.querySelectorAll("a")?.forEach((link) =>
        link?.setAttribute("style", DefaultFormat + TextFormat + LinkFormat + NoMarginFormat)
      );
      parsed.querySelectorAll("div, p, span")?.forEach((link) =>
        link?.setAttribute("style", DefaultFormat + TextFormat + NoMarginFormat)
      );
      parsed.querySelectorAll("svg, hr")?.forEach((link) =>
        link?.setAttribute("style", "display: none;")
      );
      parsed.querySelectorAll(`#${getCopiableId('exclude')}`)?.forEach((link) =>
        link?.setAttribute("style", "display: none;")
      );
      parsed.querySelectorAll(`#${getCopiableId('extend')}`)?.forEach((link) =>
        link?.setAttribute("style", DefaultFormat + TextFormat)
      );

      const copiedElement: HTMLDivElement = document.createElement("div");

      copiedElement.innerHTML = new XMLSerializer().serializeToString(parsed);
      (copiedElement.style as any).element = 0;

      Array.from(copiedElement.getElementsByTagName("*") as HTMLCollectionOf<HTMLElement>).forEach((element) => {
        const display = window.getComputedStyle(element).getPropertyValue("display");

        if (display.includes('inline') && BlockLevelElements.includes(element.tagName)) {
          const defaultCSS = (document.defaultView as any).getComputedStyle(element, '').cssText;

          element.outerHTML = element.outerHTML.replace(new RegExp(element.tagName, 'ig'), 'span');
          element.style.cssText = defaultCSS;
        }
      });

      copiedElement.tabIndex = -1;
      copiedElement.focus();
      navigator.clipboard.write([new ClipboardItem({
        'text/plain': new Blob([copiedElement.innerText], { type: 'text/plain' }),
        'text/html': new Blob([copiedElement.outerHTML], { type: 'text/html' }),
      })]);
    }
  }, [getCopiableId]);

  const copyDashboard = useCallback(async (dashboard: Dashboard, queryTitles: string[]): Promise<boolean> => {
    const contents = await formatQueryContents(dashboard, queryTitles);

    if (Array.isArray(contents)) {
      copyFormattedContents([
        formatHeaderData(dashboard.title),
        ...contents,
        formatEmptyData(),
        formatFooterData(),
      ]);

      return true;
    }

    return false;
  }, [copyFormattedContents, formatEmptyData, formatFooterData, formatHeaderData, formatQueryContents]);

  const copyBulkDashboards = useCallback(async (collection: string, queryTitles: string[]): Promise<boolean> =>
    await new Promise<boolean>((resolve) => {
      const promises: Promise<ContentCopyType[]>[] = [];
      const dashboardTitles: string[] = [];
      const bulkContents: ContentCopyType[] = [];
      const bulkDashboards = getBulkDashboards(collection);

      bulkDashboards.forEach(exportable => {
        promises.push(formatQueryContents(exportable.dashboard, queryTitles))
        dashboardTitles.push(exportable.dashboard.title);
      }, []);

      Promise.all(promises).then((formattedContentsData) => {
        formattedContentsData.forEach((contents, i) => {
          bulkContents.push(
            formatHeaderData(dashboardTitles[i]),
            ...contents,
          );
        });
        bulkContents.push(formatEmptyData());
        bulkContents.push(formatFooterData());
        copyFormattedContents([...bulkContents]);

        resolve(true);
      });

      resolve(false);
  }), [copyFormattedContents, formatEmptyData, formatFooterData, formatHeaderData, formatQueryContents, getBulkDashboards]);

  const copyQueryAnswer = useCallback((question: string) => {
    const copiableHtml = document.querySelector(`#${getCopiableId(question)}`)?.innerHTML || '';
    const keyPeopleHtml = document.querySelector(`#${getCopiableId('Key People')}`)?.innerHTML || '';

    copyFormattedContents([
      formatRawData(copiableHtml),
      formatRawData(question === 'Team' ? (keyPeopleHtml || '') : ''),
    ]);
  // eslint-disable-next-line
  }, [copyFormattedContents, getCopiableId]);

  return { getCopiableId, copyFormattedContents, copyDashboard, copyBulkDashboards, copyQueryAnswer };
};

export default useContentCopy;
