import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getDashboardQueriesFunc } from "../../../lib/helper";
import { Dashboard, DashboardQuery } from "../../../types/files";
import { NoteMetric, NoteMetricKeys } from "../../../types/search";
import { FallbackLoading } from "../../templates/loader";
import MonthlySummary from "../../molecules/market-insights/MonthlySummary";
import moment from "moment";
import TrendsChart from "../../molecules/market-insights/TrendsChart";
import CompanySummary from "../../molecules/market-insights/CompanySummary";

type MetricItem = {
    dashboardId: string;
    dashboardTitle: string;
    date: string | null;
    units: string | null;
    value: number | null;
};

type MetricItems = {
    [key in NoteMetricKeys]: MetricItem[];
};

const useStyles = makeStyles((theme) => ({
    cardsGroupContainer: {
        width: '380px',
        maxWidth: 'auto',
        height: '100%',
        padding: '16px 20px',
        borderRadius: '20px',
        background: 'rgba(123, 212, 212, 0.1)',
    },
    applyButton: {
        borderRadius: 32,
        textTransform: 'none',
        padding: '6px 20px',
        fontSize: 16,
        minWidth: 100
    },
}));

const initMetricItems: MetricItems = {
    Fundraising: [],
    Valuation: [],
    Revenue: [],
    Margin: [],
    Retention: [],
    MRR: [],
    ARR: [],
    "Gross Revenue": [],
    "Net Revenue": [],
    TCV: [],
    ACV: [],
    EBITDA: [],
    "Gross Margins": [],
    "Product Margin": [],
    GMV: [],
    "LTV/CAC": []
};

const MarketInsightsMetrics: React.FC<{
    dashboards: Dashboard[],
    tags: string[],
    stages: string[],
    period: number,
    applyFilter: boolean,
    onResetApplyFilter?: () => void,
}> = ({ dashboards, tags, stages, period, applyFilter, onResetApplyFilter }) => {
    const classes = useStyles();

    const [metricItems, setMetricItems] = useState<MetricItems>(structuredClone(initMetricItems));
    const [loading, setLoading] = useState<boolean>(false);

    const toNumber = useCallback((value: string) => {
        let cleanInput = value!.replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        return (multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, '')));
    }, []);

    const handleApplyFilters = useCallback(() => {
        setLoading(true);

        const filteredDashboards = dashboards.filter(dashboard => {
            const inTags = tags.every(tag => dashboard.tags?.map(tag => tag.toLowerCase()).includes(tag));
            const inStages = !stages.length || stages.some(stage => stage.toLowerCase() === (dashboard.investmentStage || '').toLowerCase());

            return inTags && inStages;
        });

        if (!!filteredDashboards.length) {
            Promise.all(filteredDashboards.map(dashboard => getDashboardQueriesFunc(dashboard.id)))
                .then((queriesGroup: DashboardQuery[][]) => {
                    const dashboardNoteMetrics: {
                        dashboardId: string, dashboardTitle: string, noteMetrics: NoteMetric
                    }[] = [];
                    const items: MetricItems = structuredClone(initMetricItems);
                    const dashboardTitles = filteredDashboards.reduce((group, item) => {
                        group[item.id] = item.title;
                        return group;
                    }, Object.create(null));

                    queriesGroup.forEach(queries => {
                        const metrics = queries.find(q => q.title.toLowerCase() === 'note metrics');
                        if (metrics) {
                            let metricsAnswer = metrics?.answer ? JSON.parse(metrics.answer) : null;
                            if (typeof metricsAnswer?.answer === 'string')
                                metricsAnswer = JSON.parse(metricsAnswer?.answer);
                            dashboardNoteMetrics.push({
                                dashboardId: metrics.dashboardId,
                                dashboardTitle: dashboardTitles[metrics.dashboardId] || '<Unknown Company>',
                                noteMetrics: metricsAnswer?.answer ?? {} as NoteMetric
                            });
                        }
                    });

                    dashboardNoteMetrics.forEach(item => {
                        Object.entries(item.noteMetrics).forEach(([key, value]) => {
                            if (!!value.date && !!value.value)
                                items[key as NoteMetricKeys]?.push({
                                    dashboardId: item.dashboardId,
                                    dashboardTitle: item.dashboardTitle,
                                    date: moment(value.date).toISOString(),
                                    units: ['retention', 'margin'].includes(key.toLowerCase()) ? '%' : '$',
                                    value: toNumber(String(value.value)),
                                });
                    })});

                    setMetricItems(items);
                    onResetApplyFilter?.();
                    setLoading(false);
                });
        } else {
            setMetricItems(structuredClone(initMetricItems));
            onResetApplyFilter?.();
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [dashboards, tags, stages]);

    const insightsInfo = useMemo(() => (
        Object.entries(metricItems).map(([metric, items]) => ({
            metric,
            items: [...items.map(item => ({...item, date: moment(item.date!).format('YYYY-MM'), value: item.value!}))],
            count: items.map(item => item.date).flat(1).reduce((count, date) => count += Number(!!(moment().diff(moment(date), 'months') < period)), 0),
            unit: ['retention', 'margin'].includes(metric.toLowerCase()) ? '%' : '$',
        }))
    ), [metricItems, period]);

    useEffect(() => {
        if (applyFilter)
            handleApplyFilters();
        // eslint-disable-next-line
    }, [applyFilter]);

    return (<>
        <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%">
            {loading ? (
                <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%">
                    <FallbackLoading height={`calc(100vh - 400px)`} />
                </Stack>
            ) : (<>
                <Typography component="div" color="gray" justifyContent="flex-end" display="flex" width="100%">
                    {'*data points are from dashboards in your Notissia workspace.'}
                </Typography>
                {insightsInfo.map((insight, i) => (
                    <Stack p="8px 0" key={"market-insights-metrics-126-" + i}>
                        <Typography component="p" variant="h5-bold" fontFamily="Poppins" color="#666666">
                            {insight.metric}
                            <Typography component="span" variant="h6" fontFamily="Poppins" color="gray" ml={1} display="inline">
                                ({insight.count} companies):
                            </Typography>
                        </Typography>
                        <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
                            <Stack direction="column" className={classes.cardsGroupContainer} sx={{width: '700px !important'}}>
                                <TrendsChart title={insight.metric} data={insight.items} months={period} />
                            </Stack>
                            <Stack direction="column" className={classes.cardsGroupContainer}>
                                <MonthlySummary data={insight.items} unit={insight.unit} />
                            </Stack>
                            <Stack direction="column" className={classes.cardsGroupContainer}>
                                <CompanySummary data={insight.items} unit={insight.unit} months={period} />
                            </Stack>
                        </Stack>
                    </Stack>
                ))}
            </>)}
        </Stack>
    </>);
};

export default MarketInsightsMetrics;