import React, { useContext, useEffect, useMemo } from "react";
import MetricsAnswer from "../dashboard-query-answer/MetricsAnswer";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import {DashboardContext} from "../../../contexts/DashboardContext";
import {DashboardQueriesContext} from "../../../contexts/DashboardQueriesContext";

const MetricsQuery: React.FC<{ roundDetails?: boolean }> = ({ roundDetails }) => {
    const { isPublicView } = useContext(DashboardContext);
    const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
    const { queryAnswer, updateQuery, removeQuery } = useDashboardQuery('Note Metrics');

    const isVisibleByDefault = useMemo(() =>
        defaultQueryTemplateValues.find(defQuery => defQuery?.title === 'Note Metrics')?.visibleByDefault ?? true
    , [defaultQueryTemplateValues]);

    const isPresent = useMemo(() => 'answer' in queryAnswer && !!queryAnswer.answer, [queryAnswer]);
    const isDeleted = useMemo(() => 'deletedAt' in queryAnswer && !!queryAnswer.deletedAt, [queryAnswer]);
    const isHidden = useMemo(() => isDeleted || (!isVisibleByDefault && !isPresent), [isDeleted, isPresent, isVisibleByDefault]);

    useEffect(() => {
        if (!isDeleted && (!isVisibleByDefault && isPresent) && !queryAnswer.manualOverride)
            removeQuery(queryAnswer);
    // eslint-disable-next-line
    }, [isDeleted, isPresent, isVisibleByDefault, queryAnswer]);

    if (isHidden)
        return (<></>);

    return (<>
        <MetricsAnswer
            answeredQuestion={queryAnswer.answeredQuestion}
            roundDetails={roundDetails}
            readOnly={isPublicView}
            onUpdate={answer => updateQuery(answer)}
        />
    </>);
}

export default MetricsQuery;
