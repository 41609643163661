import { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { Box, Button, Chip, Divider, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Dashboard, UnassignedContent } from '../../../types/files';
import DashboardsSearchOrCreate from '../dashboards-search-create/DashboardsSearchOrCreate';
import UnassignedEmailBody from './UnassignedEmailBody';
import UnassignedContentsMenu from './UnassignedContentsMenu';
import moment from 'moment';
import { DashboardsContext } from '../../../contexts/DashboardsContext';
import WebsiteUrlInput from '../crunchbase-modal/WebsiteUrlInput';
import theme from '../../../theme';
import classNames from 'classnames';
import { useDebouncedCallback } from 'use-debounce';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { createDashboardFunc } from '../../../lib/helper';
import { AuthContext } from '../../../contexts/AuthContext';
import { getUserEmail } from '../../../helpers/authUser';
import {v4 as uuidv4} from "uuid";
import { GroupSettingsContext } from '../../../contexts/GroupSettingsContext';
import { LoadingButton } from '@mui/lab';
import useStaticDashboard from '../../../hooks/useStaticDashboard';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { EmailDeal } from '../../../hooks/useEmailDealsExtraction';
import { UnassignedContentData } from '../../atoms/UnassignedContentsBadge';
import UnassignedEmailDeals from './UnassignedEmailDeals';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
    container: {
        "&:hover": {
            background: theme.colors.primary['50'],
        }
    },
    subject: {
        display: '-webkit-box',
        width: 'fit-content',
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        maxWidth: '24ch',
        wordBreak: 'break-all',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    addTo: {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: '0.9rem',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    chip: {
        height: 30,
        width: 120,
        fontFamily: 'Inter',
        fontSize: '0.8rem',
        fontWeight: 500,
        backgroundColor: theme.colors.orange['50'],
        color: theme.colors.orange['500'],
    },
    lastUpdatedDate: {
        color: theme.colors.neutral['600'],
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 45,
            padding: '0 8px !important',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    mediumInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 24,
        },
    },
    mediumSquareInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 16,
        },
    },
    optionsButton: {
      width: 'auto',
      height: 34,
      borderRadius: 24,
      backgroundColor: theme.colors.primary['600'],
      color: 'white',
      fontFamily: 'Inter',
      fontSize: '0.9rem',
      fontWeight: 500,
      textTransform: 'none',
      transition: 'ease-in-out 300ms',
      '&:hover': {
        backgroundColor: theme.colors.primary['500'],
      }
    },
    cancelButton: {
      width: 100,
      height: 36,
      borderRadius: 24,
      borderColor: theme.colors.neutral['100'],
      background: theme.colors.neutral['100'],
      color: theme.colors.neutral['500'],
      fontFamily: 'Inter',
      fontSize: '0.9rem',
      fontWeight: 500,
      textTransform: 'none',
      transition: 'ease-in-out 300ms',
    },
    saveButton: {
      width: 'auto',
      height: 36,
      borderRadius: 24,
      backgroundColor: theme.colors.primary['600'],
      color: 'white',
      fontFamily: 'Inter',
      fontSize: '0.9rem',
      fontWeight: 500,
      textTransform: 'none',
      transition: 'ease-in-out 300ms',
      '&:hover': {
        backgroundColor: theme.colors.primary['500'],
      }
    },
    notificationBlock: {
        width: '100%',
        height: 150,
        transition: 'height .3s ease',
    },
    notificationIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        fill: theme.palette.primary.main,
    },
    notificationText: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
    ownership: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
    },
    owner: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 600,
        color: theme.palette.primary.light,
    },
    icon: {
        width: 100,
        height: 40,
        padding: 'unset',
    },
}));

const UnassignedEmailContent: React.FC<{
    contentData: UnassignedContentData,
    createdAt?: string,
    icon: ReactNode,
    onSave?: (content: UnassignedContent, dashboard?: Dashboard, deals?: EmailDeal[]) => Promise<any>,
    onDelete?: (content: UnassignedContent) => void,
}> = ({ contentData, createdAt, icon, onSave, onDelete }) => {
    const classes = useStyles();
    const {user, userGroup} = useContext(AuthContext);
    const { externalSync } = useContext(GroupSettingsContext);
    const { dashboards } = useContext(DashboardsContext);
    const { saveWebQueryAnswer } = useStaticDashboard();
    const [loading, setLoading] = useState<boolean>(false);
    const [newCompany, setNewCompany] = useState<boolean>(false);
    const [attachCompany, setAttachCompany] = useState<boolean>(false);
    const [dashboard, setDashboard] = useState<Dashboard|null>(null);
    const [dashboardTitle, setDashboardTitle] = useState<string>('');
    const [customUrl, setCustomUrl] = useState<string>('');
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const [notification, setNotification] = useState<string|null>(null);
    const [isExpanded, setExpanded] = useState<boolean>(false);

    const { content, deals, exists } = contentData;

    const hasDeals = useMemo(() => exists.some(exist => !exist), [exists]);

    const debounced = useDebouncedCallback((value) => {
        setDashboardTitle(value);
        handleSearch(value);
    }, 500, { maxWait: 2000 });

    const handleSearch = useCallback((search: string) =>
        setDashboard(dashboards.find(d => d.title.toLowerCase() === search.toLowerCase()) ?? null)
    , [dashboards]);

    const handleClear = useCallback(() => {
        setNewCompany(false);
        setAttachCompany(false);
        setDashboard(null);
        setDashboardTitle('');
        setCustomUrl('');
        setIsNotificationVisible(false);
        setNotification(null);
        setLoading(false);
    }, []);

    const handleSaveDeals = useCallback(async (deals: EmailDeal[]) => {
        setLoading(true);
        setNotification(`New deals successfully added`);
        setIsNotificationVisible(true);

        await new Promise<void>((resolve) => {
            const timeout = setTimeout(async () => {
                clearTimeout(timeout);
                await onSave?.(content, undefined, deals);
                handleClear();
                resolve();
            }, 3000);
        });
    }, [content, handleClear, onSave]);

    const handleAttach = useCallback(() => {
        setLoading(true);
        setNotification(`Email successfully attached to ${dashboard?.title || 'dashboard'}`);
        setIsNotificationVisible(true);

        const timeout = setTimeout(async () => {
            clearTimeout(timeout);
            await onSave?.(content, dashboard!);
            handleClear();
        }, 3000);
    }, [content, dashboard, handleClear, onSave]);

    const handleCreate = useCallback(() => {
        const newDashboard = {
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
            email: getUserEmail(user!),
            group: userGroup!,
            project: userGroup!,
            id: uuidv4(),
            title: dashboardTitle,
            documents: [],
            selections: [],
            screenshots: [],
            notes: [],
            lastUpdatedBy: '',
            shouldRefresh: false,
            externalLinks: null,
            shouldSyncExternally: externalSync,
            refreshData: null,
            summary: '',
            status: '',
        } as Dashboard;

        setLoading(true);
        createDashboardFunc(newDashboard).then(async () => {
            await saveWebQueryAnswer(newDashboard, customUrl);
            setNotification(`${newDashboard?.title || 'dashboard'} successfully added`);
            setIsNotificationVisible(true);

            const timeout = setTimeout(async () => {
                clearTimeout(timeout);
                await onSave?.(content, newDashboard);
                handleClear();
            }, 3000);
        });
    // eslint-disable-next-line
    }, [content, customUrl, dashboard, dashboardTitle, externalSync, user, userGroup]);

    if (isNotificationVisible) {
        return (<>
            <Stack className={classes.container} spacing={1} width="100%" pb={1}>
                <Divider sx={{ width: '100%' }}/>
                <Stack spacing={2} className={classes.notificationBlock} alignItems="center" justifyContent="center">
                    <CheckCircleRoundedIcon className={classes.notificationIcon}/>
                    <Typography className={classes.notificationText}> {notification} </Typography>
                </Stack>
            </Stack>
        </>);
    }

    return (<>
        <Stack className={classes.container} spacing={1} width="100%" pb={1}>
            <Divider sx={{ width: '100%' }}/>
            <Chip className={classes.chip} label="New email" />
            <Stack direction="row" alignItems="center" p="0 8px" width="100%">
                <Box width="40px" mt="4px">
                    {icon}
                </Box>
                <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" pl={1} width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Typography className={classes.subject}>
                            {'Subject: '}
                            <Typography component="span" className={classes.title} display="inline">
                                {content?.subject?.replace('Fwd:', '')?.replace('Fw:', '') || '<Untitled>'}
                            </Typography>
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            {!!createdAt && (
                                <Typography className={classes.lastUpdatedDate}>{`Added ${moment(createdAt).fromNow()}`}</Typography>
                            )}
                            <UnassignedContentsMenu onDelete={() => onDelete?.(content)} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" pl="54px" width="100%" marginTop="-8px !important">
                <Typography className={classes.ownership}>
                    {'From: '}
                    <Typography component="span" className={classes.owner} display="inline" pl="4px">
                        {!!content.from ? content.from : 'N/A'}
                    </Typography>
                </Typography>
                <IconButton className={classes.icon}
                    onClick={(e) => {
                        e.stopPropagation();
                        setExpanded(prev => !prev);
                    }} disableRipple
                > <Typography fontFamily="Inter" fontWeight={500} fontSize="0.8rem">{'See email'}</Typography>
                  <ExpandMoreIcon style={{ transform: !isExpanded ? 'none' : 'rotate(180deg)' }} />
                </IconButton>
            </Stack>
            <UnassignedEmailBody emailBody={content?.body} isExpanded={isExpanded} />
            {hasDeals ? (
                <UnassignedEmailDeals
                    emailDeals={deals}
                    exists={exists}
                    onSave={handleSaveDeals}
                    onDiscard={() => onDelete?.(content)} />
            ) : (<>
                {(newCompany && !attachCompany) && (<>
                    <Stack direction="row" alignItems="center" width="91%" height="100%" pb={1}>
                        <Typography className={classes.addTo} minWidth="160px"> {'Add new company:'} </Typography>
                        <TextField
                            variant="outlined"
                            className={classNames(classes.inputField, classes.mediumInputField, classes.mediumSquareInputField)}
                            placeholder='Company name...'
                            onChange={(e) => debounced(e.target.value)}
                            sx={{ width: '100%' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                        <AddRoundedIcon />
                                    </InputAdornment>
                                )}}/>
                    </Stack>
                    <Stack direction="row" alignItems="center" width="91%" height="100%" pb={1}>
                        <Typography minWidth="160px" />
                        <WebsiteUrlInput placeholder={'Company website...'}customUrl={customUrl} onCustomUrl={setCustomUrl} inlineCaption />
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" width="90%" pb={1}>
                        <Button variant="outlined"
                            className={classes.cancelButton}
                            onClick={handleClear}
                            disabled={loading}>
                            {'Discard'}
                        </Button>
                        <LoadingButton variant="contained"
                            className={classes.saveButton}
                            onClick={handleCreate}
                            disabled={!!dashboard}
                            loading={loading}>
                            {'Add'}
                        </LoadingButton>
                    </Stack>
                </>)}
                {(!newCompany && attachCompany) && (<>
                    <Stack direction="row" alignItems="center" width="91%" height="100%" pb={1}>
                        <Typography className={classes.addTo} minWidth="90px"> {'Attach to:'} </Typography>
                        <DashboardsSearchOrCreate search autoComplete mediumFit squareFit onSearch={handleSearch} />
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end" width="90%" pb={2}>
                        <Button variant="outlined"
                            className={classes.cancelButton}
                            onClick={handleClear}>
                            {'Discard'}
                        </Button>
                        <LoadingButton variant="contained"
                            className={classes.saveButton}
                            disabled={!dashboard}
                            loading={loading}
                            onClick={handleAttach}>
                            {'Attach'}
                        </LoadingButton>
                    </Stack>
                </>)}
                {(!newCompany && !attachCompany) && (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" pl="54px" pb={2}>
                        <Button variant="contained"
                            className={classes.optionsButton}
                            onClick={() => setNewCompany(true)}>
                            {'New company'}
                        </Button>
                        <LoadingButton variant="contained"
                            className={classes.optionsButton}
                            onClick={() => setAttachCompany(true)}>
                            {'Attach to existing company'}
                        </LoadingButton>
                    </Stack>
                )}
            </>)}
        </Stack>
    </>);
}

export default UnassignedEmailContent;