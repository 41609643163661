import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { scrollbarStyle } from "../../../shared/dashboard";
import { Dashboard, Workspace } from "../../../types/files";
import { FallbackLoading } from "../../templates/loader";
import NoDashboardsFoundView from "../dashboards-empty/NoDashboardsFoundView";
import useDashboards from "../../../hooks/useDashboards";
import moment from "moment";
import DashboardsTableRow from "../dashboards-table-view/DashboardsTableRow";
import DashboardsTableHeader, { TableHeaders } from "../dashboards-table-view/DashboardsTableHeader";
import theme from "../../../theme";

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(100% - 30px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        marginTop: 20,
        ...scrollbarStyle,
    },
    table: {
        width: '100%',
        height: 'calc(100vh - 190px)',
        overflow: 'hidden',
        marginLeft: 12,
    },
    headers: {
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        ...scrollbarStyle,
    },
    rows: {
        minWidth: '100%',
        width: 'fit-content',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    section: {
        width: 'calc(100vw - 560px)',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        fontWeight: 600,
        color: '#3ABDC6',
        "&::before, &::after": {
            borderTop: 'thin solid #3ABDC6',
        }
    },
    loadingText: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 700,
        color: theme.colors.neutral['900'],
    },
    loadingTextSub: {
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: '#9e9e9e',
    },
    loading: {
        width: '100%',
        height: '100%',
        color: theme.palette.primary.main,
    },
    sharedTitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: '600',
        color: theme.colors.neutral['700'],
    },
}));

const SavedMatches: React.FC<{
    workspace?: Workspace,
    search?: string,
 }> = ({ workspace, search }) => {
    const classes = useStyles();
    const { workspace: globalWorkspace, isPublicView } = useContext(DashboardsContext);
    const { dashboardsLoaded, filterDashboardList } = useDashboards();

    const [latelySharedArray, setLatelySharedArray] = useState<Dashboard[]>([]);
    const [previouslySharedArray, setPreviouslySharedArray] = useState<Dashboard[]>([]);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const localWorkspace = useMemo(() => workspace ?? globalWorkspace, [globalWorkspace, workspace]);

    const getMembership = useCallback((dashboard: Dashboard) =>
        dashboard?.workspaceMemberships?.find(wsMem => wsMem.workspaceId === localWorkspace?.id)
    , [localWorkspace?.id]);

    const filteredDashboards = useMemo(() =>
        filterDashboardList(search || '', localWorkspace?.id)
            .sort((prev: Dashboard, next: Dashboard) => {
                const prevDate = getMembership(prev)?.updatedAt || prev.createdAt;
                const nextDate = getMembership(next)?.updatedAt || next.createdAt;

                return nextDate.localeCompare(prevDate);
            })
        // eslint-disable-next-line
    , [filterDashboardList, localWorkspace, search]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            setLatelySharedArray(filteredDashboards.filter(dashboard => {
                const wsMembership = getMembership(dashboard);

                return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') < 7;
            }));
            setPreviouslySharedArray(filteredDashboards.filter(dashboard => {
                const wsMembership = getMembership(dashboard);

                return moment().diff(!!wsMembership ? wsMembership.updatedAt : dashboard.updatedAt, 'days') >= 7;
            }));
            clearTimeout(timerRef.current);
        }, 500);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [filteredDashboards]);

    if (!localWorkspace)
        return (<></>);

    return (<>
        {(!dashboardsLoaded || (!!filteredDashboards.length && (!latelySharedArray.length && !previouslySharedArray.length))) ? (
            <FallbackLoading />
        ) : (<>
            {isPublicView && (<>
                <Typography className={classes.sharedTitle} pl={1} py={1}>
                    {`Deals shared:`}
                </Typography>
                <Divider sx={{ width: '100%' }} />
            </>)}
            <Stack className={classes.table}>
                {(!!latelySharedArray.length || !!previouslySharedArray.length) ? (<>
                    <Stack className={classes.headers} alignItems="flex-start" justifyContent="flex-start">
                        <DashboardsTableHeader columns={TableHeaders} withFitScore />
                        <Stack className={classes.rows} alignItems="flex-start" justifyContent="flex-start">
                            {latelySharedArray?.map(dashboard => (
                                <DashboardsTableRow
                                    dashboard={dashboard}
                                    workspace={workspace ?? undefined}
                                    columns={TableHeaders}
                                    timestamp={getMembership(dashboard)?.updatedAt}
                                    isPublic={false}
                                    withFitScore
                                    key={'saved-matches-150-' + dashboard.id} />
                            ))}
                            {!!previouslySharedArray.length && (
                                <Stack direction="row" alignItems="center" justifyContent="stretch" width="100%" minHeight="80px">
                                    <Divider className={classes.section}>{'Previously shared'}</Divider>
                                </Stack>
                            )}
                            <Box borderBottom={`1px solid ${theme.colors.neutral['400']}`} width="100%" />
                            {previouslySharedArray?.map(dashboard => (
                                <DashboardsTableRow
                                    dashboard={dashboard}
                                    workspace={workspace ?? undefined}
                                    columns={TableHeaders}
                                    timestamp={getMembership(dashboard)?.updatedAt}
                                    isPublic={false}
                                    forSharing
                                    withFitScore
                                    prevShared
                                    key={'saved-matches-172-' + dashboard.id} />
                            ))}
                        </Stack>
                    </Stack>
                </>) : (<NoDashboardsFoundView noResult isPublic={isPublicView} />)}
            </Stack>
        </>)}
    </>);
}

export default SavedMatches;