import { useCallback, useContext } from 'react';
import { Dashboard, DashboardQueriesType, DashboardQuery } from '../types/files';
import ArrayUtils from '../utils/ArrayUtils';
import {AuthContext} from '../contexts/AuthContext';
import { BulkActionsContext } from '../contexts/BulkActionsContext';
import {DashboardQueriesContext} from '../contexts/DashboardQueriesContext';
import {DashboardsContext} from '../contexts/DashboardsContext';
import { getDashboardQueriesFunc, addWorkspaceToDashboardFunc } from '../lib/helper';
import useBackwardsCompatible from './useBackwardsCompatible';

export const ExcludeExportQueries = [
  'Digest',
  'Competitive Advantage',
  'Growth',
  'Email Summaries',
  'LinkedIn Posts',
  'Note Summary',
  'Questions',
  'Recent News',
  'Screenshot Summaries',
  'Similar Companies',
  'Tags',
];

const useBulkDashboards = () => {
  const { userGroup } = useContext(AuthContext);
  const { dashboards, setDashboards } = useContext(DashboardsContext);
  const { defaultQueryTemplateValues } = useContext(DashboardQueriesContext);
  const { loading, bulkDashboardsMap, setLoading, setBulkDashboardsMap } = useContext(BulkActionsContext);
    const { dashboardWorkspaces } = useBackwardsCompatible();

  const getBulkDashboards = useCallback((collection: string) =>
    Array.from(bulkDashboardsMap, ([collection, bulk]) => ({ collection, bulk })).filter(data => data.collection === collection).map(data => data.bulk).flat()
  , [bulkDashboardsMap]);

  const clearBulk = useCallback((collection?: string) => {
    if (collection) {
      setBulkDashboardsMap(prev => {
        prev.set(collection, []);

        return new Map(prev);
      });
    } else {
      setBulkDashboardsMap(new Map([]));
    }
  }, [setBulkDashboardsMap]);

  const addToBulk = useCallback((collection: string, dashboard: Dashboard, queries?: DashboardQuery[]) => {
    setBulkDashboardsMap(prev => {
      const collections = prev.get(collection);

      if (!collections?.find(exportable => exportable.dashboard.id === dashboard.id))
        prev.set(collection, [...(collections || []), {dashboard: dashboard, queries: queries ?? []}]);

      return new Map(prev);
    });
  }, [setBulkDashboardsMap]);

  const removeFromBulk = useCallback((collection: string, dashboard: Dashboard) => {
    setBulkDashboardsMap(prev => {
      const collections = prev.get(collection);

      if (!!collections?.find(exportable => exportable.dashboard.id === dashboard.id))
        prev.set(collection, collections?.filter(exportable => exportable.dashboard.id !== dashboard.id) ?? []);

      return new Map(prev);
    });
  }, [setBulkDashboardsMap]);

  const buildExportable = useCallback((collection:string, selectedTitles?: string[]) => {
    return new Promise<DashboardQueriesType[] | any>((resolve, reject) => {
      const promises: Promise<DashboardQuery[]>[] = [];
      const bulkDashboards = getBulkDashboards(collection);

      setLoading(true);
      bulkDashboards.forEach(exportable =>
        promises.push(getDashboardQueriesFunc(exportable.dashboard.id))
      );

      Promise.all(promises).then((dashboardQueriesData: DashboardQuery[][]) => {
        const flatDashboardQueries = dashboardQueriesData.flat();
        const exports = [...bulkDashboards].map(exportable => ({...exportable,
          queries: flatDashboardQueries.filter(query =>
            query.dashboardId === exportable.dashboard.id
            && !ExcludeExportQueries.includes(query.title)
            && (selectedTitles ?? [query.title]).includes(query.title)
          ).map(query => ({...query,
              title: (defaultQueryTemplateValues
                .find(defQuery => defQuery?.title === query.title)?.displayTitle || query.title)
                  .replace('2.0', ''),
              order: defaultQueryTemplateValues.find(defQuery => defQuery.title === query.title)?.order || query.order
            })).sort((qA, qB) => qA.order - qB.order),
        }));

        resolve(exports);
      }).catch((error) => {
        reject(error);
      }).finally(() => setLoading(false));
    });
  }, [defaultQueryTemplateValues, getBulkDashboards, setLoading]);

  const assignToWorkspaces = useCallback((collection: string, workspaceIds: string[]) => {
    return new Promise((resolve, reject) => {
      const promises: Promise<Dashboard>[] = [];
      const bulkDashboards = getBulkDashboards(collection);

      setLoading(true);
      bulkDashboards.forEach(assignable => {
        const assignedIds = dashboardWorkspaces(assignable.dashboard) ?? [];
        const newWorkspaceIds = workspaceIds.filter(id => !assignedIds.includes(id));

        newWorkspaceIds.forEach(id => {
          promises.push(addWorkspaceToDashboardFunc({
            dashboardId: assignable.dashboard.id,
            workspaceId: id,
            group: userGroup,
          }));
        });
      });

      Promise.all(promises).then((dashboardUpdates: Dashboard[]) => {
        // force update dashboards list
        const updatedDashboards = [...dashboards].map(dashboard => {
          const updates = dashboardUpdates.filter(dU => dU.id === dashboard.id);

          if (!!updates.length)
            return ArrayUtils.sortByDescending(updates, 'createdAt')[0];

          return dashboard;
        });

        setDashboards(ArrayUtils.sortByDescending(updatedDashboards, 'createdAt'));

        resolve(true);
      }).catch((error) => {
        reject(error);
      }).finally(() => setLoading(false));
    });
  // eslint-disable-next-line
  }, [dashboards, userGroup, getBulkDashboards]);

  return { loading, getBulkDashboards, clearBulk, addToBulk, removeFromBulk, buildExportable, assignToWorkspaces };
};

export default useBulkDashboards;