import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Chip, Divider, InputAdornment, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {ReactComponent as AttachmentIcon} from "../../../assets/icons/attachment.svg";
import {ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import { LoadingButton } from '@mui/lab';
import {DashboardsContext} from '../../../contexts/DashboardsContext';
import theme from '../../../theme';
import { Dashboard, DocSendIngestion } from '../../../types/files';
import moment from 'moment';
import UnassignedContentsMenu from './UnassignedContentsMenu';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const useStyles = makeStyles((theme) => ({
    container: {
        "&:hover": {
            background: theme.colors.primary['50'],
        }
    },
    contentIcon: {
        width: 32,
        height: 32,
    },
    title: {
        display: '-webkit-box',
        width: 'fit-content',
        fontSize: '1.1rem',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: 'black',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        textOverflow: 'ellipsis',
        textTransform: 'capitalize',
        maxWidth: '32ch',
    },
    docsendUrl: {
      display: '-webkit-box',
      overflow: 'hidden',
      maxWidth: '32ch',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
      wordBreak: 'break-all',
      fontFamily: 'Inter',
      fontSize: '0.9rem',
      color: '#7bd4d4',
      textOverflow: 'ellipsis',
      textTransform: 'none' as any,
      textDecoration: 'none !important',
      textAlign: 'left',
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            width: '100%',
            height: 40,
            borderRadius: 24,
            padding: '0 8px !important',
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    createButton: {
        height: 30,
        width: 40,
        borderRadius: '16px !important',
        borderWidth: 3,
        fontSize: '14px !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
    chip: {
        height: 30,
        width: 180,
        fontFamily: 'Inter',
        fontSize: '0.8rem',
        fontWeight: 500,
        backgroundColor: theme.colors.orange['50'],
        color: theme.colors.orange['500'],
    },
    lastUpdatedDate: {
        color: theme.colors.neutral['600'],
        fontSize: '0.8rem',
        whiteSpace: 'nowrap',
    },
    notificationBlock: {
        width: '100%',
        height: 150,
        transition: 'height .3s ease',
    },
    notificationIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        fill: theme.palette.primary.main,
    },
    notificationText: {
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['700'],
    },
}));

const UnassignedDocSendContent: React.FC<{
    docsend: DocSendIngestion,
    createdAt?: string,
    onDelete: () => void,
    onIngestion: (docsend: DocSendIngestion, password: string) => Promise<any>,
}> = ({ docsend, createdAt, onDelete, onIngestion }) => {
    const classes = useStyles();
    const { dashboards } = useContext(DashboardsContext);
    const [dashboard, setDashboard] = useState<Dashboard|undefined>(undefined);
    const inputValue = useRef<TextFieldProps>(null);
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const [notification, setNotification] = useState<string|null>(null);

    const handleAssign = useCallback(() => {
        setNotification(`DocSend successfully attached to ${dashboard?.title || 'dashboard'}`);
        setIsNotificationVisible(true);
    }, [dashboard]);

    useEffect(() => {
        setDashboard(dashboards.find(dashboard => docsend?.existingDashboardId ?
            dashboard.id === docsend?.existingDashboardId : dashboard.title === docsend?.newDashboardTitle));
    }, [docsend, dashboards]);

    useEffect(() => {
        if (isNotificationVisible) {
            const timeoutId: any = setTimeout(() => {
                clearTimeout(timeoutId);
                onIngestion(docsend, inputValue.current?.value as string).then(() => {
                    setIsNotificationVisible(false);
                    setNotification(null);
                });
            }, 3000);

            return () => {
                clearTimeout(timeoutId);
            }
        }
        // eslint-disable-next-line
    }, [isNotificationVisible]);

    if (!dashboard)
        return (<></>);

    if (isNotificationVisible) {
        return (<>
            <Stack spacing={2} className={classes.notificationBlock} alignItems="center" justifyContent="center">
                <CheckCircleRoundedIcon className={classes.notificationIcon}/>
                <Typography className={classes.notificationText}> {notification} </Typography>
            </Stack>
        </>);
    }

    return (<>
        <Stack className={classes.container} spacing={1} width="100%" p={1}>
            <Divider sx={{ width: '100%' }}/>
            <Chip className={classes.chip} label="Password required" />
            <Stack direction="row" alignItems="center" p="0 8px" width="100%">
                <Box width="40px" mt="4px">
                    <DashboardIcon className={classes.contentIcon} fill={theme.palette.primary.main} />
                </Box>
                <Stack direction="column" spacing={1} alignItems="flex-start" justifyContent="center" pl={1} width="100%">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                        <Typography className={classes.title}>
                            {dashboard?.title || '<Untitled>'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            {!!createdAt && (
                                <Typography className={classes.lastUpdatedDate}>{`Added ${moment(createdAt).fromNow()}`}</Typography>
                            )}
                            <UnassignedContentsMenu onDelete={onDelete} />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="column" spacing="4px" alignItems="flex-start" pl={6} pr={4}>
                <Stack direction="row" spacing="4px" alignItems="center" justifyContent="flex-start" width="100%">
                    <Box width={24} height={24}>
                        <AttachmentIcon width={24} height={24} stroke="#7bd4d4" />
                    </Box>
                    <Typography component="a"
                        className={classes.docsendUrl}
                        href={docsend?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        display="inline">
                        {docsend?.url}
                    </Typography>
                </Stack>
            </Stack>
            <Stack width="100%" pl={6} pr={4}>
                <TextField variant="outlined"
                    className={classes.inputField}
                    placeholder={'Enter password here ...'}
                    inputRef={inputValue}
                    sx={{ width: '100%' }}
                    InputProps={{ endAdornment: (
                        <InputAdornment position="end">
                            <LoadingButton variant="contained"
                                className={classes.createButton}
                                onClick={handleAssign}>
                                {'Save'}
                            </LoadingButton>
                        </InputAdornment>
                    )}} />
            </Stack>
        </Stack>
    </>);
}

export default UnassignedDocSendContent;
