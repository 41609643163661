import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import classnames from "classnames";
import { AnswerQuestionResponse, AnswerQuestionResponseType, NoteMetric } from "../../../types/search";
import useDashboardQuery from "../../../hooks/useDashboardQuery";
import moment, { Moment } from "moment";
import { Units } from "../../molecules/dashboard-query-answer/MetricsAnswer";
import { useDebouncedCallback } from "use-debounce";
import { MetricsData } from "../../atoms/MetricsTimeline";

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 500,
            height: 'fit-content',
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
        gap: 8,
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 0,
        gap: 8,
    },
    label: {
        width: 85,
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.cyan['400'],
    },
    inputField: {
        width: '100%',
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& .MuiOutlinedInput-root": {
            padding: 'unset',
            "& > input, > textarea": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                padding: 'unset',
            },
        },
    },
    datePicker: {
        width: '100%',
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& > .MuiInputBase-root":{
            "& > input": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                padding: 'unset',
            },
            "& fieldset": {
              border: 0,
            },
        }
    },
    unitSelectorForm: {
        width: '100%',
        border: `1px solid ${theme.colors.neutral[400]}`,
        borderRadius: 8,
        padding: 8,
        "& > .MuiInputBase-root":{
            padding: 'unset',
            "& > div": {
                fontFamily: 'Lato',
                fontSize: '1.2rem',
                padding: 'unset',
            },
            "& fieldset": {
              border: 0,
            },
        }
    },
    deleteButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        textTransform: 'none',
    },
    commonButton: {
        width: 100,
        borderRadius: 32,
        fontWeight: 'bold',
        textTransform: 'none',
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        fontWeight: 'bold',
        textTransform: 'none',
    },
}));

const DateFmt = 'MMM YYYY';

const AddCustomMetricsModal: React.FC<{
    isOpen: boolean,
    customValues?: MetricsData,
    onClose: () => void,
}> = ({ isOpen, customValues, onClose }) => {
    const classes = useStyles();
    const { queryAnswer, updateQuery: updateNoteMetrics } = useDashboardQuery('Note Metrics');

    const [loading, setLoading] = useState<boolean>(false);
    const [customMetric, setCustomMetric] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [recentDate, setRecentDate] = useState<Moment>(moment(new Date()));
    const [isValidDate, setValidDate] = useState<boolean>(true);
    const [selectedUnit, setSelectedUnit] = useState<string>('');
    const [metricsHistory, setMetricsHistory] = useState<NoteMetric[]>([]);
    const [metricsHistoryList, setMetricsHistoryList] = useState<{
        metric: string,
        history: {
            date?: string|null,
            units?: string|null,
            value?: number|null,
            visible?: boolean|null,
        }[],
    }[]>([]);

    const debouncedMetric = useDebouncedCallback(value => setCustomMetric(value.trim()), 100, { maxWait: 200 });
    const debouncedValue = useDebouncedCallback(value => setValue(value.trim()), 100, { maxWait: 200 });

    const toNumber = (value: string) => {
        if (!value)
            return null;

        let cleanInput = value!.replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        return (multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, '')));
    }

    const isSaveReady = useMemo(() =>
        !!customMetric && !!value && isValidDate && !!Units.get(selectedUnit)
    , [customMetric, value, isValidDate, selectedUnit]);

    const handleUpdateMetrics = useCallback((category: string, newValue: string|null, newDate: Moment, units: string|null) => {
        const date = moment(newDate, DateFmt).toISOString();
        const value = toNumber(newValue ?? '');
        let updatedMetrics: any = {};
        let updatedHistory: any[] = [];
        let metricsSnapshot: any = {};

        metricsHistoryList.forEach(({metric, history}) => {
            if (metric === category) {
                updatedMetrics[metric] = { date, units, value };
                metricsSnapshot[metric] = {...history[0]};
            } else {
                updatedMetrics[metric] = {...history[0]};
                metricsSnapshot[metric] = {date: null, value: null, units: null};
            }
        });

        if (!updatedMetrics[category]) {
            updatedMetrics[category] = { date, units, value };
            metricsSnapshot[category] = {date: null, value: null, units: null};
        }

        [metricsSnapshot, ...metricsHistory].map((history: any) => history?.answer ?? history)
            ?.forEach((metrics: NoteMetric) => {
                if (!Object.entries(metrics).find(([metric, {date, units, value}]) => (metric === category &&
                    !!date && !!units && !!value && (moment(date).format(DateFmt) === newDate.format(DateFmt)))))
                        updatedHistory.push({answer: metrics, type: AnswerQuestionResponseType.METRICS} as AnswerQuestionResponse);
            });

        updateNoteMetrics({
            answer: updatedMetrics,
            history: updatedHistory,
            type: AnswerQuestionResponseType.METRICS,
        } as AnswerQuestionResponse);
    }, [metricsHistory, metricsHistoryList, updateNoteMetrics]);

    const handleSave = useCallback(() => {
        setLoading(true);
        handleUpdateMetrics(customMetric, value, recentDate, selectedUnit || '#');
        setLoading(false);
        onClose();
    }, [customMetric, value, recentDate, selectedUnit, handleUpdateMetrics, onClose]);

    const handleDelete = useCallback(() => {
        setLoading(true);
        handleUpdateMetrics(customMetric, null, recentDate, null);
        setLoading(false);
        onClose();
    }, [customMetric, recentDate, handleUpdateMetrics, onClose]);

    useEffect(() => {
        const answeredQuestion = queryAnswer.answeredQuestion;
        const historyRecord = answeredQuestion.history?.map((historyAnswer: any) => historyAnswer?.answer ?? historyAnswer) ?? [];
        const historyList = Object.entries(answeredQuestion.answer ?? {})
            .map(([metric, value]) => {
                const filteredHistory = historyRecord
                    .map(history => history[metric as keyof NoteMetric])
                    .filter(values => !!values && Object.values(values).every(value => !!value));

                return ({metric, history: [{...value as any}, ...filteredHistory]});
            });

        setMetricsHistory(historyRecord);
        setMetricsHistoryList(historyList);
    }, [queryAnswer]);

    useEffect(() => {
        if (!!customValues) {
            setCustomMetric(customValues?.metric);
            setValue(customValues?.value.replace(/[^0-9.-]/g, ''));
            setRecentDate(moment(customValues.date).isValid() ? customValues.date! : moment(new Date()));
            setSelectedUnit(customValues?.units!);
        }
    },[customValues]);

    useEffect(() => {
        if (!isValidDate && moment(recentDate).isValid())
            setValidDate(true);
    },[isValidDate, recentDate]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {`Add/edit metric`}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <Typography className={classes.label}>{'Metric'}</Typography>
                    <TextField variant="outlined"
                        className={classnames('no-border', classes.inputField)}
                        inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                        placeholder={'Metric name...'}
                        defaultValue={customValues?.metric}
                        onChange={(e) => debouncedMetric(e.target.value as string)}
                        disabled={loading}
                        rows={1}
                    />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <Typography className={classes.label}>{'Amount'}</Typography>
                    <TextField variant="outlined"
                        className={classnames('no-border', classes.inputField)}
                        inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                        placeholder={'Value...'}
                        defaultValue={customValues?.value?.replace(/[^0-9.-]/g, '')}
                        onChange={(e) => debouncedValue(e.target.value as string)}
                        disabled={loading}
                        rows={1}
                    />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <Typography className={classes.label}>{'Date'}</Typography>
                    <DatePicker className={classes.datePicker}
                        format="MMM YYYY"
                        views={['year', 'month']}
                        minDate={moment(new Date(2000, 0, 1))}
                        maxDate={moment()}
                        openTo="month"
                        value={recentDate}
                        onChange={(newValue) => {setRecentDate(newValue!); setValidDate(true);}}
                        onError={() => setValidDate(false)} />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" width="100%">
                    <Typography className={classes.label}>{'Unit'}</Typography>
                    <FormControl className={classes.unitSelectorForm} variant="outlined">
                        <Select value={selectedUnit}
                            renderValue={(selected) => (<>
                                {!selected ? (
                                    <Typography sx={{ fontSize: "1.2rem", color: "#696e70", opacity: "0.5" }}>{'Unit...'}</Typography>
                                ) : `${Units.get(selected)}`}
                            </>)}
                            onChange={(e: SelectChangeEvent) => setSelectedUnit(e.target.value)}
                            displayEmpty>
                            {Array.from(Units).map(([symbol, label], i) => (
                                <MenuItem value={symbol} key={"add-custom-metrics-modal-239-" + i}>{label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                    {!!customValues ? (
                        <LoadingButton variant="outlined"
                            className={classes.deleteButton}
                            loading={loading}
                            onClick={handleDelete}
                        > {'Delete'} </LoadingButton>
                    ) : (<Box />)}
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        <Button variant="outlined"
                            className={classes.cancelButton}
                            onClick={() => onClose()}
                        > {'Cancel'} </Button>
                        <LoadingButton variant="contained"
                            className={classes.commonButton}
                            loading={loading}
                            disabled={!isSaveReady}
                            onClick={handleSave}
                        > {'Save'} </LoadingButton>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default AddCustomMetricsModal;