import { useState } from 'react';
import { Collapse, IconButton, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    email: {
        paddingLeft: 8,
        paddingRight: 16,
        fontSize: '0.95rem',
        fontFamily: 'Inter',
        fontWeight: 'bold',
        color: theme.colors.neutral['600'],
    },
    emailField: {
        width: '100%',
        maxWidth: 370,
        height: 'auto',
        padding: '1rem',
        borderRadius: 12,
        border: '1px solid lightgray',
        color: theme.colors.neutral['600'],
        fontFamily: 'Inter',
        fontSize: '0.85rem',
        lineHeight: '1.5',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    },
    fullEmailField: {
        maxWidth: 410,
    },
    emailCollapsed: {
        display: '-webkit-box',
        height: 55,
        padding: '0.5rem 1rem',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        wordBreak: 'break-word',
        textOverflow: 'ellipsis',
    },
    icon: {
        width: 40,
        height: 40,
        padding: 'unset',
    },
}));

const UnassignedEmailBody: React.FC<{ emailBody?: string, isExpanded?: boolean }> = ({ emailBody, isExpanded }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<boolean>(isExpanded !== undefined);

    return (<>
        <Collapse in={isExpanded ?? true} collapsedSize={0}>
            <Stack direction="row" alignItems="flex-start" width="100%" height="100%" py={1}>
                {(isExpanded === undefined) ? (
                    <Typography className={classes.email}>{'Email:'}</Typography>
                ) : (<Typography width={60} />)}
                <Stack direction="row" alignItems="flex-end" width="100%" height="100%">
                    <Typography className={classNames(classes.emailField,
                        (isExpanded !== undefined) && classes.fullEmailField,
                        !expanded && classes.emailCollapsed)}>
                        {emailBody}
                    </Typography>
                    {(isExpanded === undefined) && (
                        <IconButton className={classes.icon}
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpanded(prev => !prev);
                            }} disableRipple
                        > <ExpandMoreIcon style={{ transform: !expanded ? 'none' : 'rotate(180deg)' }} />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
        </Collapse>
    </>);
}

export default UnassignedEmailBody;
