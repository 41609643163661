import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EmailDeal } from "../../hooks/useEmailDealsExtraction";

const useStyles = makeStyles(() => ({
    copyButton: {
        minWidth: 'unset !important',
        "& > .MuiButton-startIcon": {
            margin: 'unset !important',
        },
        '&:hover': {
            background: 'inherit',
            color: '#666666',
        },
    },
    copyIcon: {
        width: '20px !important',
        height: '20px !important',
    },
    checkBox: {
        padding: 'unset !important',
        marginRight: '4px !important',
        "& > svg": {
            width: '20px !important',
            height: '20px !important',
        }
    },
}));

const EmailDealSelect: React.FC<{
    emailDeal: EmailDeal,
    selectedDeals: EmailDeal[],
    setSelectedDeals: React.Dispatch<React.SetStateAction<EmailDeal[]>>,
 }> = ({ emailDeal, selectedDeals, setSelectedDeals }) => {
    const classes = useStyles();
    const [checked, setChecked] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    const timerRef = useRef<string | number | NodeJS.Timeout | undefined>(undefined);

    const cleared = useMemo(() => !selectedDeals.length, [selectedDeals]);

    const selected = useMemo(() =>
        !!selectedDeals.find(deal => deal.id === emailDeal.id)
    , [selectedDeals, emailDeal]);

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            if (loaded) {
                if (checked && !selected){
                    setSelectedDeals(prev => {
                        if (!selectedDeals.find(deal => deal.id === emailDeal.id))
                          return [...selectedDeals, emailDeal];

                        return prev;
                      });
                } else if (!checked && selected) {
                    setSelectedDeals(prev => {
                        if (!!selectedDeals.find(deal => deal.id === emailDeal.id))
                            return selectedDeals.filter(item => item.id !== emailDeal.id) ?? [];

                        return prev;
                    });
                }
            }

            clearTimeout(timerRef.current);
        }, 200);

        return () => {
            clearTimeout(timerRef.current);
        }
    // eslint-disable-next-line
    }, [checked]);

    useEffect(() => {
        if (!loaded) {
            setChecked(selected);
            setLoaded(true);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (cleared || !selected)
            setChecked(false);
    // eslint-disable-next-line
    }, [cleared]);

    return (<>
        <Checkbox edge="end"
            className={classes.checkBox}
            checked={checked}
            onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                setChecked(prev => !prev);
            }}
            disableRipple
        />
    </>);
}

export default memo(EmailDealSelect);