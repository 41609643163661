import React, { useState } from "react";
import { Typography, Stack, Collapse } from "@mui/material";
import theme from "../../../theme";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MarketSizeOverviewQuery from "../../molecules/dashboard-queries/MarketSizeOverviewQuery";

const useStyles = makeStyles(() => ({
    expandIcon: {
        fill: '#666666 !important',
        alignItems: 'center !important',
        justifyContent: 'right',
        cursor: 'pointer',
    },
}));

const DashboardMarketSize: React.FC<{}> = () => {
    const classes = useStyles();
    const [isExpanded, setExpanded] = useState<boolean>(true);

    return (<>
        <Collapse in={isExpanded} collapsedSize={40}>
            <Stack direction="row" spacing={2} alignItems="flex-start" marginLeft={1} marginBottom={2}>
                <Typography fontFamily="Inter" fontWeight="bold" fontSize="1.2rem" color={theme.colors.neutral['400']}>
                    {'Market Size & Overview'}
                </Typography>
                <ExpandMoreIcon
                    className={classes.expandIcon}
                    sx={isExpanded ? { transform: 'rotate(180deg) !important' } : {}}
                    onClick={(e)=> {
                        e.stopPropagation();
                        e.preventDefault();
                        setExpanded((prev) => !prev);
                    }} />
            </Stack>
            <MarketSizeOverviewQuery />
        </Collapse>
    </>);
};

export default DashboardMarketSize;