import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete, Box, InputAdornment, Popper, PopperPlacementType, TextField, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { v4 as uuidv4 } from "uuid";
import { LoadingButton } from "@mui/lab";
import { getUserEmail } from "../../../helpers/authUser";
import { Dashboard } from "../../../types/files";
import { createDashboardFunc } from "../../../lib/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SubscriptionContext } from "../../../contexts/SubscriptionContext";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import classnames from "classnames";
import { useSnackbar } from "notistack";
import theme from "../../../theme";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { ChromeExtensionLink } from "../../../shared/dashboard";
import { useDebouncedCallback } from "use-debounce";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import ArrayUtils from "../../../utils/ArrayUtils";
import {ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";

const useStyles = makeStyles((theme) => ({
    optionPaper: {
        background: 'white',
        border: `1px solid ${theme.colors.neutral['100']}`,
        borderRadius: 12,
        boxShadow: '0px 3px 5px -1px rgba(16, 24, 40,0.2), 0px 5px 8px 0px rgba(16, 24, 40,0.14), 0px 1px 14px 0px rgba(16, 24, 40,0.12)',
    },
    optionContainer: {
        padding: '2px 12px',
        margin: '4px 8px 4px 16px',
        border: `1px solid ${theme.colors.neutral['200']}`,
        borderRadius: 32,
        transition: '0.5s ease-in-out',
        "&:hover": {
            backgroundColor: '#EFF6FF',
            boxShadow: '0px 4px 10px -1px rgba(16, 24, 40, 0.06)',
        },
        cursor: 'pointer',
    },
    optionItem: {
        fontSize: '0.9rem !important',
        fontWeight: '600 !important',
        color: `${theme.colors.neutral['800']} !important`,
    },
    inputField: {
        minWidth: 'fit-content',
        "& .MuiOutlinedInput-root": {
            minWidth: 250,
            height: 45,
            padding: '0 8px !important',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                padding: 'unset !important',
                fontSize: '1rem',
            },
            "& fieldset": {
              	borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              	borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              	borderColor: `${theme.palette.primary.main} !important`,
            },
        },
    },
    disabledInputField: {
        "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
                borderWidth: '1px !important',
                borderColor: 'rgba(0, 0, 0, 0.38) !important',
            },
        },
    },
    smallInputField: {
        "& .MuiOutlinedInput-root": {
            height: 32,
            borderRadius: 16,
        },
    },
    mediumInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 24,
        },
    },
    mediumSquareInputField: {
        "& .MuiOutlinedInput-root": {
            height: 40,
            borderRadius: 16,
        },
    },
    createSaveButton: {
        height: 32,
        width: 40,
        borderRadius: '16px !important',
        borderWidth: 3,
        fontSize: '14px !important',
        textTransform: 'none !important' as any,
        fontWeight: 'bold !important',
        "&:hover": {
            borderWidth: 3,
            fontWeight: 'bold !important',
        },
    },
    success: {
        background: theme.colors.success[600],
        "&:hover, &:disabled": {
            background: `${theme.colors.success[600]} !important`,
        },
        "& > svg": {
            fill: 'white !important',
        }
    },
    failed: {
        background: theme.colors.error[600],
        "&:hover, &:disabled": {
            background: `${theme.colors.error[600]} !important`,
        },
        "& > svg": {
            fill: 'white !important',
        }
    },
    smallButton: {
        height: 24,
    },
    blocked: {
        "&:hover, &:disabled": {
            cursor: 'not-allowed !important',
        },
        cursor: 'not-allowed !important',
    }
}));

const InvalidNameNotification: React.FC<{}> = () => {
	return (<>
		<Box textAlign="center">
			<Typography component="span" fontSize="0.95rem" fontFamily="Inter">
				{'Please enter a company name.'}<br/>
				{'For DocSends, use our Chrome Extension '}
				<Typography display="inline"
					onClick={() => window.open(ChromeExtensionLink, '_blank', 'noopener, noreferrer')}
					sx={{
						cursor: 'pointer',
						color: '#fff',
						fontSize: '0.95rem',
						fontFamily: 'Inter',
						textDecoration: 'underline',
					}}>{'here'}
				</Typography>{'.'}
			</Typography>
		</Box>
	</>);
}

const ExistingNameNotification: React.FC<{}> = () => {
	return (<>
		<Box textAlign="center">
			<Typography component="span" fontSize="0.95rem" fontWeight="bold" fontFamily="Inter">
				{'Company already added'}
			</Typography>
		</Box>
	</>);
}

const ErrorNotification: React.FC<{ message: string }> = ({message}) => {
	return (<>
		<Box textAlign="center">
			<Typography component="span" fontSize="0.95rem" fontFamily="Inter">
				{message}
			</Typography>
		</Box>
	</>);
}

const DashboardsSearchOrCreate: React.FC<{
    search?: boolean,
    create?: boolean,
    save?: boolean,
    execute?: string,
    autoComplete?: boolean,
    smallFit?: boolean,
    mediumFit?: boolean,
    squareFit?: boolean,
    disabled?: boolean,
    placement?: string,
    notify?: boolean,
    flags?: any,
    onSearch?: (searchValue: string, status: string) => void,
    onPreExecute?: () => Promise<boolean>,
    onCreate?: (dashboard: Dashboard) => void,
    onSave?: (dashboard: Dashboard) => void,
    onElseExecute?: () => void,
}> = ({ search, create, save, execute, autoComplete, smallFit, mediumFit, squareFit, disabled, placement, notify, flags,
    onSearch, onPreExecute, onCreate, onSave, onElseExecute }) => {
    const classes = useStyles();
    const { user, userGroup } = useContext(AuthContext);
    const { externalSync } = useContext(GroupSettingsContext);
    const { usage, subscription } = useContext(SubscriptionContext);
    const { dashboards } = useContext(DashboardsContext);
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);
	const [invalid, setInvalid] = useState<boolean>(false);
	const [mode, setMode] = useState<string>('');
	const [blocked, setBlocked] = useState<boolean>(false);
	const [createMode, setCreateMode] = useState<boolean>(false);
	const [saveMode, setSaveMode] = useState<boolean>(false);
    const [message, setMessage] = useState<string|null>(null);
    const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false);
    const [dashboardTitle, setDashboardTitle] = useState<string>('');
    const [selectedDashboard, setSelectedDashboard] = useState<Dashboard|null>(null);

    const existing = useMemo(() =>
        !!dashboards.find(dashboard => dashboard.title.trim().toLowerCase() === dashboardTitle.trim().toLowerCase())
    ,[dashboardTitle, dashboards]);

    const getPlaceHolder = useCallback(() => {
        let placeholder = 'Enter keywords...';

        if (search) {
            if (create && !blocked) {
                placeholder = 'Search or add company...';
            } else
                placeholder = 'Search company...';
        } else if (create) {
            if (!blocked)
                placeholder = 'Company name...';
            else
                placeholder = 'Limit reached...';
        }

        return placeholder;
    }, [create, blocked, search]);

    const debounced = useDebouncedCallback((value) => {
        const searchValue = value.trim();
        const inputElement = document.createElement('input');

        inputElement.type = 'url';
        inputElement.value = searchValue;

		const invalid = inputElement.value.includes(':/');
        setDashboardTitle(searchValue);
        setInvalid(invalid);
        setSuccess(false);
        setFailed(false);
    }, 500, { maxWait: 2000 });

    const createNewDashboard = useCallback(async () => {
        setLoading(true);

        const newDashboardDetails: Dashboard = {
            createdAt: (new Date()).toISOString(),
            updatedAt: (new Date()).toISOString(),
            email: getUserEmail(user!),
            group: userGroup!,
            project: userGroup!,
            id: uuidv4(),
            title: dashboardTitle.trim(),
            documents: [],
            selections: [],
            screenshots: [],
            notes: [],
            lastUpdatedBy: "",
            shouldRefresh: false,
            shouldSyncExternally: externalSync,
            refreshData: null,
            summary: "",
            status: "New",
        };

        const isSuccess: boolean = await createDashboardFunc(newDashboardDetails);

        if (isSuccess) {
            setMode('create');
            setSuccess(true);
            setSelectedDashboard(newDashboardDetails);
        } else {
            setFailed(true);
            enqueueSnackbar(`Dashboard already exists.`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                autoHideDuration: 4000
            });
        }

        setLoading(false);
        setIsNotificationVisible(true);
        // eslint-disable-next-line
    }, [userGroup, user, externalSync, dashboardTitle]);

    const handleCreate = useCallback(async () => {
        if (dashboardTitle && !blocked) {
            const inputElement = document.createElement('input');

            inputElement.type = 'url';
            inputElement.value = dashboardTitle.trim();
            if (!inputElement.checkValidity()) {
                const proceed = await onPreExecute?.();

                if (proceed ?? true) {
                    setInvalid(false);
                    await createNewDashboard();
                } else {
                    onElseExecute?.();
                }
            } else {
                setInvalid(true);
            }
        }
    // eslint-disable-next-line
    }, [dashboardTitle, blocked, onPreExecute, onElseExecute]);

    const handleSave = useCallback(async () => {
        if (dashboardTitle && !blocked) {
            const inputElement = document.createElement('input');

            inputElement.type = 'url';
            inputElement.value = dashboardTitle.trim();
            if (!inputElement.checkValidity()) {
                const lookUpDashboard = dashboards.find(dashboard => dashboard.title.trim().toLowerCase() === dashboardTitle.trim().toLowerCase());
                const proceed = await onPreExecute?.();

                if (proceed ?? true) {
                    if (!!lookUpDashboard) {
                        setMode('save');
                        setSuccess(true);
                        setSelectedDashboard(lookUpDashboard);
                        setInvalid(false);
                    } else {
                        setFailed(true);
                        setInvalid(true);
                    }
                    setIsNotificationVisible(true);
                } else {
                    onElseExecute?.();
                }
            } else {
                setFailed(true);
                setInvalid(true);
                setIsNotificationVisible(true);
            }
        }
    }, [dashboardTitle, blocked, dashboards, onPreExecute, onElseExecute]);

    const handleReset = useCallback(() => {
        setDashboardTitle('');
        setInvalid(false);
        setSuccess(false);
        setFailed(false);
        setMode('');
        setCreateMode(false);
        setSaveMode(false);
        setMessage('');
        setDashboardTitle('');
        setSelectedDashboard(null);
    }, []);

    useEffect(() => {
        if (!isNaN(flags?.monthlyDashboardLimit) && !!subscription && !!usage)
            setBlocked((flags?.monthlyDashboardLimit > 0) && ((usage?.dashboardsCreated ?? 0) >= flags?.monthlyDashboardLimit));
    }, [flags, subscription, usage]);

    useEffect(() => {
        if (!!dashboardTitle)
            setCreateMode(!!create && !existing);
        else
            setCreateMode(false);
    }, [create, existing, dashboardTitle]);

    useEffect(() => {
        if (save && !!dashboardTitle)
            setSaveMode(existing);
        else
            setSaveMode(false);
    }, [dashboardTitle, save, existing]);

    useEffect(() => {
        onSearch?.(dashboardTitle,
			  invalid ? 'invalid'
			: failed ? 'failed'
			: success ? 'success'
			: createMode ? 'create'
			: saveMode ? 'save'
			: blocked ? 'blocked'
            : existing ? 'existing'
			: 'not-found');
    }, [dashboardTitle, invalid, failed, success, createMode, saveMode, blocked, existing, onSearch]);

    useEffect(() => {
		if (execute !== undefined) {
            if (createMode && ['mode', 'create'].includes(execute))
                handleCreate();
            else if (saveMode && ['mode', 'save'].includes(execute))
                handleSave();
            else if (execute === 'reset')
                handleReset();
		}
    // eslint-disable-next-line
    }, [createMode, saveMode, execute]);

    useEffect(() => {
      if (isNotificationVisible) {
        const timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            setIsNotificationVisible(false);
            if (!!selectedDashboard) {
                if (mode === 'save')
                    onSave?.(selectedDashboard);
                else if (mode === 'create')
                    onCreate?.(selectedDashboard);
            }
            setMode('');
        }, 3000);

        return () => {
            clearTimeout(timeoutId);
        }
      }
      // eslint-disable-next-line
    }, [isNotificationVisible, selectedDashboard]);

    return (<>
        <Tooltip open={invalid || (notify && existing) || !!message} placement="bottom" title={
				invalid ? (<InvalidNameNotification />) :
                existing ? (<ExistingNameNotification />) :
				(!!message) && (<ErrorNotification message={message} />)
            }
            componentsProps={{tooltip:{sx:{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 4,
                background: (!!message) ? 'red' : 'gray',
                maxWidth: 400,
                width: 'max-content',
                maxHeight: 64,
                height: 'fit-content',
                padding: existing ? '6px 20px' : 2,
                textAlign: 'center',
                opacity: existing ? '0.8 !important' : '1.0',
                "&::before": {
                    position: 'absolute',
                    height: 0,
                    width: 0,
                    top: '5%',
                    left: '50%',
                    borderRight: '12px solid transparent',
                    borderBottom: '12px solid gray',
                    borderLeft: '12px solid transparent',
                    content: '""',
                }
            }}}}>
                <Autocomplete
                    disableClearable
                    openOnFocus
                    autoHighlight
                    autoComplete
                    includeInputInList
                    disablePortal
                    freeSolo
                    forcePopupIcon={false}
                    open={!autoComplete ? false : undefined}
                    disabled={success || disabled}
                    value={dashboardTitle}
                    onChange={(_, option: string) => debounced(option)}
                    options={ArrayUtils.sortByAscending([...dashboards], 'title').map((dashboard: Dashboard) => dashboard.title)}
                    filterOptions={(options, { inputValue }) => options.filter(opt => inputValue ? opt?.toLowerCase().includes(inputValue.toLowerCase()) : !!opt)}
                    getOptionLabel={(option: string) => option}
                    PopperComponent={(props) => (<Popper {...props} disablePortal placement={placement as PopperPlacementType}/>)}
                    PaperComponent={(props) => (<Box {...props} className={classes.optionPaper} />)}
                    ListboxProps={{style: { maxHeight: 165, padding: 8, }}}
                    renderOption={(props: any, option: string) => (
                        <Box {...props} className={classes.optionContainer}>
                            <Typography className={classes.optionItem}>{option}</Typography>
                        </Box>
                    )}
                    sx={{ width: '100%', }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            className={classnames(
                                classes.inputField,
                                disabled && classes.disabledInputField,
                                smallFit && classes.smallInputField,
                                mediumFit && classes.mediumInputField,
                                squareFit && classes.mediumSquareInputField,
                            )}
                            placeholder={getPlaceHolder()}
                            disabled={loading || success || disabled}
                            onChange={(e) => debounced(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter')
                                    handleCreate();
                            }}
                            sx={{ width: '100%' }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start" sx={{ color: theme.palette.primary.main }}>
                                        {(createMode || !search) ? (<AddRoundedIcon />) : search && (
                                            <DashboardIcon height={20} width={20} fill={theme.palette.primary.main} />
                                        )}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end" >
										{(execute === undefined) && (<>
											{createMode && (
												<LoadingButton
													variant="contained"
													className={classnames(
														classes.createSaveButton,
														smallFit && classes.smallButton,
														blocked && classes.blocked,
														success && classes.success,
														failed && classes.failed,
													)}
													loading={loading}
													disabled={!dashboardTitle.trim() || success || failed || disabled}
													onClick={handleCreate}>
													{success ? (
														<CheckCircleOutlineIcon/>
													) : (failed) ? (
														<ErrorOutlineIcon />
													) : (
														<>{'Add'}</>
													)}
												</LoadingButton>
											)}
											{saveMode && (
												<LoadingButton
													variant="contained"
													className={classnames(
														classes.createSaveButton,
														smallFit && classes.smallButton,
														blocked && classes.blocked,
														success && classes.success,
														failed && classes.failed,
													)}
													loading={loading}
													disabled={!dashboardTitle.trim() || success || failed || disabled}
													onClick={handleSave}>
													{success ? (
														<CheckCircleOutlineIcon/>
													) : (failed) ? (
														<ErrorOutlineIcon />
													) : (
														<>{'Save'}</>
													)}
												</LoadingButton>
											)}
										</>)}
                                    </InputAdornment>
                                )}}
                        />
                    )}
                />
        </Tooltip>
    </>);
}

export default withLDConsumer()(DashboardsSearchOrCreate);