import React, { memo, useState } from "react";
import { Box, Collapse, IconButton, Stack, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDebouncedCallback } from "use-debounce";
import { AttachmentType, EmailDeal } from "../../../hooks/useEmailDealsExtraction";
import StageSelector from "../crunchbase-modal/StageSelector";
import UnassignedAttachmentSelector from "./UnassignedAttachmentSelector";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    padding: 12,
    transition: '200ms ease-in-out',
  },
  chip: {
    height: 30,
    width: 120,
    fontFamily: 'Inter',
    fontSize: '0.8rem',
    fontWeight: 500,
    backgroundColor: theme.colors.orange['50'],
    color: theme.colors.orange['500'],
  },
  icon: {
    width: 24,
    height: 24,
    fill: `${theme.colors.cyan['600']} !important`,
  },
  ingestedIcon: {
    width: 24,
    height: 24,
    color: `${theme.palette.primary.light} !important`,
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    maxWidth: '12ch',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    wordBreak: 'break-all',
    fontSize: '1rem',
    fontFamily: 'Inter',
    fontWeight: 'bold',
    color: `${theme.palette.primary.main} !important`,
    textOverflow: 'ellipsis',
    textTransform: 'none !important' as any,
    textAlign: 'left',
  },
  subtitle: {
    marginLeft: 32,
    marginBottom: 4,
    fontFamily: 'Inter !important',
    fontSize: '0.9rem !important',
    fontWeight: '500 !important',
    color: `${theme.palette.primary.light} !important`,
  },
  label: {
    fontFamily: 'Inter !important',
    fontSize: '0.9rem !important',
    fontWeight: 'bold !important',
    color: `${theme.colors.neutral['600']} !important`,
  },
  inputField: {
    minWidth: 'fit-content',
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 36,
      padding: '0 8px !important',
      borderRadius: 8,
      background: 'transparent',
      "& > input": {
        padding: 'unset !important',
        fontFamily: 'Inter !important',
        fontSize: '0.9rem',
        fontWeight: 600,
        color: theme.colors.neutral['600'],
        "&::placeholder": {
          fontFamily: 'Lato !important',
          fontWeight: 'normal !important',
        },
      },
      "& fieldset": {
        borderColor: 'darkgray !important',
      },
      "&:hover fieldset": {
        borderColor: 'black !important',
      },
      "&.Mui-focused fieldset": {
        borderWidth: '1px !important',
        borderColor: 'darkgreen !important',
      },
    },
  },
  emailField: {
    minWidth: 'fit-content',
    width: "100%",
    "& .MuiInputBase-root": {
      height: 'auto',
      padding: '8px !important',
      borderRadius: 8,
      background: 'transparent',
      "& > textarea": {
        padding: 'unset !important',
        fontFamily: 'Inter !important',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['600'],
        "&::placeholder": {
          fontFamily: 'Lato !important',
          fontWeight: 'normal !important',
        },
      },
      "& fieldset": {
        borderColor: 'darkgray !important',
      },
      "&:hover fieldset": {
        borderColor: 'black !important',
      },
      "&.Mui-focused fieldset": {
        borderWidth: '1px !important',
        borderColor: 'darkgreen !important',
      },
    },
  },
  expandIcon: {
    fill: '#666666 !important',
    alignItems: 'center !important',
    justifyContent: 'right',
    cursor: 'pointer',
  },
  cancelButton: {
    width: 100,
    height: 32,
    borderRadius: 24,
    borderColor: theme.colors.neutral['100'],
    background: theme.colors.neutral['100'],
    color: theme.colors.neutral['500'],
    fontFamily: 'Inter',
    fontSize: '0.9rem',
    fontWeight: 500,
    textTransform: 'none',
    transition: 'ease-in-out 300ms',
  },
  saveButton: {
    width: 80,
    height: 32,
    borderRadius: 24,
    backgroundColor: theme.colors.primary['600'],
    color: 'white',
    fontFamily: 'Inter',
    fontSize: '0.9rem',
    fontWeight: 500,
    textTransform: 'none',
    transition: 'ease-in-out 300ms',
    '&:hover': {
      backgroundColor: theme.colors.primary['500'],
    }
  },
  lastUpdatedDate: {
    color: theme.colors.neutral['600'],
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
  },
  expandedIcon: {
    width: 40,
    height: 40,
    padding: 'unset',
  },
}));

const UnassignedEmailDeal: React.FC<{
  emailDeal: EmailDeal,
  attachments: AttachmentType[],
  onChange?: (emailDeal: EmailDeal) => void,
}> = ({ emailDeal, attachments, onChange }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  const debouncedName = useDebouncedCallback(name => onChange?.({...emailDeal, name }), 300, { maxWait: 500 });
  const debouncedWebsite = useDebouncedCallback(website => onChange?.({...emailDeal, website }), 300, { maxWait: 500 });
  const debouncedDeck = useDebouncedCallback(deck => onChange?.({...emailDeal, deck }), 200, { maxWait: 300 });

  return (<>
    <Stack spacing={1} width="100%">
      <Collapse in={expanded} collapsedSize={40}>
        <Stack direction="column" className={classes.container} alignItems="stretch" justifyContent="flex-start" mt="-4px !important">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
              <Typography className={classes.title}>{emailDeal.name}</Typography>
              {expanded && (<>
                <Box />
                <StageSelector
                  defaultStage={emailDeal.stage}
                  onSelectStage={(stage) => onChange?.({...emailDeal, stage})}
                  filled />
              </>)}
              <IconButton className={classes.expandedIcon}
                onClick={(e) => {
                    e.stopPropagation();
                    setExpanded(prev => !prev);
                }} disableRipple
              > <ExpandMoreIcon style={{ transform: !expanded ? 'none' : 'rotate(180deg)' }} />
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="column" spacing={1} alignItems="stretch" py={1}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
              <Typography className={classes.label}>{'Name:'}</Typography>
              <TextField
                variant="outlined"
                className={classes.inputField}
                placeholder="Enter company name..."
                defaultValue={emailDeal.name}
                onChange={(e) => debouncedName(e.target.value as string)}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
              <Typography className={classes.label}>{'Website:'}</Typography>
              <TextField
                variant="outlined"
                className={classes.inputField}
                placeholder="Enter website URL..."
                defaultValue={emailDeal.website}
                onChange={(e) => debouncedWebsite(e.target.value as string)}
              />
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" width="100%">
              <Typography className={classes.label}>{'Deck:'}</Typography>
              <UnassignedAttachmentSelector
                attachments={attachments}
                defaultAttachment={emailDeal.deck}
                onSelect={(deck) => debouncedDeck(deck)} />
            </Stack>
          </Stack>
        </Stack>
      </Collapse>
    </Stack>
  </>);
}

export default memo(UnassignedEmailDeal);
