import React, { Fragment, useCallback, useContext } from "react";
import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { CompanyFile, Dashboard } from "../../../types/files";
import awsExports from "../../../aws-exports";
import {DashboardsContext} from "../../../contexts/DashboardsContext";
import {FileStructureContext} from "../../../contexts/FileStructureContext";
import useDashboard from "../../../hooks/useDashboard";

const { app_domain } = awsExports;

const useStyles = makeStyles(() => ({
    link: {
        fontFamily: 'Inter !important',
        fontSize: '0.9rem !important',
        fontStyle: 'normal !important',
        textDecoration: 'underline',
        color: '#0e0e0e',
    },
}));

const CompanyInfoAnswers: React.FC<{
    dashboard: Dashboard,
    title: string,
 }> = ({ dashboard, title }) => {
    const classes = useStyles();
    const { fileStructure } = useContext(FileStructureContext);
    const { mappedOverviewQueries } = useContext(DashboardsContext);
    const { sharePublicly } = useDashboard();

    const handlePublicLink = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        sharePublicly(true, dashboard).then(() => {
            window.open(`https://${app_domain}/dashboards/public/${dashboard.id}`, '_blank', 'noopener, noreferrer');
        });
    }, [dashboard, sharePublicly]);

    const handlePublicDeck = useCallback((e: React.MouseEvent<HTMLSpanElement, MouseEvent>, selectedFile: CompanyFile) => {
        e.stopPropagation();
        e.preventDefault();
        sharePublicly(true, dashboard).then(() => {
            window.open(`https://${app_domain}/dashboards/public/${dashboard.id}/files/${selectedFile.id}`, '_blank', 'noopener, noreferrer');
        });
    }, [dashboard, sharePublicly]);

    switch (title) {
        case 'Description':
            return (() => {
                const descriptionAnswer = mappedOverviewQueries.get(`${dashboard.id}:Digest`)?.[0];
                const websiteAnswer = mappedOverviewQueries.get(`${dashboard.id}:Website`);

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color="black" fontSize="0.95rem" fontWeight="bold">
                            {`${dashboard.title} `}
                            <span>{'('}</span>
                            <Typography component="a"
                                className={classes.link}
                                href={websiteAnswer}
                                target="_blank"
                                rel="noopener noreferrer"
                                display="inline">
                                {'website'}
                            </Typography>
                            <span>{'): '}</span>
                            <Typography component="span" fontSize="0.95rem" color="black" display="inline">
                                {descriptionAnswer}
                            </Typography>
                        </Typography>
                    </Stack>
                </>);
            })();
        case 'Stage':
            return (() => (<>
                {!!dashboard?.investmentStage && (
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color="black" fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.95rem" color="black" display="inline">
                                {dashboard?.investmentStage}
                            </Typography>
                        </Typography>
                    </Stack>
                )}</>))();
        case 'Sector':
            return (() => (<>
                {!!dashboard?.tags?.length && (
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color="black" fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.95rem" color="black" display="inline">
                                {dashboard?.tags?.join(' - ')}
                            </Typography>
                        </Typography>
                    </Stack>
                )}</>))();
        case 'Team':
            return (() => {
                const keyPeople = mappedOverviewQueries.get(`${dashboard.id}:Key People`);

                if (Array.isArray(keyPeople) && !!keyPeople.length) {
                    return (<>
                        <Stack alignItems="baseline" justifyContent="center">
                            <Typography color="black" fontSize="0.95rem" fontWeight="bold">
                                {`${title}: `}
                                <Typography component="span" fontSize="0.95rem" color="black" display="inline">
                                    {(keyPeople.map((element: any, i) => (
                                        <Fragment key={'company-info-answers-100-' + i}>
                                            {!!element?.title?.split('|')?.[0] && (<>
                                                <Typography component="a"
                                                    className={classes.link}
                                                    href={element?.link || '#'}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    display="inline">
                                                    {element?.title?.split('|')?.[0]?.split(' - ')?.[0]}
                                                </Typography>
                                                {(keyPeople.length > (i + 1)) && (<span>{', '}</span>)}
                                            </>)}
                                        </Fragment>
                                    )))}
                                </Typography>
                            </Typography>
                        </Stack>
                    </>);
                }

                return (<></>);
            })();
        case 'Deck':
            return (() => {
                const selectedFile = fileStructure.find(file => !file.isDirectory && file.dashboardId === dashboard.id && file.isDeck);

                if (!!selectedFile) {
                    return (<>
                        <Stack alignItems="baseline" justifyContent="center">
                            <Typography color="black" fontSize="0.95rem">
                                {`${title} `}
                                <Typography component="span"
                                    className={classes.link}
                                    onClick={(e) => handlePublicDeck(e, selectedFile)}
                                    sx={{ cursor: 'pointer' }}
                                    display="inline">
                                    {'here'}
                                </Typography>
                                {'.'}
                            </Typography>
                        </Stack>
                    </>);
                }

                return (<></>);
            })();
        case 'Deal overview':
            return (() => (<>
                <Stack alignItems="baseline" justifyContent="center">
                    <Typography color="black" fontSize="0.95rem">
                        {`${title} `}
                        <Typography component="span"
                            className={classes.link}
                            onClick={handlePublicLink}
                            sx={{ cursor: 'pointer' }}
                            display="inline">
                            {'here'}
                        </Typography>
                        {'.'}
                    </Typography>
                </Stack>
            </>))();
        default:
            return (() => {
                const fullWordAnswer = mappedOverviewQueries.get(`${dashboard.id}:${title}`) as string;

                return (<>
                    <Stack alignItems="baseline" justifyContent="center">
                        <Typography color="black" fontSize="0.95rem" fontWeight="bold">
                            {`${title}: `}
                            <Typography component="span" fontSize="0.95rem" color="black" display="inline">
                                {fullWordAnswer}
                            </Typography>
                        </Typography>
                    </Stack>
                </>);
            })();
    }
}

export default CompanyInfoAnswers;
