import React, { useRef, useState } from "react";
import { IconButton, Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import theme from "../../../theme";

const useStyles = makeStyles(() => ({
    title: {
        width: "fit-content",
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 4,
        paddingLeft: 4,
        paddingRight: 4,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
    },
    answerHover: {
        width: 'auto',
        marginRight: 2,
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    icon: {
        width: 32,
        height: 32,
        padding: 'unset',
    },
    ownership: {
        fontFamily: 'Inter',
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['500'],
    },
    owner: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 600,
        color: theme.palette.primary.light,
    },
    inputField: {
        padding: 8,
        "& .MuiInputBase-root": {
            width: '100%',
            padding: 'unset',
            "& > input": {
                fontFamily: 'Inter',
                fontSize: '1.1rem',
                fontWeight: 600,
                color: theme.palette.primary.light,
            },
        },
    },
}));

const DashboardDealEditor: React.FC<{
    label: string,
    currentValue?: string | null,
    readOnly?: boolean,
    onUpdate?: (newValue: string) => void,
}> = ({ label, currentValue, readOnly, onUpdate }) => {
    const classes = useStyles();
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | undefined | null>(undefined);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const nameRef = useRef<TextFieldProps>(null);

    return (<>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
            <Typography className={classes.ownership}>
                {label}
            </Typography>
            {!isEditingAnswer ? (
                <Stack direction="row" alignItems="center" justifyContent="space-between"
                    className={classNames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                    onMouseLeave={() => setHoverAnswer(false)}>
                        <Stack className={classes.answerBlock}
                            onMouseEnter={() => setHoverAnswer(!readOnly)}
                            style={{ height: 'auto' }}>
                            <Typography className={classes.owner}>
                                {currentValue ?? 'N/A'}
                            </Typography>
                        </Stack>
                    <Stack direction="column" alignItems="center" justifyContent="center" width="40px">
                        {hoverAnswer && (
                            <IconButton size="small"
                                className={classes.icon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsEditingAnswer(true);
                                    setEditAnswer(currentValue);
                                }}
                            > <EditOutlinedIcon fontSize="small" /> </IconButton>
                        )}
                    </Stack>
                </Stack>
            ) : (
                <Stack direction="row" alignItems="center" justifyContent="space-between" minWidth={150} maxWidth={250}>
                    <TextField variant="standard"
                        className={classNames('no-border', classes.inputField)}
                        inputProps={{ style: { height: '100%' }, className: 'no-fade' }}
                        defaultValue={editAnswer || 'N/A'}
                        inputRef={nameRef}
                        autoFocus
                    />
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            onUpdate?.(nameRef.current?.value as string);
                            setIsEditingAnswer(false);
                            setEditAnswer(undefined);
                        }}>
                            <CheckIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={(e) => {
                            e.stopPropagation();
                            setIsEditingAnswer(false);
                            setEditAnswer(undefined);
                        }}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Stack>
            )}
        </Stack>
    </>);
}

export default DashboardDealEditor;