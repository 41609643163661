import { Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddInvestor from "./AddInvestor";
import InBulkCSV from "./InBulkCSV";

const useStyles = makeStyles((theme) => ({
    image: {
        width: 150,
        height: 120,
    },
    smallImage: {
        width: 80,
        height: 'auto',
    },
    mainText: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    mainLabel: {
        fontFamily: 'Inter',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['800'],
    },
    subText: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 400,
        color: theme.colors.neutral['700'],
        textAlign: 'center',
    },
    otherText: {
        fontFamily: 'Lato',
        fontSize: '1.1rem',
        fontWeight: 'normal',
        color: theme.colors.neutral['600'],
        textAlign: 'center',
    },
    bulkIcon: {
        width: 24,
        height: 24,
    },
    bulkAddButton: {
        minWidth: 210,
        borderRadius: 24,
        fontFamily: 'Lato',
        fontSize: '0.95rem',
        fontWeight: 'bold !important',
        textTransform: 'none !important' as any,
        transition: 'ease-in-out 300ms',
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
}));

const NoWorkspacesFoundView: React.FC<{
    noResult?: boolean,
    isPublic?: boolean,
    onCreateModalOpen?: (open: boolean) => void,
}> = ({ noResult, isPublic, onCreateModalOpen }) => {
    const classes = useStyles();

    return (<>
        {noResult ? (<>
            <Stack alignItems="center" justifyContent="center" width="100%" height="calc(100vh - 300px)">
                <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.image} />
                {isPublic ? (<>
                    <Typography className={classes.mainText} mt={4}>{'No investors yet!'}</Typography>
                    <Stack alignItems="center" justifyContent="center" width="100%">
                        <Typography className={classes.otherText}>
                            {'Add investors from your network so you can easily share deals with them based on their preferences.'}
                        </Typography>
                    </Stack>
                </>) : (<>
                    <Typography className={classes.mainText} mt={4}>{'No investors found!'}</Typography>
                </>)}
            </Stack>
        </>) : (<>
            <Stack alignItems="center" spacing={1} justifyContent="flex-start" width="100%" height="calc(100vh - 300px)" mt={5}>
                <Stack direction="row" spacing={3} alignItems="center">
                    <img src="../../images/missing-dashboard.png" alt="missing-dashboard" className={classes.smallImage} />
                    <Typography className={classes.mainLabel}>{'No investors yet.'}</Typography>
                </Stack>
                <Typography className={classes.subText} color="#666666 !important">
                    {'There are several ways you can add new investors: '}
                </Typography>
                <Stack spacing={2} pt={2}>
                    <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" width="100%">
                        <AddInvestor onModalOpen={onCreateModalOpen} />
                        <InBulkCSV />
                    </Stack>
                </Stack>
            </Stack>
        </>)}
    </>);
}

export default NoWorkspacesFoundView;