import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

import useDashboardQuery from "../../../hooks/useDashboardQuery";
import EmptyQueryContainer from "../dashboard-query-container/EmptyQueryContainer";
import {DashboardContext} from "../../../contexts/DashboardContext";
import { AnsweredQuestion } from "../../../contexts/DashboardQueriesContext";

const MarketSizeOverviewQuery: React.FC<{}> = () => {
    const { dashboard } = useContext(DashboardContext);
    const { queryAnswer } = useDashboardQuery('Market Size & Overview');
    const [answeredQuestion, setAnsweredQuestion] = useState<AnsweredQuestion|undefined>(undefined);

    useEffect(() => {
        if (!!queryAnswer.answeredQuestion) {
            queryAnswer.answeredQuestion.question = `${dashboard?.title!} Market Size Breakdown`;
            setAnsweredQuestion(queryAnswer.answeredQuestion);
        }
    }, [dashboard, queryAnswer]);

    return (<>
        <EmptyQueryContainer queryAnswer={queryAnswer}>
            <ReactMarkdown
                children={answeredQuestion?.answer as string}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeKatex]} />
        </EmptyQueryContainer>
    </>);
};

export default MarketSizeOverviewQuery;