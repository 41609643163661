import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Stack } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";
import QueryFieldSelection from "../../atoms/multi-action/QueryFieldSelection";
import { Fragment, useContext, useMemo } from "react";
import useBulkDashboards from "../../../hooks/useBulkDashboards";
import NoDashboardsFoundView from "../../molecules/dashboards-empty/NoDashboardsFoundView";
import { scrollbarStyle } from "../../../shared/dashboard";
import { collectionKey } from "../../organisms/dashboards/DashboardsView";
import CompanyInfoAnswers from "../../molecules/dashboard-query-answer/CompanyInfoAnswers";
import {DashboardsContext,  CompanyInfoFields } from "../../../contexts/DashboardsContext";
import { FallbackLoading } from "../../templates/loader";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            minWidth: 800,
            maxHeight: 600,
            width: 'min-content',
            height: 'fit-content',
            padding: 20,
            borderRadius: 16,
        },
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.5rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'auto',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    commonLayoutBlock: {
        height: '100%',
        overflow: 'hidden',
    },
    answerBlock: {
        width: '100%',
        height: 'auto',
        marginTop: '8px !important',
        paddingBottom: '4px !important',
        color: '#666666',
        overflowY: 'auto',
        cursor: 'text',
        ...scrollbarStyle,
    },
    borderedAnswerBlock: {
        padding: '16px',
        border: `1px ${theme.colors.neutral['400']} solid`,
        borderRadius: 12,
    },
    cancelButton: {
        width: 100,
        borderRadius: 40,
        background: theme.colors.neutral['100'],
        color: theme.colors.neutral['600'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
    copyButton: {
        width: 100,
        borderRadius: 20,
        background: theme.palette.primary.main,
        color: 'white',
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const CopyDealSnippetsModal: React.FC<{
    isOpen: boolean,
    queryTitles: string[],
    loading?: boolean,
    onQueryTitles: React.Dispatch<React.SetStateAction<string[]>>,
    onCopy?: () => void,
    onClose: () => void,
}> = ({ isOpen, queryTitles, loading, onQueryTitles, onCopy, onClose }) => {
    const classes = useStyles();
    const { dashboardsQueries } = useContext(DashboardsContext);
    const { getBulkDashboards } = useBulkDashboards();

    const bulkDashboards = useMemo(() =>
        getBulkDashboards(collectionKey).map(exportable => exportable.dashboard)
    , [getBulkDashboards]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                {'Copy deal snippets'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <QueryFieldSelection queryTitles={queryTitles} onQueryTitles={onQueryTitles} />
                <Box className={classes.answerBlock}>
                    {!!bulkDashboards.length ? (
                        <Stack direction="column" className={classes.borderedAnswerBlock} spacing={1} height="100%">
                            {!dashboardsQueries.length ? (<FallbackLoading height="200px" />) : (
                                bulkDashboards.map((dashboard, i) => (
                                    <Stack direction="column" alignItems="flex-start" justifyContent="center"
                                        key={'copy-deal-snippers-modal-114-' + i}>
                                        {CompanyInfoFields.map((displayTitle, i) => (
                                            <Fragment key={'copy-deal-snippers-modal-124-' + i}>
                                                {queryTitles.includes(displayTitle) && (
                                                    <CompanyInfoAnswers dashboard={dashboard} title={displayTitle} />
                                                )}
                                            </Fragment>
                                        ))}
                                    </Stack>
                                ))
                            )}
                        </Stack>
                    ) : (<NoDashboardsFoundView noResult />)}
                </Box>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end" width="100%">
                    <Button className={classes.cancelButton}
                        onClick={onClose}> {'Cancel'} </Button>
                    <LoadingButton variant="contained"
                        className={classes.copyButton}
                        loading={loading}
                        onClick={() => onCopy?.()}
                    > {'Copy'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default CopyDealSnippetsModal;