import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from "react";
import { AttachmentType } from "../../../hooks/useEmailDealsExtraction";
import PdfIcon from "../../../assets/icons/pdf-icon.svg";
import AttachmentIcon from "../../../assets/icons/attachment.svg";

export type ContentType = {
  name: string;
  mimeType: string;
  icon: string;
};

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: "Inter",
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: theme.colors.neutral["600"],
  },
  formContainer: {
    width: "100%",
    "& > .MuiInputBase-root": {
      height: 36,
      paddingLeft: 'unset !important',
      borderRadius: 8,
      borderWidth: 1,
      background: 'transparent',
      "& > .MuiSelect-select": {
        padding: 'unset !important',
      },
      "& > .MuiSvgIcon-root": {
        width: 24,
        height: 24,
      },
      "& fieldset": {
        borderColor: 'darkgray !important',
      },
      "&:hover fieldset": {
        borderColor: 'black !important',
      },
      "&.Mui-focused fieldset": {
        borderWidth: '1px !important',
        borderColor: 'darkgreen !important',
      },
    },
  },
  attachmentIcon: {
    height: 24,
    width: 24,
  },
  attachmentName: {
    display: 'flow',
    maxWidth: '24ch',
    overflow: 'hidden',
    fontFamily: 'Inter !important',
    fontSize: '0.9rem !important',
    fontWeight: '500 !important',
    color: theme.colors.neutral['600'],
    textOverflow: 'ellipsis',
    textTransform: 'none !important' as any,
    textAlign: 'left',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
  },
}));

const ContentTypes: ContentType[] = [
  { name: 'PDF', mimeType: 'application/pdf', icon: PdfIcon },
  { name: 'URL', mimeType: 'text/plain', icon: AttachmentIcon },
];

const UnassignedAttachmentSelector: React.FC<{
  attachments: AttachmentType[],
  defaultAttachment?: AttachmentType,
  onSelect?: (attachment: AttachmentType) => void,
}> = ({ attachments, defaultAttachment, onSelect }) => {
  const classes = useStyles();
  const [index, setIndex] = useState<string>('');

  const handleSelect = useCallback((event: SelectChangeEvent) => {
    const index = event.target.value as string;

    setIndex(index);
    onSelect?.(attachments[Number(index)]);
  }, [attachments, onSelect]);

  useEffect(()=>{
    if (!!defaultAttachment) {
      const lookUpIndex = attachments.findIndex(attached => attached.name === defaultAttachment.name);

      if (lookUpIndex !== -1)
        setIndex(String(lookUpIndex));
    }
  }, [attachments, defaultAttachment]);

  return (<>
    <FormControl className={classes.formContainer} variant="outlined">
      <Select value={index} onChange={handleSelect} displayEmpty>
        {attachments.map((attachment, i) => (
          <MenuItem value={String(i)}
            style={{ minHeight: 'unset', padding: 8 }}
            key={'attachment-selector-90-' + i}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start" width="100%" pl={1}>
              <img src={ContentTypes.find((type) => type.mimeType === attachment.contentType)?.icon || ''} className={classes.attachmentIcon} alt="" />
              <Typography className={classes.attachmentName}>{attachment.name}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </>);
}

export default UnassignedAttachmentSelector;
