import { useCallback, useContext } from 'react';
import { Dashboard, RefreshDataEnum } from '../types/files';
import { NoInfoYet } from '../types/search';
import { updateDashboardFunc } from '../lib/helper';
import awsExports from '../aws-exports';
import {DashboardsContext,  CompanyInfoFields } from '../contexts/DashboardsContext';
import {FileStructureContext} from '../contexts/FileStructureContext';
import useBulkDashboards from './useBulkDashboards';
import useContentCopy, { ContentCopyType } from './useContentCopy';

const { app_domain } = awsExports;

const useExportCopy = () => {
  const { fileStructure } = useContext(FileStructureContext);
  const { mappedOverviewQueries } = useContext(DashboardsContext);
  const { getBulkDashboards } = useBulkDashboards();
  const { copyFormattedContents } = useContentCopy();

  const anchorElem = (url: string, value: string) => (`<a href="${url}" target="_blank" rel="noopener noreferrer">${value}</a>`);

  const formatNewline = () => ({ type: 'paragraph', data: { text: '<br/>', },});

  const formatRaw = (rawHTML: string) => ({ type: 'paragraph', data: { text: rawHTML, } });

  const formatField = (title: string, value: string) => ({ type: 'paragraph', data: { text: `<span>${title}:&nbsp;${value}</span>`, } });

  const formatFooter = useCallback(() => ({ type: 'paragraph', data: { text: `<span>Generated by ${anchorElem('https://notissia.com', 'Notissia')}.</span>`, } }), []);

  const formatQueryContents = useCallback(async (dashboard: Dashboard, infoTitles: string[]): Promise<ContentCopyType[]> =>
    await Promise.resolve(CompanyInfoFields
      .filter(displayTitle => infoTitles.includes(displayTitle))
      .reduce((content: ContentCopyType[], displayTitle: string) => {
        const title = (displayTitle === 'Description') ? 'Digest' : displayTitle;

        switch (title) {
          case 'Digest': (() => {
            const descriptionAnswer = mappedOverviewQueries.get(`${dashboard.id}:Digest`)?.[0];
            const websiteAnswer = mappedOverviewQueries.get(`${dashboard.id}:Website`);

            content.push(formatField(`<b>${dashboard.title}</b>&nbsp;(${anchorElem(websiteAnswer, 'website')})`, descriptionAnswer));
          })(); break;
          case 'Stage': (() => {
            if (!!dashboard?.investmentStage)
              content.push(formatField(`${title}`, dashboard?.investmentStage));
          })(); break;
          case 'Sector': (() => {
            if (!!dashboard?.tags?.length)
              content.push(formatField(`${title}`, dashboard?.tags?.join(' - ')));
          })(); break;
          case 'Team': (() => {
            const keyPeople = mappedOverviewQueries.get(`${dashboard.id}:Key People`);

            if (Array.isArray(keyPeople) && !!keyPeople.length) {
              content.push(formatField(`${title}`,
                keyPeople.map((element: any) => {
                  if (!!element?.title?.split('|')?.[0])
                    return anchorElem(element?.link, element?.title?.split('|')?.[0]?.split(' - ')?.[0]);

                  return ('');
                }).join(', ')
              ));
            }
          })(); break;
          case 'Deck': (() => {
            const selectedFile = fileStructure.find(file => !file.isDirectory && file.dashboardId === dashboard.id && file.isDeck);
            const publicUrl = `https://${app_domain}/dashboards/public/${dashboard.id}/files/${selectedFile?.id}`;

            if (!!selectedFile)
              content.push(formatRaw(`<span>${title}&nbsp;${anchorElem(publicUrl, 'here')}.</span>`));
          })(); break;
          case 'Deal overview': (() => {
            const publicUrl = `https://${app_domain}/dashboards/public/${dashboard.id}`;

            content.push(formatRaw(`<span>${title}&nbsp;${anchorElem(publicUrl, 'here')}.</span>`));
          })(); break;
          default: (() => {
            const answer = mappedOverviewQueries.get(`${dashboard.id}:${title}`);

            content.push(formatField(`<b>${title}</b>`, answer || NoInfoYet.answer));
          })(); break;
        }

        return content;
      }, []) as ContentCopyType[])
  , [fileStructure, mappedOverviewQueries]);

  const copyDashboard = useCallback(async (dashboard: Dashboard, infoTitles: string[]): Promise<boolean> => {
    const contents = await formatQueryContents(dashboard, infoTitles);

    if (Array.isArray(contents)) {
      await updateDashboardFunc({
        ...dashboard,
        isPublic: true,
        refreshData: {
          lastUpdatedBy: '',
          shouldRefresh: true,
          modifiedData: RefreshDataEnum.Public,
        }
      });
      copyFormattedContents([...contents, formatNewline(), formatFooter(), formatNewline()]);

      return true;
    }

    return false;
  }, [copyFormattedContents, formatFooter, formatQueryContents]);

  const copyBulkDashboards = useCallback(async (collection: string, queryTitles: string[]) =>
    await new Promise<boolean>((resolve) => {
      const sharePromises: Promise<any>[] = [];
      const formatPromises: Promise<ContentCopyType[]>[] = [];
      const bulkContents: ContentCopyType[] = [];
      const bulkDashboards = getBulkDashboards(collection);

      bulkDashboards.forEach(exportable => {
        sharePromises.push(updateDashboardFunc({
          ...exportable.dashboard,
          isPublic: true,
          refreshData: {
            lastUpdatedBy: '',
            shouldRefresh: true,
            modifiedData: RefreshDataEnum.Public,
          }
        }));
        formatPromises.push(formatQueryContents(exportable.dashboard, queryTitles));
      }, []);

      Promise.all(sharePromises).then(() => {
        Promise.all(formatPromises).then((formattedContentsData) => {
          formattedContentsData.forEach(contents =>
            bulkContents.push(...contents, formatNewline())
          );
          copyFormattedContents([...bulkContents, formatNewline(), formatFooter(), formatNewline()]);
        });
      }).catch(() => resolve(false))
        .finally(() => resolve(true));
  }), [copyFormattedContents, formatFooter, formatQueryContents, getBulkDashboards]);

  return { copyDashboard, copyBulkDashboards };
};

export default useExportCopy;