import React, { useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Button, Stack, Typography } from "@mui/material";
import classnames from "classnames";
import DashboardTagModal from "../../modals/dashboards-overview/DashboardTagModal";
import CompaniesMultiAction from "../multi-action/CompaniesMultiAction";

const useStyles = makeStyles((theme) => ({
    row: {
        minWidth: '100%',
        width: 'fit-content',
        height: 'fit-content',
        borderBottom: `1px solid ${theme.colors.neutral['400']}`,
        background: `#fff`,
    },
    header: {
        fontFamily: 'Inter',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        color: theme.colors.neutral['900'],
    },
    checkbox: {
        minWidth: 24,
        maxWidth: 24,
    },
    cell: {
        minWidth: 180,
        maxWidth: 180,
        minHeight: 60,
        maxHeight: 60,
        padding: '0 10px',
    },
    narrowCell: {
        minWidth: 150,
        maxWidth: 150,
    },
    narrowerCell: {
        minWidth: 50,
        maxWidth: 50,
    },
    wideCell: {
        minWidth: 280,
        maxWidth: 280,
    },
    widerCell: {
        minWidth: 380,
        maxWidth: 380,
    },
    fullWidthCell: {
        minWidth: 0,
        maxWidth: 'unset !important',
        width: '100%',
    },
    hiddenCell: {
        display: 'none',
    },
    endCell: {
        minWidth: 4,
        maxWidth: 4,
        padding: 'unset',
    },
    addSector: {
        minWidth: 'unset',
        fontFamily: 'Inter',
        fontSize: '0.95rem',
        color: theme.palette.primary.light,
        textTransform: 'none',
        "&:hover": {
            background: 'unset',
        }
    },
}));

export const TableHeaders = ['Name', 'Website', 'Fit score', 'Stage', 'Sectors', 'Description', 'Data from...', 'Deal owner', 'Fundraising', 'Revenue', 'Valuation', 'Margins', '', 'Added', ''];

const DashboardsTableHeader: React.FC<{ columns: string[], multiAction?: boolean, withFitScore?: boolean }> = ({ columns, multiAction, withFitScore }) => {
    const classes = useStyles();
    const [manageTagsOpenModal, setManageTagsOpenModal] = useState<boolean>(false);
    const multiActionRef = useRef<any>(null);

    const showColumn = (column: string) => columns.join(';').includes(column);

    return (<>
        <Stack direction="row" className={classes.row} alignItems="flex-start" justifyContent="flex-start" ref={multiActionRef}>
            <Stack className={classes.checkbox} />
            {TableHeaders.map((header, i, self) => showColumn(header) && (
                <Stack direction="column" className={classnames(classes.cell,
                    (i === 2) && !withFitScore && classes.hiddenCell,
                    (i === 4) && classes.wideCell,
                    (i === 5) && classes.widerCell,
                    (i === self.length - 3) && classes.fullWidthCell,
                    (i === self.length - 2) && classes.narrowCell,
                    (i === self.length - 1) && classes.narrowerCell)}
                    alignItems="flex-start" justifyContent="center" key={'dashboads-table-header-74-' + i}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                        <Typography className={classes.header}>{header}</Typography>
                        {!withFitScore && (header === 'Sectors') && (
                            <Button component="text"
                                className={classes.addSector}
                                onClick={() => setManageTagsOpenModal(true)}
                                disableRipple> {'Add...'} </Button>
                        )}
                    </Stack>
                </Stack>
            ))}
            <Stack className={classnames(classes.cell, classes.endCell)} />
        </Stack>
        {multiAction && (<CompaniesMultiAction ref={multiActionRef} />)}
        {manageTagsOpenModal && (
            <DashboardTagModal
                open={manageTagsOpenModal}
                onClose={() => setManageTagsOpenModal(false)}
                forManagement
            />
        )}
    </>);
}

export default DashboardsTableHeader;