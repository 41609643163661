import { Divider, Stack, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useMemo } from 'react';
import { scrollbarStyle } from '../../../shared/dashboard';
import React from 'react';
import ArrayUtils from '../../../utils/ArrayUtils';
import moment from 'moment';

type CategorySummary = {
    [date: string]: {
        id: string;
        title: string;
        value: number;
    }[];
};

const useStyles = makeStyles(() => ({
    cardsGroupBlock: {
        height: '250px',
        overflowY: 'auto',
        borderRadius: '10px',
        marginTop: '8px',
        ...scrollbarStyle,
    },
}));

const CompanySummary: React.FC<{
    data: Array<{dashboardId: string, dashboardTitle: string, date: string, value: number}>,
    months: number,
    unit: string,
}> = ({ data, months, unit }) => {
    const classes = useStyles();

    const summaryInfo: CategorySummary = useMemo(() => {
        const groupedData = [...ArrayUtils.sortByDescending(data, 'date')]
            .reduce((group, item) => {
                if (moment().diff(moment(item.date), 'months') < months)  {
                    group[item.date] = group[item.date] ?? [];
                    group[item.date].push({id: item.dashboardId, title: item.dashboardTitle, value: item.value});
                }

                return group;
            }, Object.create(null));

        for (const date in groupedData)
            groupedData[date].sort((a: {title: string}, b: {title: string}) => a.title.localeCompare(b.title));

        return groupedData;
    }, [data, months]);

    const formatNumber = useCallback((value: number|null, unit?: string) => {
        if (value === null)
            return 'N/A';

        let cleanInput = String(value!).replace(/[^0-9bmkt.$%-]/gi, '').toLowerCase();
        let multiplier = 1;

        if (cleanInput.includes('t'))
            multiplier = Math.pow(10, 12);
        else if (cleanInput.includes('b'))
            multiplier = Math.pow(10, 9);
        else if (cleanInput.includes('m'))
            multiplier = Math.pow(10, 6);
        else if (cleanInput.includes('k'))
            multiplier = 1000;

        const numericValue = multiplier * parseFloat(cleanInput.replace(/[^0-9.-]/g, ''));

        if (unit === '%')
            return `${numericValue.toFixed(1)}%`;
        else if (unit === '$')
            return `${numericValue < 0 ? '-' : ''}$${Math.abs(numericValue).toFixed(0)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return `${numericValue}`;
    }, []);


    return (<>
        <Typography fontSize="1.1rem" fontWeight="bold" color="#7bd4d4">Companies:</Typography>
        <Stack spacing={2} className={classes.cardsGroupBlock} sx={{pl: '8px'}}>
            {Object.entries(summaryInfo).map(([dateKey, values]) => (
                <Stack direction="column" spacing={1}>
                    <Typography fontSize="0.9rem" color="darkgray">{moment(dateKey).format('MMM YYYY')}</Typography>
                    <Divider />
                    {values.map(value => (
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Typography
                                component="a"
                                href={"/dashboards/" + value.id}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    color: '#048290',
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    textDecoration: 'none',
                                    "&:hover": {
                                        textDecoration: 'none !important',
                                    }
                                }}
                            > {value.title} </Typography>
                            <Typography fontSize="1rem" fontWeight="bold" color="#666666">{formatNumber(value.value, unit)}</Typography>
                        </Stack>
                    ))}
                </Stack>
            ))}
        </Stack>
    </>);
}

export default CompanySummary;