import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, Container, Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import IntegrationIcons from "../atoms/IntegrationIcons";
import InvestorsTable from "../organisms/investors/InvestorsTable";
import CreateEditInvestorModal from "../modals/investors/CreateEditInvestorModal";
import BulkAddWorkspacesModal from "../modals/home/BulkAddWorkspacesModal";
import WorkspacesSearchOrCreate from "../molecules/workspaces-search-create/WorkspacesSearchOrCreate";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        height: '100%',
        maxWidth: 'unset !important',
        overflowY: 'hidden',
    },
    createButton: {
        width: 'auto',
        height: 40,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        background: '#E0F1F1',
        color: theme.palette.primary.main,
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
        transition: 'ease-in-out 300ms',
    },
    label: {
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 500,
    },
    sublabel: {
        marginLeft: 4,
        fontFamily: 'Lato',
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.colors.neutral['500'],
    },
}));

export const collectionKey = 'investors';

const InvestorsPage: React.FC<{}> = () => {
    const classes = useStyles();
    const [search, setSearch] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [createWorkspaceModalOpen, setCreateWorkspaceModalOpen] = useState<boolean>(false);
    const [bulkAddWorkspaceModalOpen, setBulkAddWorkspaceModalOpen] = useState<boolean>(false);

    return (<>
        <Container className={classes.rootContainer}>
            <Stack direction="column" spacing={2} pt={4} pb={2}>
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="h4-bold">{`Investors`}</Typography>
                    <Box width="600px">
                        <WorkspacesSearchOrCreate search onSearch={(value) => setSearch(value)} />
                    </Box>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
                        <Box>
                            <Button variant="outlined"
                                className={classes.createButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setAnchorEl(e.currentTarget);
                                }}
                            >{`+  Add an investor`} </Button>
                            <Menu open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                onClose={() => setAnchorEl(null)}>
                                <MenuItem className={classes.label}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setAnchorEl(null);
                                        setCreateWorkspaceModalOpen(true);
                                    }}> {'Single investor'} </MenuItem>
                                <MenuItem className={classes.label}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setAnchorEl(null);
                                        setBulkAddWorkspaceModalOpen(true);
                                    }}> {'List of investors'}
                                    <Typography className={classes.sublabel} display="inline">
                                        {'(via CSV)'}
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                        <IntegrationIcons />
                    </Stack>
                </Stack>
                <Divider sx={{ width: '100%' }} />
                <InvestorsTable collectionKey={collectionKey} search={search} withFilter />
            </Stack>
        </Container>
        {createWorkspaceModalOpen && (
            <CreateEditInvestorModal
                isOpen={createWorkspaceModalOpen}
                onClose={() => {
                    setCreateWorkspaceModalOpen(false);
                }}
            />
        )}
        <BulkAddWorkspacesModal
            isOpen={bulkAddWorkspaceModalOpen}
            onClose={() => setBulkAddWorkspaceModalOpen(false)}
        />
    </>);
}

export default InvestorsPage;