import React, { SetStateAction } from "react";
import { IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { ExtFile, FileInputButton } from "@files-ui/react";
import { useDebouncedCallback } from "use-debounce";
import {ReactComponent as FilesIcon } from "../../../assets/icons/document.svg";
import { ReactComponent as FileUploadIcon } from "../../../assets/icons/upload.svg";
import { ReactComponent as PdfIcon } from "../../../assets/icons/pdf-icon.svg";
import theme from "../../../theme";
import { DEF_VALID_FILE } from "../../atoms/dashboards/DashboardFileUploader";

const useStyles = makeStyles((theme) => ({
    form: {
        padding: '16px 4px',
        width: '100%',
    },
    icon: {
        width: 24,
        height: 24,
		fill: theme.palette.primary.main,
    },
    inputField: {
        width: '100%',
        margin: 0,
        "& .MuiOutlinedInput-root": {
            width: '100%',
            height: '40px',
            borderRadius: 32,
            background: '#fff',
            "& > input": {
                fontSize: 16,
            },
            "& fieldset": {
              borderColor: 'darkgray !important',
            },
            "&:hover fieldset": {
              borderColor: 'black !important',
            },
            "&.Mui-focused fieldset": {
              borderColor: '#048290 !important',
            },
        },
    },
    browseButton: {
		minWidth: '40px',
		minHeight: '40px',
		padding: 'unset !important',
        borderRadius: '50%',
        background: 'transparent !important',
		boxShadow: 'unset !important',
    },
    attachedItem: {
        padding: '4px 8px',
        borderRadius: 12,
    },
    attachmentIcon: {
        height: 24,
        width: 24,
    },
    actionIcon: {
        height: '32px !important',
        width: '32px !important',
        padding: '0 4px !important',
    },
    attachmentName: {
        display: '-webkit-box',
        overflow: 'hidden',
        fontFamily: 'Inter !important',
        fontSize: '0.8rem !important',
        fontWeight: 'bold !important',
        color: '#666666',
        textOverflow: 'ellipsis',
        textTransform: 'none !important' as any,
        textAlign: 'left',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '1',
    },
}));

const DashboardDeckInput: React.FC<{
	deckLink: string,
    extFiles: Array<ExtFile>,
    onUpdate?: (extFiles: ExtFile[]) => void,
  	onDeckLink?: React.Dispatch<SetStateAction<string>>,
}> = ({ deckLink, extFiles, onUpdate, onDeckLink })=> {
    const classes = useStyles();
    const debounced = useDebouncedCallback(value => onDeckLink?.(value.trim()), 30, { maxWait: 100 });

    return (<>
        {!!extFiles.length ? (<>
            <Stack direction="row" spacing={1} className={classes.attachedItem} alignItems="center" justifyContent="flex-start">
                <Stack direction="row" spacing={1} alignItems="center">
                    <PdfIcon className={classes.attachmentIcon} />
                    <Typography className={classes.attachmentName}>{extFiles[0].name}</Typography>
                </Stack>
                <IconButton className={classes.actionIcon} onClick={() => { onUpdate?.([]); onDeckLink?.(''); }}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </>) : (<>
            <TextField variant="outlined"
                className={classes.inputField}
                placeholder="PDF or DocSend link... (optional)"
                value={deckLink}
                onChange={(e) => debounced(e.target.value as string)}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <FilesIcon className={classes.icon} />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        <FileInputButton className={classes.browseButton}
                            onChange={onUpdate}
                            value={extFiles}
                            maxFiles={1}
                            maxFileSize={DEF_VALID_FILE.maxFileSize}
                            accept={DEF_VALID_FILE.allowedFileTypes.join(',')}
                            behaviour="replace"
                            disabled={!!deckLink}
                        > <FileUploadIcon className={classes.icon}
                            style={{ fill: !!deckLink ? theme.colors.neutral['500'] : theme.palette.primary.main }} />
                        </FileInputButton>
                    </InputAdornment>
                ),
                }}
            />
        </>)}
    </>);
}

export default DashboardDeckInput;