import React, { useCallback, useContext, useState } from 'react';
import { Box, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import classnames from 'classnames';

import AnswerEditor from '../dashboard-query-answer/AnswerEditor';
import { scrollbarStyle } from '../../../shared/dashboard';
import { Dashboard } from '../../../types/files';
import {DashboardContext} from '../../../contexts/DashboardContext';
import { updateDashboardFunc } from '../../../lib/helper';
import useFileUploader from '../../../hooks/useFileUploader';

const useStyles = makeStyles((theme) => ({
    masonryElement: {
        display: 'inline-block',
        width: '100%',
        height: 'auto',
        overflow: 'hidden',
        padding: 'unset',
        border: 'unset',
        background: 'transparent',
        transition: 'all 300ms ease',
        "&:hover": {
            border: `unset !important`,
            background: 'transparent !important',
            boxShadow: 'unset !important',
        },
    },
    answerContainer: {
        width: 'auto',
        height: 'auto',
        marginLeft: 4,
        paddingLeft: 4,
        paddingRight: 16,
        border: `1px transparent solid`,
        borderRadius: 12,
    },
    answerBlock: {
        width: '100%',
        height: '48px',
        paddingTop: 8,
        paddingBottom: 8,
        color: '#666666',
        overflowY: 'auto',
        ...scrollbarStyle,
    },
    answerHover: {
        width: '96%',
        marginRight: 2,
        '&:hover': {
            border: `1px ${theme.colors.neutral['400']} solid`,
            borderRadius: 12,
        }
    },
    deckTitle: {
        fontFamily: 'Inter',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: 8,
    },
    deck: {
        color: '#666666',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    url: {
        display: 'inline-block',
        fontFamily: 'Inter',
        fontSize: '0.9rem',
        fontWeight: 500,
        color: theme.colors.neutral['700'],
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        lineClamp: 1,
        maxWidth: '22ch',
        "&:hover": {
            textDecoration: 'none !important',
        },
    },
    editBlock: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxHeight: 910,
        overflow: 'hidden',
    },
    icon: {
        width: 24,
        height: 24,
        fill: '#7bd4d4',
    },
}));

const DocsendLink: React.FC<{ dashboard: Dashboard }> = ({ dashboard }) => {
    const classes = useStyles();
    const { isPublicView } = useContext(DashboardContext);
    const { docsendIngestion } = useFileUploader({});
    const [isEditingAnswer, setIsEditingAnswer] = useState<boolean>(false);
    const [editAnswer, setEditAnswer] = useState<string | undefined >(undefined);
    const [hoverAnswer, setHoverAnswer] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    const saveEditAnswer = useCallback(async (answer: string) => {
        setSaving(true);
        Promise.all([
            docsendIngestion(dashboard, answer),
            updateDashboardFunc({ ...dashboard, externalLinks: [answer] })
        ]).then(() => {
            setIsEditingAnswer(false);
            setEditAnswer(undefined);
            setSaving(false);
        });
    }, [dashboard, docsendIngestion]);

    return (<>
        <Box className={classes.masonryElement}>
            <Stack direction="column" alignItems="flex-start" width="100%">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                    <Typography className={classes.deckTitle}>{'Deck link'}</Typography>
                    {saving && (<CircularProgress size={24} />)}
                </Stack>
                {!isEditingAnswer && (
                    <Stack direction="row" justifyContent="space-between"
                        className={classnames(classes.answerContainer, hoverAnswer && classes.answerHover)}
                        onMouseLeave={() => setHoverAnswer(false)}>
                            <Stack className={classes.answerBlock}
                                onMouseEnter={() => setHoverAnswer(!isPublicView || !saving)}
                                style={{ height: 'auto' }}>
                                {!!dashboard?.externalLinks ? (
                                    <Typography component="a"
                                        className={classes.url}
                                        href={dashboard?.externalLinks?.[0]}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {dashboard?.externalLinks?.[0]}
                                    </Typography>
                                ) : (<Typography className={classes.deck}>{'N/A'}</Typography>)}
                            </Stack>
                        <Stack direction="column" alignItems="center" justifyContent="center" width="40px">
                            {hoverAnswer && (
                                <IconButton size="small"
                                    className={classes.icon}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsEditingAnswer(true);
                                        setEditAnswer(dashboard?.externalLinks?.[0]);
                                    }}
                                > <EditOutlinedIcon fontSize="small" /> </IconButton>
                            )}
                        </Stack>
                    </Stack>
                )}
            </Stack>
            {isEditingAnswer && (
                <Box className={classes.editBlock} px={2}>
                    <AnswerEditor
                        currentAnswer={editAnswer || ''}
                        placeHolder={"Add link..."}
                        rows={2}
                        handleCancel={() => {
                            setIsEditingAnswer(false);
                            setEditAnswer(undefined);
                        }}
                        handleSave={saveEditAnswer} />
                </Box>
            )}
        </Box>
    </>);
}

export default DocsendLink;
