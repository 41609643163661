import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LoadingButton } from "@mui/lab";
import { GroupSettingsContext } from "../../../contexts/GroupSettingsContext";
import { Dashboard, Workspace } from "../../../types/files";
import { addWorkspaceToDashboardFunc } from "../../../lib/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import ArrayUtils from "../../../utils/ArrayUtils";
import { DashboardsContext } from "../../../contexts/DashboardsContext";
import { useSnackbar } from "notistack";
import WorkspaceShare from "../../atoms/workspaces/WorkspaceShare";
import useBackwardsCompatible from "../../../hooks/useBackwardsCompatible";

export type SelectWorkspaceType = {
    workspace: Workspace;
    checked: boolean;
    mutable: boolean;
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        "& .MuiDialog-paper": {
            width: 430,
            height: 430,
            padding: '16px 24px',
            borderRadius: 24,
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 'unset',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'Inter',
        fontSize: '1.25rem',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 0',
        height: '100%',
        overflowY: 'hidden',
    },
    dialogActions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 0,
    },
    checklist: {
        height: '100%',
        overflowY: 'auto',
    },
    listItem: {
       "& > .MuiTypography-root": {
            fontFamily: 'Inter',
            fontSize: '0.95rem',
            fontWeight: 700,
       }
    },
    commonButton: {
        borderRadius: 32,
        textTransform: 'none',
        fontWeight: 'bold',
        minWidth: 80,
    },
    cancelButton: {
        width: 100,
        borderRadius: 32,
        borderColor: theme.colors.neutral['100'],
        backgroundColor: theme.colors.neutral['100'],
        color: theme.colors.neutral['500'],
        textTransform: 'none',
        fontWeight: 'bold',
        transition: 'ease-in-out 300ms',
    },
}));

const DashboardAddToInvestorModal: React.FC<{
    isOpen: boolean,
    dashboard: Dashboard,
    onClose: (createWorkspace?: boolean) => void,
}> = ({ isOpen, dashboard, onClose }) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { userGroup } = useContext(AuthContext);
    const { dashboards, setDashboards } = useContext(DashboardsContext);
    const { workspaces } = useContext(GroupSettingsContext);
    const { dashboardWorkspaces } = useBackwardsCompatible();

    const [selectedWorkspaces, setSelectedWorkspaces] = useState<SelectWorkspaceType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const assignedIds = useMemo(() => dashboardWorkspaces(dashboard) ?? [], [dashboard, dashboardWorkspaces]);
    const hasSelection = useMemo(() => selectedWorkspaces.some(selection => selection.checked), [selectedWorkspaces]);

    const handleFilterWorkspaces = useCallback((id: string, mutable: boolean) => () => {
        if (mutable) {
            setSelectedWorkspaces(prev => prev.map(selection =>
                selection.workspace.id === id ? ({...selection, checked: !selection.checked}) : selection));
        }
    }, []);

    const handleAssigntoWorkspace = useCallback(() => {
        const promises: Promise<Dashboard>[] = [];
        const assignedWorkspaces = selectedWorkspaces
            .filter(selection => selection.checked && !assignedIds.includes(selection.workspace.id));
        const assignedWorkspaceIds = assignedWorkspaces.map(selection => selection.workspace.id);
        const assignedWorkspaceNames = assignedWorkspaces.map(selection => selection.workspace.name).join(', ');

        setLoading(true);
        assignedWorkspaceIds.forEach(id => {
            promises.push(addWorkspaceToDashboardFunc({
              dashboardId: dashboard.id,
              workspaceId: id,
              group: userGroup,
            }));
        });

        Promise.all(promises).then((dashboardUpdates: Dashboard[]) => {
            // force update dashboards list
            const latestDashboard = ArrayUtils.sortByDescending(dashboardUpdates, 'createdAt')[0];
            const newDashboards = dashboards.map(d => d.id === dashboard?.id ? ({...latestDashboard}) : d);
            const sortedData = ArrayUtils.sortByDescending(newDashboards, 'createdAt');
            setDashboards(sortedData);
        }).finally(() => {
            setLoading(false);
            enqueueSnackbar(`${dashboard.title} has been successfully added to ${assignedWorkspaceNames}`, {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                },
                autoHideDuration: 4000,
            });
            onClose();
        });
    // eslint-disable-next-line
    }, [assignedIds, dashboards, selectedWorkspaces, userGroup]);

    useEffect(() => {
        setSelectedWorkspaces(prev => workspaces.map(workspace => {
            const assigned = assignedIds.includes(workspace.id);
            const selected = prev.find(selection => selection.workspace.name === workspace.name)?.checked ?? false;

            return { workspace,
                checked: assigned || selected,
                mutable: !assigned,
            };
        }));
    }, [assignedIds, workspaces]);

    return (<>
        <Dialog className={classes.dialog} open={isOpen} onClose={() => onClose()}>
            <DialogTitle className={classes.dialogTitle}>
                {'Add to investor'}
            </DialogTitle>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogContent className={classes.dialogContent}>
                <Typography fontWeight="bold">{'Select the investor to add:'}</Typography>
                <List className={classes.checklist} >
                    {selectedWorkspaces.map(({workspace, checked, mutable}, i) => (mutable) && (
                        <ListItem key={'dashboard-add-to-investor-modal-164-' + i} disablePadding>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mr={2}>
                                <ListItemButton role={undefined} onClick={handleFilterWorkspaces(workspace.id, mutable)} dense>
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={checked} disabled={!mutable} disableRipple />
                                    </ListItemIcon>
                                    <ListItemText id={workspace.id} className={classes.listItem} primary={workspace.name} />
                                </ListItemButton>
                                <WorkspaceShare workspace={workspace} hiddenOnPrivate slim />
                            </Stack>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <Divider sx={{ margin: '8px 0'}} />
            <DialogActions className={classes.dialogActions}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <Button
                        variant="contained"
                        className={classes.commonButton}
                        onClick={() => onClose(true)}
                    > {'Add an investor'} </Button>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                    <Button
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={() => onClose()}
                    > {'Cancel'} </Button>
                    <LoadingButton
                        variant="contained"
                        className={classes.commonButton}
                        loading={loading}
                        disabled={!hasSelection}
                        onClick={handleAssigntoWorkspace}
                    > {'Save'} </LoadingButton>
                </Stack>
            </DialogActions>
        </Dialog>
    </>);
}

export default DashboardAddToInvestorModal;